<template>
  <div class="mobile">
    <div class="title">使用完整服务</div>
    <div class="code">手机号</div>
    <div class="code-input">
      <el-input v-model="mobile" placeholder="请输入手机号"></el-input>
    </div>
    <div class="code code-mobile">验证码</div>
    <div class="code-input">
      <div class="get-code" @click="getCode" v-if="getCodeStatus == 1">获取验证码</div>
      <div class="get-code get-code59" v-else>{{ codeNum }}s后重发</div>
      <el-input v-model="code" placeholder="请输入验证码"></el-input>
    </div>
    <div class="serviceAgreement" style="display: none">
      <el-checkbox v-model="checked" @change="radioChange"></el-checkbox>
      <!-- <el-radio v-model="radio" @input="radioChange"> </el-radio> -->
      我已阅读并同意<span @click="peopleServiceAgreement">《用户服务协议》</span>和<span
        @click="privacyPolicy"
        >《隐私政策》</span
      >
    </div>
    <div class="btn">
      <div class="check" @click="close" style="display: none">稍后完善</div>
      <div class="check submit" @click.stop="submitPhone">立即使用</div>
    </div>
    <div class="serviceAgreementContent" v-if="peopleServiceAgreementShow">
      <div class="serverTitle">
        <span>用户隐私协议</span
        ><i class="el-icon-warning-outline" @click="peopleServiceAgreementShow = false"></i>
      </div>
      <div class="serviceContent">
        本协议旨在明确说明我们如何收集、使用、保护以及可能披露您的个人信息。我们非常重视
        您的隐私，并承诺按照相关法律法规的要求处理您的个人信息。请仔细阅读以下条款，以确
        保您了解我们的隐私政策和做法。
        <div class="serverTitleList">一、信息的收集和使用</div>
        <div class="serverTitleItem">
          当您使用我们的服务时，我们可能会收集您的个人信息，包括但不限于：姓名、电子邮件地
          址、电话号码、地理位置等。这些信息将用于提供、改进和个性化我们的服务。
          此外，我们还可能收集与您使用我们的产品和服务相关的其他信息，例如使用记录、浏览记 录等。
        </div>
        <div class="serverTitleList">二、信息的保护和存储</div>
        <div class="serverTitleItem">
          我们将采取合理的技术和管理措施来保护您的个人信息，防止数据泄露、篡改或损坏。您的
          个人信息将存储在安全的服务器上，我们将遵守相关法律法规的要求，确保您的数据得到充 分保护。
          但是，由于网络环境的不安全性，我们不能保证您的个人信息永远不会被盗取或泄露。
        </div>
        <div class="serverTitleList">三、信息的披露和共享</div>
        <div class="serverTitleItem">
          我们不会向第三方出售或出租您的个人信息，除非事先得到您的明确同意或法律要求。我们
          可能会与我们的合作伙伴、代理商或承包商分享您的个人信息，以便他们能够提供服务。但
          是，我们会确保这些合作伙伴、代理商或承包商遵守与我们相同的隐私保护标准，或者按照
          我们的要求进行保密。
        </div>
        <div class="serverTitleList">四、您的权利和选择</div>
        <div class="serverTitleItem">
          您有权访问和更正您的个人信息。如果您想访问或更正您的个人信息，或者对我们的隐私政
          策有任何疑问或建议，可以通过我们的客服渠道联系我们。您还可以要求我们删除您的个人
          信息或限制我们对您的个人信息的处理。如果您不同意我们的隐私政策，您可以选择停止使
          用我们的产品和服务。
        </div>
        <div class="serverTitleList">五、变更和争议解决</div>
        <div class="serverTitleItem">
          我们保留随时修改本协议的权利。如有重大变更，我们将通过平台或其他适当方式提前通知 您。
          如您在执行本协议过程中与我们发生争议，应首先通过友好协商解决；协商不成的，任何一
          方均有权向有管辖权的法院提起诉讼。
        </div>
        <div class="serverTitleList">六、其他</div>
        <div class="serverTitleItem">
          本协议自您使用我们的服务之日起生效，并持续有效直至您停止使用我们的服务或我们终止 本协议。
          请注意，本隐私协议可能会根据我们的服务发展和法规变化进行更新。我们鼓励您定期查看
          本协议以确保您了解其最新内容。如果您有任何问题或疑虑，或者需要行使本隐私协议中提
          到的任何权利，请通过我们的联系方式与我们联系。
        </div>
      </div>
    </div>
    <div class="privacyPolicyShowContent" v-if="privacyPolicyShow">
      <div class="serverTitle">
        <span>隐私政策</span>
        <i class="el-icon-warning-outline" @click="privacyPolicyShow = false"></i>
      </div>
      <div class="serviceContent">
        我们深知隐私对您的重要性，因此我们制定了本隐私政策，以明确我们收集、使用和保护您
        的个人信息的方式。本隐私政策适用于我们所有的产品和服务。
        <div class="serverTitleList">一、信息收集</div>
        <div class="serverTitleItem">
          <div class="serverTtem">
            1. 我们可能会收集您的姓名、电子邮件地址、电话号码、住址、IP 地址、设备信息以及其他
            非公开的个人信息。
          </div>
          <div class="serverTtem">
            2. 我们可能会收集您在使用我们的产品和服务过程中产生的其他信息，例如搜索记录、浏览
            记录、购买记录等。
          </div>
          <div class="serverTtem">
            3. 我们可能会从第三方获取您的个人信息，例如合作伙伴、社交媒体平台或其他数据源。
          </div>
        </div>
        <div class="serverTitleList">二、信息使用</div>
        <div class="serverTitleItem">
          <div class="serverTtem">
            1. 我们将使用您的个人信息来提供和改进我们的产品和服务，包括但不限于定制内容、个性
            化推荐、客户服务等。
          </div>
          <div class="serverTtem">
            2. 我们可能会使用您的个人信息进行内部研究和改进，以提高我们的产品和服务的质量和效率。
          </div>
          <div class="serverTtem">
            3. 我们可能会将您的个人信息用于市场营销和宣传活动，例如发送电子邮件、短信或推送通知。
          </div>
        </div>
        <div class="serverTitleList">三、信息保护</div>
        <div class="serverTitleItem">
          <div class="serverTtem">
            1. 我们将采取合理的安全措施来保护您的个人信息，包括但不限于加密、访问控制和数据备份。
          </div>
          <div class="serverTtem">
            2. 我们将限制对您的个人信息的访问和使用，只有经过授权的员工和合作伙伴才能访问和使
            用您的个人信息。
          </div>
          <div class="serverTtem">
            3. 我们将遵守适用的法律和法规，确保您的个人信息的合法性和安全性。
          </div>
          <div class="serverTtem">
            4. 我们不会向任何第三方出售或泄露用户个人信息，除非得到用户的明确授权或法律法规要 求。
          </div>
        </div>
        <div class="serverTitleList">四、信息共享和披露</div>
        <div class="serverTitleItem">
          <div class="serverTtem">
            1. 我们可能会将您的个人信息共享给我们的合作伙伴、代理商或承包商，以提供更好的服务。
            但是，我们将确保这些合作伙伴遵守与我们相同的隐私保护标准。
          </div>
          <div class="serverTtem">
            2. 在法律要求的情况下，我们可能会披露您的个人信息给相关的政府机构或法律程序。
          </div>
          <div class="serverTtem">
            3. 在紧急情况下，为了保护您的人身安全或他人的利益，我们可能会披露必要的个人信息。
          </div>
        </div>
        <div class="serverTitleList">五、隐私政策的更新和修改</div>
        <div class="serverTitleItem">
          <div class="serverTtem">
            1. 我们可能会根据我们的业务需求和市场变化更新或修改本隐私政策。在更新或修改本隐私
            政策时，我们将通过网站公告或电子邮件等方式通知您。
          </div>
          <div class="serverTtem">
            2. 如果我们对本隐私政策的修改涉及到您的重要权益，您有权要求我们提供修改后的隐私政
            策，并有权在收到通知后的合理时间内提出异议。
          </div>
        </div>
        <div class="serverTitleList">六、争议解决</div>
        <div class="serverTitleItem">
          <div class="serverTtem">
            1. 如果您对我们的隐私政策或我们的隐私实践有任何疑问或异议，您可以通过我们的客服联
            系我们，我们将尽快回复您。
          </div>
          <div class="serverTtem">
            2. 如果您认为我们的隐私政策或我们的隐私实践违反了适用的法律和法规，您可以通过我们
            的客服提出投诉，我们将尽快调查并采取适当的纠正措施。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bindPhone, sendVerifyCode } from "@/api/fri";

export default {
  data() {
    return {
      mobile: "",
      code: "",
      getCodeStatus: 1,
      codeNum: "59",
      checked: false,
      peopleServiceAgreementShow: false,
      privacyPolicyShow: false,
      unionId: "or7GT5xb2817VgvVEn9c45hEbA5M",
    };
  },
  methods: {
    radioChange(v) {
      console.log(this.checked);
      // console.log(v);
    },
    peopleServiceAgreement() {
      this.peopleServiceAgreementShow = true;
    },
    privacyPolicy() {
      this.privacyPolicyShow = true;
    },
    submitPhone() {
      if (!this.mobile) {
        this.$message({
          message: "请输入手机号！",
          type: "warning",
        });
        return;
      }
      // if (!this.checked) {
      // this.$dialog
      //   .alert({
      //     title: "隐私政策授权",
      //     message: "登录代表您已同意《服务协议》《隐私政策》。",
      //     showCancelButton: true,
      //     confirmButtonColor: "#165DFF",
      //     cancelButtonColor: "#0E1429",
      //     className: "vantDialog",
      //     confirmButtonText: "同意",
      //   })
      //   .then(() => {
      //     this.checked = true;
      // let params = {
      //   channelUniqueId:
      //     this.$route.query.channelUniqueId || localStorage.getItem("channelUniqueId"),
      //   mobile: this.mobile,
      //   verifyCode: this.code,
      //   unionId: this.unionId,
      // };
      // this.loading = this.$loading({
      //   lock: true,
      //   text: "正在绑定...",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
      // bindPhone(params).then((res) => {
      //   console.log(res);
      //   this.loading.close();
      //   if (res.data.code == 200) {
      //     this.$message({
      //       message: "绑定成功！",
      //       type: "success",
      //     });
      //     localStorage.setItem("token", res.headers["authorization"]);
      //     localStorage.setItem("phoneNumber", res.data.data.phoneNumber);
      //     localStorage.setItem("organizationId", res.data.data.currentOrganizationId);
      //     this.$emit("bindPhoneWx");
      //   }
      // });
      // })
      //   .catch(() => {
      //     this.$emit("closeMobile");
      //   });
      // return;
      // } else {

      // let params = {
      //   channelUniqueId:
      //     this.$route.query.channelUniqueId || localStorage.getItem("channelUniqueId"),
      //   mobile: this.mobile,
      //   verifyCode: this.code,
      //   unionId: localStorage.getItem('unionId'),
      // };
      // this.loading = this.$loading({
      //   lock: true,
      //   text: "正在绑定...",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
      // bindPhone(params).then((res) => {
      //   this.loading.close();
      //   if (res.data.code == 200) {
      //     this.$message({
      //       message: "绑定成功！",
      //       type: "success",
      //     });
      //   return;
      // } else {

      let params = {
        channelUniqueId:localStorage.getItem("channelUniqueId"),
        mobile: this.mobile,
        verifyCode: this.code,
        unionId: localStorage.getItem("unionId"),
        posterUniqueId:localStorage.getItem("posterUniqueId"),

      };
      this.loading = this.$loading({
        lock: true,
        text: "正在绑定...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      bindPhone(params).then((res) => {
        console.log(res);
        this.loading.close();
        if (res.data.code == 200) {
          this.$message({
            message: "绑定成功！",
            type: "success",
          });
          localStorage.setItem("token", res.headers["authorization"]);
          localStorage.setItem("phoneNumber", res.data.data.phoneNumber);
          localStorage.setItem("organizationId", res.data.data.currentOrganizationId);
          localStorage.setItem("headImgUrl", res.data.data.headImgUrl);
          localStorage.setItem("isMobile", false);

          this.$emit("bindPhoneWx");
        }
      });
      // }
    },
    close() {
      this.$emit("closeMobile");
    },
    getCode() {
      if (!this.mobile) {
        this.$message({
          message: "请输入手机号！",
          type: "warning",
        });
        return;
      }
      this.loading = this.$loading({
        lock: true,
        text: "正在获取验证码...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      sendVerifyCode(this.mobile).then((res) => {
        this.loading.close();
        if (res.code == 200) {
          this.getCodeStatus = 2;
          this.delCode();
          this.$message({
            message: res.message,
            type: "success",
          });
        }
      });
    },
    delCode() {
      let clock = window.setInterval(() => {
        this.codeNum--;
        if (this.codeNum <= 0) {
          window.clearInterval(clock);
          this.getCodeStatus = 1;
          this.codeNum = 59;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="stylus" scoped>
.mobile {
  width: 7.5rem;
  //height: 7.36rem;
  background: #FFFFFF;
  border-radius: 0.16rem 0.16rem 0 0;
  opacity: 1;
  font-size: 0.32rem;
  color: #181C36;
  padding-bottom: 0.4rem;

  .title {
    font-size: 0.34rem;
    line-height: 0.48rem;
    font-weight: 600;
    text-align: center;
    margin-top: 0.4rem;
    margin-bottom: 0.5rem;
  }

  .code {
    font-size: 0.3rem;
    line-height: 0.42rem;
    margin-left: 0.3rem;
  }

  .code:before {
    content: "*";
    color: rgba(250, 79, 79, 1);
    margin-right: 5px;
  }

  .code-mobile {
    margin-top: 0.5rem;
  }

  .code-input {
    position: relative;
    width: 6.9rem;
    height: 0.88rem;
    line-height: 0.88rem;
    background: #F8F9FB;
    border-radius: 0.1rem;
    opacity: 1;
    margin: 0.2rem 0 0 0.3rem;

    >>> .el-input__inner {
      border: none;
      background: #F8F9FB;
      height: 0.88rem;
      line-height: 0.88rem;
      padding: 0 0.24rem;
    }

    >>> .el-input__inner::placeholder {
      font-size: 0.3rem;
    }

    .get-code {
      position: absolute;
      right: 0.24rem;
      top: 0;
      line-height: 0.88rem;
      color: rgba(63, 116, 246, 1);
      z-index: 1002;
      font-size: 0.3rem;
    }

    .get-code59 {
      color: rgba(185, 186, 194, 1);
    }
  }

  .btn {
    display: flex;
    margin-top: 0.6rem;

    .check {
      width: 6.9rem;
      height: 0.8rem;
      line-height: 0.8rem;
      background: rgba(63, 116, 246, 0.2)
      border-radius: 0.1rem;
      color: #165DFF;
      text-align: center;
      margin-left: 0.3rem;
    }

    .submit {
      background: #165DFF;
      color: #fff;
    }
  }
}
>>>.el-radio{
  margin-right: 0px;
}
>>>.el-radio__label {
   padding-left: 5px;
}
.serviceAgreement{
  font-size: 0.28rem;
  margin: 0.5rem 0 0 0.3rem;
  color: #0E1429;
  line-height: 0.42rem;
  span{
    color: #165DFF;
  }
}
.serviceAgreementContent{
  background: #fff;
  position: fixed;
  top: 1.88rem;
  height: 11.975rem;
  border-radius: 0.24rem;
  padding: 0.3rem 0.4rem;
  overflow-y: auto;
  z-index:1003;
  .serverTitle{
    display: flex;
    justify-content: space-between
  }
  .serverTitle span{
   font-size:0.34rem;
   font-weight: 600;
   color: #0E1429;
   margin-bottom: 0.4rem;
  }
  .serviceContent{
    line-height: 0.42rem;
    .serverTitleItem{
      margin-top:0.2rem;
    }
  }
  .serverTitleList{
    margin-top:0.4rem;
    font-size:0.34rem;
    font-weight: 600;
    color: #0E1429;
  }

}
.privacyPolicyShowContent{
  background: #fff;
  position: fixed;
  top: 1.88rem;
  height: 11.975rem;
  border-radius: 0.24rem;
  padding: 0.3rem 0.4rem;
  overflow-y: auto;
  z-index:1003;
  .serverTitle{
    display: flex;
    justify-content: space-between
  }
  .serverTitle span{
   font-size:0.34rem;
   font-weight: 600;
   color: #0E1429;
   margin-bottom: 0.4rem;
  }
  .serviceContent{
    line-height: 0.42rem;
    .serverTitleItem{
      margin-top:0.2rem;
    }
  }
  .serverTitleList{
    margin-top:0.4rem;
    font-size:0.34rem;
    font-weight: 600;
    color: #0E1429;
  }
}
.el-icon-warning-outline{
  font-size: 0.28rem;
  color: #7C7D86;
  line-height: 1.7;
}
>>>.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #165DFF;
  border-color: #165DFF;
  border-radius: 50%;
  width: 0.36rem;
  height: 0.36rem;
}
.el-checkbox__inner:hover {
  border-color: #dedede;
  border-radius: 50%;
  width: 0.36rem;
  height: 0.36rem;
}
>>>.el-checkbox__inner {
  border-color: #dedede;
  border-radius: 50%;
  width: 0.36rem;
  height: 0.36rem;
}
>>>.el-checkbox__inner::after{
  left: 6px;
  position: absolute;
  top: 2px;
}
>>>.el-checkbox__input.is-focus .el-checkbox__inner  {
  border-color: #dedede;
}
>>>.vantDialog .van-dialog__header {
  padding-top: 0.4rem;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}
</style>
