import axios, {
    get,
    post
  } from "@/axios";
  
  const base = process.env.VUE_APP_API_ROOT;

//检测记录
// 提交检测记录
export const checkApi = programId => {
    return post(`${base}/channel/modelCheck/check?programId=${programId}`)
      .then(res => res.data);
  };

//判断是否有风险检测菜单权限
export const checkMenuAccessApi = () => {
    return get(`${base}/channel/modelCheck/checkMenuAccess`)
      .then(res => res.data);
  };

// 获取可检测版本和次数
export const getCheckVersionDataApi = (channelUniqueId) => {
    return get(`${base}/channel/modelCheck/getCheckVersionData?channelUniqueId=${channelUniqueId}`)
      .then(res => res.data);
  };

// 检测记录分页列表
// export const modelCheckGetPageApi = params => {
//     return post(`${base}/channel/modelCheck/getPage`, params)
//       .then(res => res.data);
//   };
export const modelCheckGetPageApi = params => {
    return get(`${base}/shuian-home/testing/record/getTestingRecordPage`, params)
      .then(res => res.data);
  };
//保存预检测数据  
export const savePrepareDataApi = params => {
    return post(`${base}/channel/modelCheck/savePrepareData`, params)
      .then(res => res.data);
  }


//支付订单前校验系统登录手机号  
export const checkPhoneNumberApi = (goodsId,phoneNumber) => {
    return get(`${base}/channel/channelOrdOrder/checkPhoneNumber?goodsId=${goodsId}/phoneNumber=${phoneNumber}`)
      .then(res => res.data);
  };

// 通过订单号创建-充值系统账号
export const createAccountApi = orderNo => {
    return get(`${base}/channel/channelOrdOrder/createAccount?orderNo=${orderNo}`)
      .then(res => res.data);
  };

// 获取已经购买过的手机号公司名称
export const getBuyLoginInfoListApi = goodsId => {
    return get(`${base}/channel/channelOrdOrder/getBuyLoginInfoList?goodsId=${goodsId}`)
      .then(res => res.data);
  };
