<template>
  <div>
    <payItem class="good" :type="order" :item="orderInfo"></payItem>
    <goodInfo class="info" :goodInfo="orderInfo.productConfigJson" style="display: none"></goodInfo>
    <div class="submit-account">
      <div class="title" v-if="orderInfo.templateId != 6">产品详细信息</div>
      <div class="item items" v-if="orderInfo.templateId != 6">
        <div>有效期</div>
        <!-- <div>{{ orderInfo.endCheckTime }}</div> -->
        <div>{{orderInfo.endCheckTime}}</div>
      </div>
      <div class="item items" v-if="orderInfo.templateId != 6">
        <div>客服电话</div>
        <div>{{ orderInfo.orderPhone }}</div>
      </div>
      <div
        class="item items item-bottom"
        :class="{ 'item-bottom': orderInfo.orderStatus == -1 }"
        v-if="orderInfo.templateId != 6"
      >
        <div>购买须知</div>
        <div>本产品不支持退款</div>
      </div>
      <!-- <div
        class="item items item-bottom"
        v-if="orderInfo.orderStatus != -1 && orderInfo.templateId != 6"
      >
        <div>初始密码</div>
        <div>123456</div>
      </div> -->
      <div class="item items item-code">
        <div>订单编号</div>
        <div>{{ orderInfo.orderNo }}</div>
      </div>
      <div class="item items">
        <div>创建时间</div>
        <div>{{ orderInfo.createTime }}</div>
      </div>
      <div v-if="orderInfo.orderStatus == 2">
        <div class="item items">
          <div>支付时间</div>
          <div>{{ orderInfo.updateTime }}</div>
        </div>
        <div class="item items">
          <div>支付方式</div>
          <div>{{ orderInfo.payType == 1 ? "微信支付" : "其他支付" }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import payItem from "./components/pay-item.vue";
import goodInfo from "./components/good-info.vue";

import { getOrderInfo } from "@/api/fri";
import loginVue from "./components/login.vue";

export default {
  metaInfo: {
    title: "订单信息",
  },
  components: {
    payItem,
    goodInfo,
  },
  data() {
    return {
      order: 2,
      orderInfo: {},
    };
  },
  mounted() {
    this.getOrder();
  },
  methods: {
    getOrder() {
      getOrderInfo(this.$route.query.orderNo).then((res) => {
        if (res.code == 200) {
          this.orderInfo = res.data;
          console.log(res.data);
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.good {
  margin: 0.2rem 0 0.2rem 0.2rem;
}

.info {
  margin-left: 0.2rem;
}

.submit-account {
  width: 7.1rem;
  background: #FFFFFF;
  border-radius: 0.2rem;
  opacity: 1;
  font-size: 0.28rem;
  margin: 0.2rem 0 0 0.2rem;
  padding: 0 0.2rem;
  box-sizing: border-box;
  padding-bottom: 0.2rem;

  .title{
    font-size: 0.32rem;
    line-height: 0.46rem;
    padding-top: 0.3rem;
    padding-bottom: 0.4rem;
    font-weight: bold;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1rem;
    border-bottom: 1px solid rgba(14, 20, 41, 0.1);
    box-sizing: border-box;

    >>> .el-input {
      width: 3.2rem;
    }

    >>> .el-input__inner {
      border: none;
      backround: #F8F9FB;
    }
  }

  .items {
    border-bottom: none;
    line-height: 0.4rem;
    margin-bottom: 0.3rem;
  }
  .items:last-child {
    border-bottom: none;
    line-height: 0.4rem;
    margin-bottom: 0rem;
  }
  .item-botom {
    border-bottom: none;
    padding-bottom: 0.38rem;
  }

  .item-code {
    padding-top: 0.3rem;
  }
}

.btn {
  width: 6.9rem;
  height: 0.88rem;
  line-height: 0.88rem;
  background: #3F74F6;
  border-radius: 0.1rem;
  opacity: 1;
  font-size: 0.34rem;
  text-align: center;
  color: #fff;
}

.btn-c {
  width: 100%;
  background: #FFFFFF;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  padding: 0.2rem 0.3rem;
  box-sizing: border-box;
  margin-top: 0.4rem;
}

.submit-account .item-bottom {
  border-bottom: 1px solid rgba(14,20,41,.1) !important;
  margin-bottom: 0 !important;
  padding-bottom: 0.3rem;
}
</style>
