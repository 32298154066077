<template>
    <van-overlay :show="show" @click="show = false">
        <div class="wrapper" @click.stop>
            <div class="inbox" @click.stop>
                <img src="@/assets/web_chahua_team@2.png" alt="">
                <div class="secondTile">系统验证授权信息约 <span style="color: #165DFF;">3</span> 分钟，请不要离开！</div>
                <div class="lastdiv">校验通过将自动进入系统数据分析流程， <br>如检验失败，请按照系统提示调整后重新授权。</div>
            </div>
        </div>

    </van-overlay>

</template>
<script>
import { getLoginStatus } from "@/api/api"
import { Dialog ,Toast} from 'vant';

export default {
    data() {
        return {
            show: false,
            interval: null
        }
    },
    methods: {
        open(e) {
            this.interval = setInterval(async () => {
                const res = await getLoginStatus(e)
                if (res.data) {
                    if (res.data.loginStatus) {
                        if (e.isGetCode) {
                            this.$emit("openCode");
                        } else {
                            this.$emit("commitSuccess");

                        }
      Toast('校验成功');

                        clearInterval(this.interval)
                        this.show = false
                    } else if (res.data.loginStatus === false) {
                        clearInterval(this.interval)
                        this.openerror(res.data.errorMsg)
                    }
                }
            }, 10000);
            this.show = true
        },
        openerror(e) {

            this.show = false

            Dialog.alert({
                title: '校验失败',
                message: e,
                confirmButtonColor:'#165DFF',
                confirmButtonText:'知道了'
            }).then(() => {
                // on close
            });
            // this.$refs.rpaErrorRef.open(e)
        }
    }
}
</script>
<style scoped lang="stylus">

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
.inbox{
    box-sizing border-box
    padding 0.625rem 0.69rem 0.83rem 0.69rem;
    width: 6.66rem;
    background #fff
  display:flex;
  flex-direction:column;
  align-items center
  border-radius: 0.208rem;
  .lastdiv{
    
    font-family: PingFang SC;
    color: #606368;
    font-weight: 400;
    font-size: 0.25rem;
    line-height:1.5
  }
  .secondTile{
    font-family: PingFang SC;
    color: #1D2129;
    font-weight: 600;
    font-size: 0.3125rem;
    font-style: normal;
    text-decoration: none;
    text-align: center;
    margin: 0.208rem 0 0.3125rem;
  }
  
  div{
    text-align center
  }
  img{
    /* web_chahua_team */
    width: 3.125rem;
    height: 3.125rem;
  }
}
</style>