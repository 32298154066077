import { render, staticRenderFns } from "./noPoster.vue?vue&type=template&id=58f896b7&scoped=true&"
import script from "./noPoster.vue?vue&type=script&lang=js&"
export * from "./noPoster.vue?vue&type=script&lang=js&"
import style0 from "./noPoster.vue?vue&type=style&index=0&id=58f896b7&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58f896b7",
  null
  
)

export default component.exports