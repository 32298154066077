<template>
  <div class="poster">
    <div class="title">基础数据</div>
    <div class="detectionTab">
      <el-radio-group v-model="tabPosition">
        <el-radio-button label="every">不限制</el-radio-button>
        <el-radio-button label="7">近7日</el-radio-button>
        <el-radio-button label="30">近30日</el-radio-button>
      </el-radio-group>
    </div>
    <div class="posterList">
      <div class="posterItem borderBt borderRg">
        <div class="posterNum">3412</div>
        <div class="posterName">海拔扫码次数</div>
      </div>
      <div class="posterItem borderBt">
        <div class="posterNum">34242</div>
        <div class="posterName">推广用户数</div>
      </div>
      <div class="posterItem borderRg">
        <div class="posterNum">3442</div>
        <div class="posterName">领取检测次数</div>
      </div>
      <div class="posterItem">
        <div class="posterNum">34</div>
        <div class="posterName">销售总金额</div>
      </div>
    </div>
    <div class="title">邀请海报</div>
    <img class="banner" @click="goPosterProduction" src="@/assets/banner.png" alt="" />
    <div class="title">海报生成记录</div>
    <div class="posterRecordTab">
      <el-radio-group v-model="tabPosition2">
        <el-radio-button label="everyOne">全部</el-radio-button>
        <el-radio-button label="1">生效中</el-radio-button>
        <el-radio-button label="2">未生效</el-radio-button>
        <el-radio-button label="3">已失效</el-radio-button>
      </el-radio-group>
    </div>
    <div class="detectionCard" v-if="tabPosition2 == 'everyOne'" @click="clickPosterDetail">
      <div class="cardTime">
        <img class="backPic" src="@/assets/老板看报告插图.png" alt="" />
        <!-- <img class="backPic" src="@/assets/老板看报告插图 (1).png" alt="" /> -->
        <div style="margin-left: 0.3rem">
          <div class="timeOne">专业版2.0</div>
          <div class="timeTwo">海报有效期：2023.08.28～2023.08.29</div>
          <div class="timeThree">创建时间：2023.08.28 16:01:30</div>
        </div>
        <div class="status">
          <!-- <div class="statusYellow">
              <div class="statusCircleYellow"></div>
              待生成
            </div> -->
          <!-- <div class="statusGreen">
              <div class="statusCircleGreen"></div>
              已生成
            </div> -->
          <!-- <div class="statusRed">
              <div class="statusCircleRed"></div>
              已生成
            </div> -->
          <div class="statusBlue" @click="clickStatusTip(1)">
            <div class="statusCircleBlue"></div>
            生成中
          </div>
          <!-- <div class="statusGray">
              <div class="statusCircleGray"></div>
              已删除
            </div> -->
        </div>
      </div>
      <div class="pay-bottom">
        <div class="pay-btn" @click="clickDelete()">删除</div>
      </div>
    </div>
    <div class="detectionCard" v-if="tabPosition2 == 'everyOne'">
      <div class="cardTime">
        <img class="backPic" src="@/assets/老板看报告插图 (1).png" alt="" />
        <div style="margin-left: 0.3rem">
          <div class="timeOne">专业版2.0</div>
          <div class="timeTwo">报税版本-年度检测-2019年</div>
          <div class="timeThree">2019-10-11 12:23:34</div>
        </div>
        <div class="status">
          <!-- <div class="statusYellow">
              <div class="statusCircleYellow"></div>
              待生成
            </div> -->
          <div class="statusGreen" @click="clickStatusTip(2)">
            <div class="statusCircleGreen"></div>
            已生成
          </div>
          <!-- <div class="statusRed">
              <div class="statusCircleRed"></div>
              已生成
            </div> -->
          <!-- <div class="statusBlue">
              <div class="statusCircleBlue"></div>
              已生成
            </div> -->
          <!-- <div class="statusGray">
              <div class="statusCircleGray"></div>
              已删除
            </div> -->
        </div>
      </div>
      <div class="pay-bottom">
        <div class="pay-btn">删除</div>
      </div>
    </div>
    <div class="detectionCard" v-if="tabPosition2 == 'everyOne'">
      <div class="cardTime">
        <img class="backPic" src="@/assets/老板看报告插图 (1).png" alt="" />
        <div style="margin-left: 0.3rem">
          <div class="timeOne">专业版2.0</div>
          <div class="timeTwo">报税版本-年度检测-2019年</div>
          <div class="timeThree">2019-10-11 12:23:34</div>
        </div>
        <div class="status">
          <!-- <div class="statusYellow">
              <div class="statusCircleYellow"></div>
              待生成
            </div> -->
          <!-- <div class="statusGreen">
              <div class="statusCircleGreen"></div>
              已生成
            </div> -->
          <div class="statusRed" @click="clickStatusTip(3)">
            <div class="statusCircleRed"></div>
            生成出错
          </div>
          <!-- <div class="statusBlue">
              <div class="statusCircleBlue"></div>
              已生成
            </div> -->
          <!-- <div class="statusGray">
              <div class="statusCircleGray"></div>
              已删除
            </div> -->
        </div>
      </div>
      <div class="pay-bottom">
        <div class="pay-btn">删除</div>
      </div>
    </div>
    <div
      class="detectionCard"
      style="background: rgba(0, 0, 0, 0.1)"
      v-if="tabPosition2 == 'everyOne'"
    >
      <div class="cardTime">
        <img class="backPic" src="@/assets/老板看报告插图 (1).png" alt="" />
        <div style="margin-left: 0.3rem">
          <div class="timeOne">专业版2.0</div>
          <div class="timeTwo">报税版本-年度检测-2019年</div>
          <div class="timeThree">2019-10-11 12:23:34</div>
        </div>
        <div class="status">
          <div class="statusGray" @click="clickStatusTip(4)">
            <div class="statusCircleGray"></div>
            已删除
          </div>
        </div>
      </div>
    </div>
    <div class="nullPic" v-else>
      <img class="backPic" src="@/assets/暂无数据.png" alt="" />
      您还没有发起过风险检测哦～
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabPosition: "every",
      tabPosition2: "everyOne",
    };
  },
  methods: {
    clickPosterDetail() {
      this.$router.push({
        path: "/previewDetail",
        query: {
          orderNo: 1,
        },
      });
    },
    clickStatusTip(v) {
      //已生成 去查看
      if (v == 2) {
        this.$router.push({
          path: "/order",
          query: {
            id: 79,
          },
        });
        return;
      }
      this.$dialog
        .alert({
          title: v == 1 ? "报告生成中" : v == 3 ? "报告生成出错" : v == 4 ? "报告已删除" : "",
          message:
            v == 1
              ? "报告生成后会短信通知您," + `\n` + "请耐心等待！"
              : v == 3
              ? "请联系客服，客服电话： " + `\n` + "0571-85055930,"
              : v == 4
              ? "查看报告需要重新授权检测"
              : "",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonColor: "#3F74F6",
          cancelButtonText: "知道了",
        })
        .then(() => {})
        .catch(() => {});
    },
    clickDelete() {
      this.$dialog
        .alert({
          title: "确定删除该记录？",
          message: "海报记录删除后将无法恢复",
          showCancelButton: true,
          confirmButtonColor: "#3F74F6",
          cancelButtonColor: "#0E1429",
          className: "vantDialog",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
        .then(() => {})
        .catch(() => {});
    },
    goPosterProduction() {
      this.$router.push({
        path: "/posterInformation",
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.posterList{
    background: #FFFFFF;
    border-radius: 14px 14px 14px 14px;
    padding:0.3rem;
    display: flex;
    flex-wrap: wrap;
    margin: 0.3rem 0.2rem;
    .posterItem{

        flex-basis: 49.8%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .posterNum{
            font-size: 0.40rem;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #3F74F6;
            line-height: 0.76rem;
        }
        .posterName{
            font-size: 0.28rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #0E1429;
            line-height: 0.56rem;
            margin-bottom: 0.1rem;
        }
    }
    .borderBt{
        border-bottom: 1px solid rgba(14,20,41,0.1);
    }
    .borderRg{
        border-right: 1px solid rgba(14,20,41,0.1);
    }
  }
.poster{
    .title{
      //margin: 0.4rem 0.3rem 0.3rem;
      font-size: 0.32rem;
      font-weight: bold;
      color: #0E1429;
      padding-left:0.2rem
      line-height: 48px;
    }
    .detectionTab{
        margin-left:0.2rem
        margin-top:0.1rem
        >>>.el-radio-button .el-radio-button__inner {
            width: 2.22rem;
          }
    }
    .banner{
        height:1.6rem;
        margin: 0 0.2rem 0.2rem;
    }
    .posterRecordTab{
        margin-left:0.2rem
        >>>.el-radio-button .el-radio-button__inner {
            width: 1.62rem;
          }
    }
}

  >>>.el-radio-button__inner{
    //width: 1.22rem;
    height: 0.64rem;
    font-size:0.24rem;
    font-weight: bold;
    border: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
  }
  >>>.el-radio-button__orig-radio:checked+.el-radio-button__inner{
    background-color: #E0E9FE;
    border-radius: 10px;
    border: none;
    font-size:0.24rem;
    font-weight: bold;
    color:#3F74F6;
    box-shadow: none;
  }
  >>>.el-radio-group label{
    margin-right:0.2rem;
  }
  >>>.el-radio-group label:last-child{
    margin-right:0rem;
  }
  >>>.el-radio-button:first-child .el-radio-button__inner {
    border-left: none;
    border-radius: 10px;
  }
  >>>.el-radio-button:last-child .el-radio-button__inner {
    border-radius: 10px;
  }
  .detectionCard{
    //width: 7.10rem;
    //height: 2.68rem;
    background: #FFFFFF;
    border-radius: 14px 14px 14px 14px;
    margin: 0.3rem 0.2rem 0
    padding: 0.3rem;
    box-sizing: border-box;
    .cardTime{
      display: flex
      .timeOne{
        font-size: 0.3rem;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #0E1429;
      }
      .timeTwo,.timeThree{
        font-size: 0.24rem;
        font-family: PingFangSC-Regular-, PingFangSC-Regular;
        font-weight: normal;
        color: rgba(14,20,41,0.4);
        margin-top:0.2rem;
      }
      .backPic{
        width:1.6rem;
        height:1.6rem;
      }
      .status{
        position: absolute;
        right: 0.5rem;
        font-size: 0.24rem;
        font-family: PingFangSC-Regular-, PingFangSC-Regular;
        font-weight: normal;
        .statusYellow{
          color: #FAAD14;
          display: flex;
          align-items: center;
        }
        .statusCircleYellow{
          width: 0.12rem;
          height: 0.12rem;
          background: #FAAD14;
          border-radius:50%;
          margin-right: 0.1rem;
        }
        .statusGreen{
          color: #52C41A;
          display: flex;
          align-items: center;
        }
        .statusCircleGreen{
          width: 0.12rem;
          height: 0.12rem;
          background: #52C41A;
          border-radius:50%;
          margin-right: 0.1rem;
        }
        .statusRed{
          color: #F5222D;
          display: flex;
          align-items: center;
        }
        .statusCircleRed{
          width: 0.12rem;
          height: 0.12rem;
          background: #F5222D;
          border-radius:50%;
          margin-right: 0.1rem;
        }
        .statusBlue{
          color: #3F74F6;
          display: flex;
          align-items: center;
        }
        .statusCircleBlue{
          width: 0.12rem;
          height: 0.12rem;
          background: #3F74F6;
          border-radius:50%;
          margin-right: 0.1rem;
        }
        .statusGray{
          color:rgba(14,20,41,0.4);
          display: flex;
          align-items: center;
        }
        .statusCircleGray{
          width: 0.12rem;
          height: 0.12rem;
          background: rgba(14,20,41,0.4);
          border-radius:50%;
          margin-right: 0.1rem;
        }

      }
    }
    .pay-bottom {
      height: 0.48rem;
      .pay-btn {
        width: 1.28rem;
        background: #fff;
        border-radius: .1rem;
        border: 1px solid rgba(14,20,41,0.2);
        text-align: center;
        color: #0E1429;
        font-size: .24rem;
        box-sizing: border-box;
        padding: 0.09rem 0;
        position: absolute;
        right: 0.47rem;
      }
    }
  }
  .nullPic{
    display:flex;
    flex-direction: column;
    align-items: center
    margin-top: 0.2rem;
    font-size:0.3rem
    img{
      width:3.6rem;
      height:3.6rem;
    }
  }
</style>
