<template>
  <div>
    <BossTable :loaded="!!data" :data="table" class="table"></BossTable>
    <div class="tableBottom">
      企业良好的纳税信用可以作为企业融资和招投标的
      “砝码”，以及国家各项税收优惠政策的“敲门砖”，企业需关注并努力提升自身的纳税信用等级。
    </div>
  </div>
</template>

<script>
import ColumnChart from "../../component/ColumnChart.vue";
import RingBar from "../../component/RingBar.vue";
import BossTable from "../../component/BossTable.vue";

export default {
  name: "HistogramTabs",
  components: { BossTable, RingBar, ColumnChart },
  props: {
    data: Object,
  },
  watch: {
    data: {
      handler() {
        //设置为最近一年(倒数第一)
        // this.activeIndex = Object.entries(this.data.yearChart).length - 1

        const body = this.data.body.map((row) => {
          return [
            {
              content: row["年度"],
            },
            {
              content: row["信用等级"],
            },
            {
              content: row["信用评分"],
            },
          ];
        });
        const table = {
          header: [
            [
              {
                content: "年度",
              },
              {
                content: "信用等级",
              },
              {
                content: "信用评分",
              },
            ],
          ],
          body: body,
        };
        this.table = table;
      },
      immediate: true,
    },
  },
  data() {
    return {
      table: null,
    };
  },
};
</script>

<style lang="stylus" scoped>
.tableBottom{
  padding: 8px;
  //width: 335px;
height: 70px;
background: rgba(117,133,162,0.05);
line-height: 18px;
font-size: 14px;
color: #4E5969;letter-spacing: 1px;font-family: PingFangSC-Regular, PingFang SC;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
letter-spacing: 2px;
}
.table{
  margin 16px 0 20px
}
.btn-tabs {

  border-bottom 1px dashed #7585A2;
  padding-bottom 20px
}

.tab-content {
  padding-bottom 30px
}
</style>
