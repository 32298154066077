<template>
    <div class="box">


        <div class="invoicMain">
            <!-- head start -->
            <div class="invoiceHeader">
                <div class="invoiceHeader-left">

                    <div class="headerMiddle">
                        <h3>{{ data.invoiceType }}</h3>
                        <div class="line"></div>
                    </div>
                </div>

                <ul class="headerRight">
                    <li>
                        <label> 发票代码： </label><span>{{ data.invoiceCode }}</span>
                    </li>
                    <li>
                        <label> 发票号码： </label><span>{{ data.invoiceNumber }}</span>
                    </li>
                    <li>
                        <label> 开票日期： </label><span>{{ riqi }}</span>
                    </li>
                    <!-- <li>
                    <label> 校&nbsp;&nbsp;验&nbsp;&nbsp;码： </label><span>11111 22222 33333 44444</span>
                </li> -->
                </ul>
            </div>
            <!-- head end -->
            <!-- invoice body start -->
            <div class="invoiceBody">
                <div class="userInfo">
                    <div class="buy">购买方</div>
                    <ul>
                        <li>
                            <label>名&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;称：</label><span>{{
                                data.purchaserName }}</span>
                        </li>
                        <!-- <li>
                        <label>纳税人识别号：</label><span>{{data.salesTaxNo}}</span>
                    </li> -->
                        <li>
                            <label>地址、&nbsp;&nbsp;&nbsp;电话：</label><span>{{ data.purchaserAddressPhone }}</span>
                        </li>
                        <li>
                            <label>开户行及账号：</label><span>{{ data.purchaserBank }}</span>
                        </li>
                    </ul>
                    <!-- <div class="password">密码区</div>
                <div class="pwdInfo"></div> -->
                </div>
                <div>
                    <table class="GoodsTable" cellpadding="0" cellspacing="0">
                        <tr>
                            <td style="width: 24%">货物或应税劳务、服务名称</td>
                            <td style="width: 10%">规格型号</td>
                            <td style="width: 7%">单位</td>
                            <td style="width: 10%">数量</td>
                            <td style="width: 10%">单价</td>
                            <td style="width: 16%">金额</td>
                            <td style="width: 7%">税率</td>
                            <td style="width: 16%; border-right: none;">税额</td>
                        </tr>
                        <tr class="invoice-list" v-for="(v, k) of data.sph" :key="k">
                            <td>{{ v.productName }}</td>
                            <td>{{ v.productModel }}</td>
                            <td>{{ v.productUnit }}</td>
                            <td class="textright">{{ v.productCount }}</td>
                            <td class="textright">{{ v.productPrice }}</td>
                            <td class="textright">{{ v.productTotalAmount }}</td>
                            <td class="textright">{{ v.productTaxRate }}</td>
                            <td class="textright">{{ v.productTaxAmount }}</td>
                        </tr>


                        <tr class="total">
                            <td>合计</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="textright">{{ data.totalAmount }}</td>
                            <td></td>
                            <td class="textright">{{ data.taxAmount }}</td>
                        </tr>
                        <tr class="GoodsTotal">
                            <td>价税合计(大写)</td>
                            <td colspan="7">
                                <div style="width: 100%;display:flex">
                                    <div type="text" style="width: 60%">{{ daxie }}</div>

                                    <div type="text" style="width: 30%"> (小写) &nbsp; ￥ &nbsp;{{ data.amount.toFixed(2) }}</div>
                                </div>

                            </td>
                        </tr>
                    </table>
                    <div class="userInfo">
                        <div class="buy">购买方</div>
                        <ul>
                            <li>
                                <label>名称：</label><span>{{ data.purchaserName }}</span>
                            </li>
                            <li>
                                <label>纳税人识别号：</label><span>{{ data.purchaserTaxNo }}</span>
                            </li>
                            <li>
                                <label>地址、&nbsp;&nbsp;&nbsp;电话：</label><span>{{ data.purchaserAddressPhone }}</span>
                            </li>
                            <li>
                                <label>开户行及账号：</label><span>{{ data.purchaserBank }}</span>
                            </li>
                        </ul>
                        <div class="password">备注</div>
                        <div class="pwdInfo">{{ data.remark }}</div>
                    </div>
                </div>

            </div>
            <!-- invoice body start -->
            <!-- <ul class="invoicetFooter">
            <li>
                <label>收款人：</label>
            </li>
            <li>
                <label>复核：</label>
            </li>
            <li>
                <label>开票人：</label>
            </li>
            <li>
                <label>销售方：（章）</label>
            </li>
        </ul> -->
        </div>

    </div>
</template>
<script>
export default {
    props: {
        data: Object,

    },
    computed: {
        daxie() {
            return this.numberParseChina(this.data.amount)
        },
        riqi() {
            let d = new Date(this.data.billingDate)
            let date = (d.getFullYear()) + "-" + 
           (d.getMonth() + 1) + "-" +
           (d.getDate()) + " " + 
           (d.getHours()) + ":" + 
           (d.getMinutes()) + ":" + 
           (d.getSeconds());
            return date
        }
    },
    data() {
        return {

        }
    },
    methods: {
        gettime(date, state) {
            function iscossten(val) {
                if (val < 10) {
                    return '0' + val
                } else {
                    return val
                }
            }
            let year = date.getFullYear()
            let month = iscossten(date.getMonth() + 1)
            let day = iscossten(date.getDate())
            let h = iscossten(date.getHours())
            let m = iscossten(date.getMinutes())
            let s = iscossten(date.getSeconds())
            if (state == 1) {
                return year + '-' + month + '-' + day + ' ' + h + ':' + m + ':' + s

            } else {
                return year + '-' + month + '-' + day
            }
        },
        // 阿拉伯数字转换大写
        numberParseChina(money) {
            //汉字的数字
            var cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
            //基本单位
            var cnIntRadice = ['', '拾', '佰', '仟'];
            //对应整数部分扩展单位
            var cnIntUnits = ['', '万', '亿', '兆'];
            //对应小数部分单位
            var cnDecUnits = ['角', '分', '毫', '厘'];
            //整数金额时后面跟的字符
            var cnInteger = '整';
            //整型完以后的单位
            var cnIntLast = '圆';
            //最大处理的数字
            var maxNum = 999999999999999.9999;
            //金额整数部分
            var integerNum;
            //金额小数部分
            var decimalNum;
            //输出的中文金额字符串
            var chineseStr = '';
            //分离金额后用的数组，预定义
            var parts;
            if (money == '') {
                return '';
            }
            money = parseFloat(money);
            if (money >= maxNum) {
                //超出最大处理数字
                return '';
            }
            if (money == 0) {
                chineseStr = cnNums[0] + cnIntLast + cnInteger;
                return chineseStr;
            }
            //转换为字符串
            money = money.toString();
            if (money.indexOf('.') == -1) {
                integerNum = money;
                decimalNum = '';
            } else {
                parts = money.split('.');
                integerNum = parts[0];
                decimalNum = parts[1].substr(0, 4);
            }
            //获取整型部分转换
            if (parseInt(integerNum, 10) > 0) {
                var zeroCount = 0;
                var IntLen = integerNum.length;
                for (var i = 0; i < IntLen; i++) {
                    var n = integerNum.substr(i, 1);
                    var p = IntLen - i - 1;
                    var q = p / 4;
                    var m = p % 4;
                    if (n == '0') {
                        zeroCount++;
                    } else {
                        if (zeroCount > 0) {
                            chineseStr += cnNums[0];
                        }
                        //归零
                        zeroCount = 0;
                        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
                    }
                    if (m == 0 && zeroCount < 4) {
                        chineseStr += cnIntUnits[q];
                    }
                }
                chineseStr += cnIntLast;
            }
            //小数部分
            if (decimalNum != '') {
                var decLen = decimalNum.length;
                for (var i = 0; i < decLen; i++) {
                    var n = decimalNum.substr(i, 1);
                    if (n != '0') {
                        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
                    }
                }
            }
            if (chineseStr == '') {
                chineseStr += cnNums[0] + cnIntLast + cnInteger;
            } else if (decimalNum == '') {
                chineseStr += cnInteger;
            }
            return chineseStr;
        }

    }
}
</script>

<style scoped>
.textright {
    text-align: right;
}

.box {
    overflow: auto;
    padding-top: 80px;
    padding-bottom: 20px;
}

ul,
ul li {
    list-style: none;
    margin: 0;
    padding: 0;
}

label {
    color: #9c5223;
}

.invoicMain {
    width: 600px;
    margin: 0 auto;
    font-size: 12px;
    color: #000;
    padding: 10px;
    border: 3px solid #000;
    box-sizing: border-box;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
}

.invoiceHeader {
    height: 126px;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}

.headerLeft li:nth-child(1) {
    text-indent: 1em;
}

.headerLeft li img {
    width: 105px;
    height: 105px;

}

.headerMiddle h1 {
    font-size: 32px;
    color: #9c5223;
    font-family: 'kaiti';
    margin: 5px 12px;
}

.line {
    height: 2px;
    border-top: #9c5223 1px solid;
    border-bottom: #9c5223 1px solid;
}

.headerRight li {
    line-height: 24px;
}

.invoiceBody {
    border: 1px solid #9c5223;
}

.userInfo {
    width: 100%;
    display: flex;
    align-items: center;
    height: 96px;
    border-bottom: 1px solid #9c5223;
}

.userInfo ul {
    width: 50%;
    margin: 0 5px;
    padding: 0;

}

.userInfo ul li {
    line-height: 24px;
    width: 300px;
}

.buy {
    width: 20px;
    border-right: 1px solid #9c5223;
    padding: 0 10px;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    color: #9c5223;
}

.password {
    width: 20px;
    padding: 0 10px;
    border-right: 1px solid #9c5223;
    border-left: 1px solid #9c5223;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    color: #9c5223;
}

.pwdInfo {
    flex: 1;
    padding-left: 5px;
    word-break: break-all;
    word-wrap: break-word;
    white-space: pre-wrap;
    text-overflow: hidden;

}

.goodsInfo {
    height: 210px;
    margin: 0;
    padding: 0;

}

.goodsInfo li {
    display: flex;
    color: #9c5223;
    text-align: center;
}

.name {
    width: 260px;
    border-right: 1px solid #9c5223;
}

.spec {
    width: 140px;
    border-right: 1px solid #9c5223;
}

.qty {
    width: 108px;
    border-right: 1px solid #9c5223;
}

.unit,
.taxRate {
    width: 65px;
    border-right: 1px solid #9c5223;
}

.qty,
.price {
    width: 160px;
    border-right: 1px solid #9c5223;
}

.money {
    flex: 1;
    border-right: 1px solid #9c5223;
}

.taxAmount {
    flex: 1;
}

.GoodsTable {
    height: 110px;
    width: 100%;
    border-collapse: collapse;
}

.GoodsTable .invoice-list {
    /* height: 24px; */
}

.GoodsTable td {
    border-right: 1px solid #9c5223;
    color: #9c5223;
}

.GoodsTable tr:nth-child(1),
.GoodsTable tr:nth-last-child(2) {
    height: 24px;
}

.GoodsTable tr:nth-last-child(1) {
    height: 34px;
}

.GoodsTable tr:nth-child(1) td {
    text-align: center;
}

.GoodsTotal {
    border-top: 1px solid #9c5223;
    border-bottom: 1px solid #9c5223;
}

.total td:nth-child(1) {
    text-align: center;
}

.invoicetFooter {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
}

.invoicetFooter li {
    width: 25%;
}

.invoiceHeader-left {
    width: 67%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.headerRight {
    margin-left: 28px;
}

.headerMiddle {
    margin-right: 15px;
}
</style>

