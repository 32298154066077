<template>
  <div>
    <div class="content-section">
      <div class="title">{{ this.data.modelName }}</div>
      <div class="danger" v-if="this.data.text">
        <div>{{ this.data.text }}</div>
      </div>
    </div>
    <div ref="theChart" className="echarts1" id="echarts1"></div>
    <!-- <BossTable :loaded="!!table" :data="table" class="table"></BossTable> -->
    <div>
      <table class="table">
        <tbody>
          <tr>
            <td class="headTable">
              {{ this.data.data2.ruleOfInvoiceImageList[0].imageTitle }}
              <i v-for="item in this.data.data2.body">
                <el-tooltip class="item" effect="dark" :content="item[0].tip">
                  <i class="el-icon-warning" style="color: #4e5969"></i>
                </el-tooltip>
              </i>
            </td>
            <td class="headTable">本企业</td>
            <td class="headTable">行业平均</td>
          </tr>
          <tr v-for="item in this.data.data2.body">
            <td>{{ ruleOfInvoiceImageList[0] }}</td>
            <td>{{ item[1].content }}</td>
            <td>{{ item[2].content }}</td>
          </tr>
          <tr v-for="item in this.data.data2.body">
            <td>{{ ruleOfInvoiceImageList[1] }}</td>
            <td>{{ item[3].content }}</td>
            <td>{{ item[4].content }}</td>
          </tr>
          <tr v-for="item in this.data.data2.body">
            <td>{{ ruleOfInvoiceImageList[2] }}</td>
            <td>{{ item[5].content }}</td>
            <td>{{ item[6].content }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { round } from "@/utils/format";
import BossTable from "./BossTable.vue";

export default {
  name: "bossThreeEchart",
  components: { BossTable },
  props: {
    data: Object,
  },
  data() {
    return {
      chartColumnM: null,
      chartData: [],
    };
  },
  computed: {
    ruleOfInvoiceImageList() {
      return this.data.data2.ruleOfInvoiceImageList[0].xLabels.map((value) => {
        return value;
      });
    },
    table() {
      return { header: this.data.data2.header, body: this.data.data2.body };
    },

    unit() {
      return "%";
    },
    headers() {
      return this.data.data2.ruleOfInvoiceImageList[0].series.map((value) => {
        return value.name;
      });
    },
    series() {
      const ret = [];
      const series = [
        { type: "line", color: "#D6B35B", barWidth: "30" },
        { type: "bar", color: "#597EF7", barWidth: "30" },
      ];
      for (let i = 0; i < this.headers.length; i++) {
        const aSeries = series[i];
        aSeries.name = this.headers[i];
        aSeries.data = this.data.data2.ruleOfInvoiceImageList[0].series[i].data;
        ret.push(aSeries);
      }
      let lastSeries = ret.pop();
      const min = lastSeries.data.map((v) => v * 0.99);
      ret.push({
        name: "Placeholder",
        type: "bar",
        stack: "Total",
        itemStyle: {
          borderColor: "transparent",
          color: "transparent",
        },
        emphasis: {
          itemStyle: {
            borderColor: "transparent",
            color: "transparent",
          },
        },
        data: min,
      });

      lastSeries.data = lastSeries.data.map((v) => v * 0.02);
      lastSeries.stack = "Total";
      ret.push(lastSeries);
      return ret;
    },
  },
  mounted() {
    const options = {
      legend: {
        data: this.headers,
        x: "center",
        bottom: "5%",
      },
      tooltip: {
        trigger: "axis",
        position: "bottom",
        axisPointer: { type: "shadow" },
        formatter: (params) => {
          let text = "";
          text +=
            this.data.data2.ruleOfInvoiceImageList[0].series[0].name +
            ":" +
            this.data.data2.ruleOfInvoiceImageList[0].series[0].data[params[0].dataIndex] +
            this.unit +
            "<br/>";
          const value =
            this.data.data2.ruleOfInvoiceImageList[0].series[1].data[params[0].dataIndex];
          const lowValue = round(value * (1 - 0.01), 2);
          const upValue = round(value * (1 + 0.01), 2);
          const text2 =
            this.data.data2.ruleOfInvoiceImageList[0].series[1].name +
            ":" +
            lowValue +
            this.unit +
            "~" +
            upValue +
            this.unit;
          text += text2;
          return text;
        },
      },
      xAxis: {
        type: "category",
        splitLine: {
          show: true,
          lineStyle: {
            color: "#EEEEEE",
            width: 1,
            type: "solid",
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#666",
          },
        },
        axisLabel: {
          interval: 0,
          textStyle: {
            fontSize: 11,
            fontFamily: "PingFangSC-Regular,PingFang SC",
            color: "rgba(0,0,0,0.6)",
          },
        },
        data: this.data.data2.ruleOfInvoiceImageList[0].xLabels,
      },
      grid: {
        left: "3%",
        right: "5%",
        top: "2%",
        bottom: "15%",
        containLabel: true,
      },
      yAxis: {
        type: "value",
        axisLabel: { formatter: `{value} ${this.unit}` },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#666",
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#EEEEEE",
            width: 1,
            type: "dashed",
          },
        },
      },
      series: this.series,
    };
    const myChart = echarts.init(this.$refs.theChart);
    myChart.setOption(options);
  },
};
</script>
<style lang="stylus" scoped>
#echarts1 {
  width: 100%;
  height: 300px;

  >>> div:first-child {
    width: 100% !important;
  }
}

.content-section {
    padding: 0px
    background: #FFFFFF;
    margin:0.6rem 0 0.3rem;
    display: flex
    justify-content: space-between

    .title,.danger {
      display flex
      align-items center
      font-size: 17px;
      font-weight: 400;
      color: #1D2129;
      line-height: 24px;
      font-weight: bold;
    }

    .title::before {
      content: '';
      display: inline-block;
      width: 0.08rem;
      height: 0.32rem;
      background: #2663F6;
      border-radius: 4px;
      margin-right: 0.2rem;
    }
    .danger{
        width: 35.2vw;
        height: 3.2666vh;
        background: rgba(234,108,91,0.08);
        border-radius: 4px;
        border: 1px solid #EA6C5B;
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #EA6C5B;
        line-height: 18px;
    div{
      margin auto;
    }
    }
  }

  >>>tr th .content{
    text-align: center;
  }
  .echarts1 {
    width: 533px;
    height: 300px;

    >>> div:first-child {
      width: 100% !important;
    }
  }

  .table {
    width 100%
    margin-top 20px
    border:1px solid  #73A0FA;
    border-collapse:collapse;
  }
  :deep(table) {
    //border-top: 1px solid #73A0FA;
    //border-left: 1px solid #73A0FA;
  .headTable {
    background: #e1eafd;
    font-weight: bold
  }

    td {
      border-right: 1px solid #73A0FA;
      border-bottom: 1px solid #73A0FA;
    }
  }
</style>
