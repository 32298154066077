<template>
  <div>
    <div class="topdiv">
      <div>
        <span class="totalNum">{{ data.undeclared }}</span>
        <span class="fontStyle">零申报月份数量</span>
      </div>
      <div>
        <span class="totalNum">{{ data.continuousUndeclared }}</span>
        <span>连续3个月零申报次数</span>
      </div>
    </div>
    <div class="bottomdiv">
      <div>零申报月份为：</div>
      <div>{{ months }}</div>
      <div>零申报的影响：</div>
      <div>
        企业在正常经营的状态下，一般不会出现增值税多次或者连续零申报的情况，可能存在错误适用增值税免税政策、接受虚开发票、少申报应税收入等风险，请注意自查。
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: Object,
  },
  computed: {
    months() {
      return this.data.months.join(",");
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="stylus" scoped>
.bottomdiv{
    font-size 14px;
    margin-top 12px;
color: #4E5969;;
line-height: 18px;
letter-spacing: 2px;
font-size: 14px;
background: rgba(117,133,162,0.05);
padding: 8px
>div{
    margin-bottom: 8px;
    word-break:break-all;
word-wrap:break-word;
white-space:pre-wrap;
}
>div:last-child{
    margin-bottom: 0px;

}
}
.topdiv{
    margin-top 12px;
    display: flex;
    justify-content: space-between
    >div{
        box-sizing: border-box;
        padding: 12px 0px;
        width: 150px;
        color: #4E5969;
height: 70px;
background: #E5F0FF;
border-radius: 8px;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
font-size 14px
>span:first-child{
    font-size 24px
}

    }
}
.totalNum{
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #4E5969;
    line-height: 32px;
}
.fontStyle{
    color: #4E5969;
    font-size: 14px;
}
</style>
