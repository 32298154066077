<template>
  <div class="order-container">
    <payItem class="good" :type="order" :item="goodInfo" @changeNum="changeNum"></payItem>
    <goodInfos class="info" :goodInfo="goodInfo.productConfigJson" style="display: none"></goodInfos>
    <div class="submit-account" style="display: none" v-if="goodInfo.templateId != 6">
      <div class="title">系统账号</div>
      <div class="pay-bottom-text" v-if="goodInfo.prompt">
        <i class="el-icon-warning-outline"></i>
        <div>
          {{ goodInfo.prompt }}
        </div>
      </div>
      <div class="item">
        <div>企业名称</div>
        <div>
          <el-input :disabled="selectCompany" v-model.trim="companyName" placeholder="请输入公司名称"></el-input>
        </div>
      </div>
      <div class="item item-botom">
        <div>手机号</div>
        <div><el-input v-model.trim="mobile" placeholder="请输入手机号"></el-input></div>
        <i class="el-icon-mobile-phone item-phone" @click="showPhoneList"></i>
        <i v-if="mobile" class="el-icon-circle-close item-close" @click="cancelPhoneList"></i>
      </div>
    </div>
    <div class="submit-account">
      <div class="title">产品详情信息</div>
      <div class="pay-bottom-text" v-if="goodInfo.prompt">
        <i class="el-icon-warning-outline"></i>
        <div>
          {{ goodInfo.prompt }}
        </div>
      </div>
      <div class="itemDetail">
        <div>有效期</div>
        {{ goodInfo.effectiveDays }}{{ goodInfo.unit }}
      </div>
      <div class="itemDetail">
        <div>客服电话</div>
        {{ goodInfo.orderPhone ? goodInfo.orderPhone : "-" }}
      </div>
      <div class="itemDetail">
        <div>购买须知</div>
        本产品不支持退款
      </div>
    </div>
    <div class="btn-c" v-if="goodInfo">
      <div class="btn" @click="submit">
        立即支付 ￥{{ Number(goodsCount) * Number(goodInfo.currentPrice) }}
      </div>
    </div>
    <!-- <el-drawer title="已购买手机号" :visible.sync="drawer" :direction="direction">
      <div class="isUserName">
        <div v-for="(item, index) in orderCompanyList" class="items" @click="selectPhone(index)">
          {{ item.username }}({{ item.companyName }})
        </div>
      </div>
    </el-drawer> -->
  </div>
</template>

<script>
import payItem from "./components/pay-item2.vue";
import goodInfos from "./components/good-info.vue";
import { Dialog } from 'vant';
import {
  getPayInfo,
  unifiedOrder, discount
  // checkPhoneNumber,
  // getBuyLoginInfoList,
} from "@/api/fri";

export default {
  metaInfo: {
    title: "产品订单",
  },
  components: {
    payItem,
    goodInfos,
  },
  data() {
    return {
      order: 2,
      companyName: "",
      mobile: "",
      pwd: "",
      pwd2: "",
      goodInfo: {},
      payInfo: {},
      item: this.$route.query.item ? JSON.parse(this.$route.query.item) : {},
      goodsCount: 1,
      // orderCompanyList: [],
      orderCompany: "",
      drawer: false,
      direction: "btt",
      selectCompany: false,
      channelUniqueId: localStorage.getItem("channelUniqueId"),
      base: process.env.VUE_APP_API_ROOT,
    };
  },
  mounted() {
    this.getGoodInfo();
  },
  methods: {
    selectPhone(act) {
      // this.companyName = this.orderCompanyList[act].companyName;
      // this.mobile = this.orderCompanyList[act].username;
      this.drawer = false;
      this.selectCompany = true;
    },
    showPhoneList() {
      this.drawer = true;
    },
    changeNum(val) {
      this.goodsCount = val;
    },

    async submit() {
      // if (!this.companyName || !this.mobile) {
      //   this.$message({
      //     message: "请输入完整信息！",
      //     type: "warning",
      //   });
      //   return;
      // }
      this.loading = this.$loading({
        lock: true,
        text: "正在提交...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      // let params = {
      //   goodsId: this.goodInfo.policyId,
      //   phoneNumber: this.mobile,
      // };
      //支付订单前校验系统登录手机号
      // let checkStatus = await checkPhoneNumber(params);
      // if (checkStatus.code != 200) {
      //   this.loading.close();
      // }
      // if (checkStatus.data) {
      let channelUniqueId = localStorage.getItem("channelUniqueId");

      let params = {

        goodsCount: this.goodInfo.bundlingNum?this.goodInfo.bundlingNum:this.goodsCount,
        // goodsCount: this.goodsCount,
        marketingStrategyId: this.goodInfo.marketingStrategyId,
        policyId: this.goodInfo.policyId,

        channelUniqueId: channelUniqueId,
        posterUniqueId: localStorage.getItem("posterUniqueId"),

      };
      if (this.goodInfo.bundlingNum != null) {
        discount(params).then((res) => {
          this.loading.close();

          if (res.code == 200) {
            this.$message({
              message: '领取成功',
              type: "success",
            });
            let id = this.goodInfo.policyId;
            this.$router.push({
              path: "/paymentSuccessful",
              query: {
                id: id,
              },
            });
          }else if(res.code==5201){
            Dialog.alert({
              title: '温馨提示',
              message: res.message,
              confirmButtonText:'知道了',
              confirmButtonColor:'#165DFF',
              theme: 'round-button',
            }).then(() => {
              // on close
                this.getGoodInfo()
            });
          }
          
        })
      } else {
        // 发起预支付
        unifiedOrder(params).then((res) => {
          console.log(res);
          this.loading.close();
          if (res.code == 200) {
            this.payInfo = res.data;
            this.StartWeiXinPay(this.payInfo);
            if (res.code != 200 && res.code != 5001) {
              this.$message({
                message: res.data,
                type: "warning",
              });
            }
          }
        });
      }


    },
    getGoodInfo() {
      let params = {
        policyId: this.$route.query.id,
        channelUniqueId: this.channelUniqueId,
        posterUniqueId: localStorage.getItem("posterUniqueId"),
      };
      getPayInfo(params).then((res) => {
        if (res.code == 200) {
          this.goodInfo = res.data;
        }
      });
    },
    StartWeiXinPay(pay_params) {
      console.log(pay_params);
      if (typeof WeixinJSBridge === "undefined") {
        if (document.addEventListener) {
          alert(1);
          document.addEventListener("WeixinJSBridgeReady", this.onBridgeReady(pay_params), false);
        } else if (document.attachEvent) {
          alert(2);
          document.attachEvent("WeixinJSBridgeReady", this.onBridgeReady(pay_params));
          document.attachEvent("onWeixinJSBridgeReady", this.onBridgeReady(pay_params));
        }
      } else {
        this.onBridgeReady(pay_params);
      }
    },

    onBridgeReady(pay_params) {
      let id = this.goodInfo.policyId;

      var that = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: "wx4de932054829ef8c", //公众号ID，由商户传入
          timeStamp: pay_params.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: pay_params.nonceStr, //随机串
          package: "prepay_id=" + pay_params.prepayId,
          signType: "MD5", //微信签名方式：
          paySign: pay_params.sign, //微信签名
        },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // that.$router.push({
            //   path: "/detail",
            //   query: {
            //     orderNo: pay_params.orderNo,
            //   },
            // });
            //支付成功跳转首页

            that.$router.push({
              path: "/paymentSuccessful",
              query: {
                id: id,
              },
            });
            // location.href = this.base + "/paymentSuccessful" + "?id=" + this.goodInfo.policyId;
          } else if (res.errMsg == "get_brand_wcpay_request:ok") {
            // location.href = this.base + "/paymentSuccessful" + "?id=" + this.goodInfo.policyId;
            that.$router.push({
              path: "/paymentSuccessful",
              query: {
                id: id,
              },
            });
          } else {
            alert("支付失败");
          }
        }
      );
    },
    // getCompanyList() {
    // 获取已经购买过的手机号公司名称
    // getBuyLoginInfoList(this.goodInfo.policyId).then((res) => {
    //   console.log(res);
    //   this.orderCompanyList = res.data;
    // });
    // },
    // orderChange() {
    // this.companyName = this.orderCompanyList[this.orderCompany].companyName;
    // this.mobile = this.orderCompanyList[this.orderCompany].username;
    // },
    cancelPhoneList() {
      this.mobile = "";
      this.companyName = "";
      this.selectCompany = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.good {
  margin: 0.2rem 0 0.2rem 0.2rem;
}

.info {
  margin-left: 0.2rem;
}

.submit-account {
  width: 7.1rem;
  background: #FFFFFF;
  border-radius: 0.2rem;
  opacity: 1;
  font-size: 0.28rem;
  margin: 0.2rem 0 0 0.2rem;
  padding: 0.3rem 0.2rem;
  box-sizing: border-box;
  padding-right: 0.3rem;
  padding-bottom: 0.2rem;
  .title{
    font-size: 0.32rem;
    line-height: 0.46rem;
    padding: 0.3rem 0;
    padding-top: 0;
    font-weight: bold;
  }

  .item,.itemDetail{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1rem;
    border-bottom: 1px solid rgba(14, 20, 41, 0.1);
    box-sizing: border-box;

    .item-phone {
      position: absolute;
      right: -8px;
      padding: 0 6px;
    }

    .item-close {
      position: absolute;
      right: 12px;
      padding: 0 6px;
      color: #C0C4CC;
    }

    >>> .el-input {
      width: 3.4rem;
    }

    >>> .el-input__inner {
      border: none;
      padding-right: 0;
    }

    >>> .el-input__inner::placeholder {
      font-size: 0.3rem;
      text-align: right;
      font-size: 0.28rem;
    }

    >>> .el-input--suffix .el-input__inner {
      padding-right: 0;
    }

    >>> .el-input.is-disabled .el-input__inner {
      background: #fff;
      color: #606266;
    }
  }
  .itemDetail{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 0.7rem;
    box-sizing: border-box;
    border-bottom: 0px solid #fff;
  }
  .item-botom {
    border-bottom: none;

    >>> .el-input__inner {
      padding-right: 18px;
    }
  }
}

.btn {
  //width: 6.9rem;
  height: 0.88rem;
  line-height: 0.88rem;
  //margin-bottom: 0.68rem;
  background: #165DFF;
  border-radius: 0.1rem;
  opacity: 1;
  font-size: 0.34rem;
  text-align: center;
  color: #fff;

}

.btn-c {
  position:fixed;
  bottom: 0;
  width: 100%;
  background: #FFFFFF;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  padding: 0.2rem 0.3rem;
  box-sizing: border-box;
  margin-top: 0.4rem;
}

.order-container {
  padding-bottom: 1.6rem;
}

.pay-bottom-text {
  display: flex;
  align-items: baseline;
  width: 6.5rem;
  background: rgba(253,179,32,0.08);
  border-radius: 0.1rem;
  opacity: 1;
  // margin-top: 0.3rem;
  color: #FDB320;
  font-size: 0.24rem;
  padding: 0.2rem 0.3rem 0.2rem 0.2rem;
  box-sizing: border-box;
  line-height: 1.7;
  margin-bottom: 0.2rem;

  .el-icon-warning-outline {
    margin-right: 0.1rem;
  }
}

.isUserName {
  font-size: 0.32rem;
  font-family: PingFangSC-Regular-, PingFangSC-Regular;
  font-weight: normal;
  color: #0E1429;
  padding: 0 30px;

  .items {
    padding: 0.12rem 0;
    // border-bottom: 1px solid #0E1429;
  }
}
</style>
