<template>
  <div>
    <van-dialog
      v-model="show"
      title="选择税种(最多三项):"
      show-cancel-button
      @confirm="confirm"
      confirmButtonColor="#2663F6"
    >
      <div class="btns">
        <div
          v-for="(v, k) of btns"
          :key="k"
          @click="choonse(v.value, k)"
          :class="v.selected == true ? 'selected' : ''"
        >
          {{ v.label }}
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selected: ["增值税", "教育费附加"],
      show: false,
      btns: [
        {
          label: "增值税",
          value: "增值税",
          selected: true,
        },
        {
          label: "教育费附加",
          value: "教育费附加",
          selected: true,
        },
        {
          label: "地方教育附加",
          value: "地方教育附加",
          selected: false,
        },
        {
          label: "城市建设维护税",
          value: "城市建设维护税",
          selected: false,
        },
        {
          label: "企业所得税",
          value: "企业所得税",
          selected: false,
        },
        {
          label: "个人所得税",
          value: "个人所得税",
          selected: false,
        },
        {
          label: "消费税",
          value: "消费税",
          selected: false,
        },
        {
          label: "城镇土地使用税",
          value: "城镇土地使用税",
          selected: false,
        },
        {
          label: "房产税",
          value: "房产税",
          selected: false,
        },
        {
          label: "契税",
          value: "契税",
          selected: false,
        },
        {
          label: "耕地占用税",
          value: "耕地占用税",
          selected: false,
        },
        {
          label: "土地增值税",
          value: "土地增值税",
          selected: false,
        },
        {
          label: "印花税",
          value: "印花税",
          selected: false,
        },
        {
          label: "车船税",
          value: "车船税",
          selected: false,
        },
        {
          label: "烟叶税",
          value: "烟叶税",
          selected: false,
        },
        {
          label: "环境保护税",
          value: "环境保护税",
          selected: false,
        },
        {
          label: "资源税",
          value: "资源税",
          selected: false,
        },
        {
          label: "车辆购置税",
          value: "车辆购置税",
          selected: false,
        },
      ],
    };
  },
  methods: {
    confirm() {
      this.$emit("confirm", this.selected);
    },
    open() {
      this.show = true;
    },
    choonse(v, k) {
      if (this.selected.length == 3) {
        let index = this.selected.findIndex((item) => item == v);
        if (index == -1) {
        } else {
          this.selected.splice(index, 1);
          this.btns[k].selected = false;
        }
      } else {
        let index = this.selected.findIndex((item) => item == v);
        if (index == -1) {
          this.selected.push(v);
          this.btns[k].selected = true;
        } else {
          this.selected.splice(index, 1);
          this.btns[k].selected = false;
        }
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.btns{
    display: flex;
    flex-wrap: wrap;
    padding: 12px
    >div:nth-child(3n){
        margin-right: 0px;

    }
    >div{
        text-align: center;
        width: 31%;
        height: 32px;
        line-height: 32px;
        margin-right: 5px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #E5E6EB;
        color: #86909C;
        margin-bottom 12px;
        font-size :12px
    }
    >div.selected{
        color: #2663F6;
        border: 1px solid #2663F6;
    }
}
</style>
