<template>
  <div>
    <div ref="theChart" class="echarts1">{{ data }}</div>
  </div>
</template>
<script>
import echarts from "echarts";

export default {
  name: "DoubleColumnChart",
  data() {
    return {
      chartColumnM: null,
      chartData: [],
    };
  },
  props: {
    data: Object,
    width: String,
    height: String,
  },
  computed: {
    unit() {
      return this.data.unit || "万元";
    },
    headers() {
      return this.data.timeSlot;
    },
    series() {
      const ret = [];

      const colors = [
        { type: "bar", color: "#73A0FA", barWidth: "30" },
        { type: "bar", color: "#73DEB3", barWidth: "30" },
        { type: "bar", color: "#7585A2", barWidth: "30" },
      ];

      for (let i = 0; i < this.headers.length; i++) {
        const element = this.headers.length[i];
        const color = colors[colors.length - i - 1];
        ret.push(color);
      }
      return ret;
    },
    source() {
      const ret = [];

      this.data.diagramResultDtoList.forEach((grp) => {
        const item = {};
        grp.data.forEach((v) => {
          item[v.label] = parseFloat(v.value);
        });
        item.product = grp.product;
        ret.push(item);
      });

      // grp.data.forEach((v,index) => {
      //   item[this.data.xLabels[index]] = parseFloat(v);
      // });

      return ret;
    },
  },
  mounted() {
    if (this.width) {
      this.$refs.chartColumn.style.width = this.width;
    }

    if (this.height) {
      this.$refs.chartColumn.style.height = this.height;
    }
    const myChart = echarts.init(this.$refs.theChart);
    const options = {
      title: [
        {
          subtext: "万元", //副标题
          itemGap: 1, //主副标题间距
        },
      ],
      legend: {
        x: "center",
        bottom: "5%",
        data: this.data.timeSlot,
      },
      tooltip: {
        formatter: (params) => {
          // const text = s.unSmooth(params.value[params.seriesName]);
          const text = params.value[params.seriesName];
          return text + this.unit;
        },
      },
      dataset: {
        dimensions: ["product", ...this.headers],
        source: this.source,
      },
      color: ["#73A0FA", "#73DEB3", "#7585A2"],
      xAxis: {
        type: "category",
        splitLine: {
          show: true,
          lineStyle: {
            color: "#EEEEEE",
            width: 1,
            type: "solid",
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#666",
          },
        },
        axisLabel: {
          // rotate: 35,
          interval: 0,
          textStyle: {
            fontSize: 11,
            fontFamily: "PingFangSC-Regular,PingFang SC",
            color: "rgba(0,0,0,0.6)",
          },
        },
      },
      grid: {
        left: "3%",
        right: "5%",
        top: "10%",
        bottom: "20%",
        containLabel: true,
      },
      yAxis: {
        type: "value",
        axisLabel: { formatter: `{value} ` },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#666",
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#EEEEEE",
            width: 1,
            type: "dashed",
          },
        },
      },
      // Declare several bar series, each will be mapped
      // to a column of dataset.source by default.
      series: this.series,
    };
    myChart.setOption(options);
  },
};
</script>
<style lang="stylus" scoped>
.echarts1 {
  width: 100%;
  height: 300px;

  >>> div:first-child {
    width: 100% !important;
  }
}
</style>
