import homePage from "@/pages/index.vue";
import scanTime from "@/pages/scanTime.vue";
import authorize from "@/pages/authorize.vue";
//我的
import my from "@/pages/my.vue";
//产品订单
import order from "@/pages/order.vue";
//提交成功
import success from "@/pages/success.vue";

import approval from "@/pages/approval.vue";
//支付详情
import detail from "@/pages/detail.vue";
//支付成功
import paymentSuccessful from "@/pages/paymentSuccessful.vue";

import orderInformation from "@/pages/orderInformation.vue";
import dataAuthorization from "@/pages/dataAuthorization.vue";
//报告生成中
import reportBeing from "@/pages/reportBeing.vue";

import posterInformation from "@/pages/posterInformation.vue";
//海报展示
import previewPoster from "@/pages/previewPoster.vue";
//推广详情
import previewDetail from "@/pages/previewDetail.vue";
import noPoster from "@/pages/noPoster.vue";

// 老板看报告
import UserOnly from "@/pages/Result/UserOnly";
import BossWatchReport from "@/pages/Result/bossWatchReport/BossWatchReport";
import FinanceAnalysis from "@/pages/Result/bossWatchReport/financeAnalysis/FinanceAnalysis";
import IndustryComparison from "@/pages/Result/bossWatchReport/IndustryComparison/IndustryComparison";
import OneKeyWatchInvoice from "@/pages/Result/bossWatchReport/OneKeyWatchInvoice/OneKeyWatchInvoice";
import TaxAnalysis from "@/pages/Result/bossWatchReport/TaxAnalysis/TaxAnalysis";
import ReportDetails from "@/pages/Result/bossWatchReport/ReportDetails/ReportDetails";
import SpecialInvoiceDetail from "@/pages/Result/bossWatchReport/specialInvoinceDetail/SpecialInvoiceDetail";



const routerConfig = [{
  path: "/",
  component: homePage,
  name: "homePage"
}, 
{
  path: "/scanTime",
  component: scanTime,
  name: "scanTime"
},

{
  path: "/my",
  component: my,
  name: "my"
}, {
  path: "/order",
  component: order,
  name: "order"
}, {
  path: "/authorize",
  component: authorize,
  name: "authorize"
}, {
  path: "/success",
  component: success,
  name: "success"
}, {
  path: "/approval",
  component: approval,
  name: "approval"
}, {
  path: "/detail",
  component: detail,
  name: "detail"
}, {
  path: "/paymentSuccessful",
  component: paymentSuccessful,
  name: "paymentSuccessful"
}, 
 {
  path: "/orderInformation",
  component: orderInformation,
  name: "orderInformation"
}, 
{
  path: "/dataAuthorization/:uuid?/:reportId?",
  component: dataAuthorization,
  name: "dataAuthorization"
}, 
 {
  path: "/reportBeing",
  component: reportBeing,
  name: "reportBeing"
}, 
 {
  path: "/posterInformation",
  component: posterInformation,
  name: "posterInformation"
}, 
 {
  path: "/previewPoster",
  component: previewPoster,
  name: "previewPoster"
}, 
 {
  path: "/previewDetail",
  component: previewDetail,
  name: "previewDetail"
},{
  path: "/noPoster",
  component: noPoster,
  name: "noPoster"
},
 // 老板看报告
{
  path: "/userOnly/:token?/",
  component: UserOnly,
  props: true,
  children: [
    {
      path: "boss-watch-report/:uuid/:reportId/:desensitizationStatus?",
      component: BossWatchReport,
      name: "boss-watch-report",
      props: true,
      hidden: true,
    },
    //财务分析
    {
      path: "boss-watch-report/:uuid/finance-analysis",
      component: FinanceAnalysis,
      name: "financeAnalysis",
      hidden: true,
      props: true,
    },
    //行业对比
    {
      path: "boss-watch-report/:uuid/industry-comparison",//ok
      component: IndustryComparison,
      name: "IndustryComparison",
      hidden: true,
      props: true,
    },
    //一键看票
    {
      path: "boss-watch-report/:uuid/one-key-watch-invoice/:desensitizationStatus?",//
      component: OneKeyWatchInvoice,
      name: "OneKeyWatchInvoice",
      hidden: true,
      props: true,
    },
    // {
    //   path: "boss-watch-report/:uuid/tax-risk-analysis",
    //   component: TaxRiskAnalysis,
    //   name: "TaxRiskAnalysis",
    //   hidden: true,
    //   props: true,
    // },
    //纳税分析
    {
      path: "boss-watch-report/:uuid/tax-analysis",//
      component: TaxAnalysis,
      name: "TaxAnalysis",
      hidden: true,
      props: true,
    },
    //特殊发票明细
    {
      path: "boss-watch-report/:uuid/special-invoice-detail/:txType/:desensitizationStatus?",
      component: SpecialInvoiceDetail,
      name: "SpecialInvoiceDetail",
      hidden: true,
      props: true,
    },
    //报告详情
    {
      path: "boss-watch-report/:uuid/report-details/:reportId",
      component: ReportDetails,
      name: "ReportDetails",
      hidden: true,
      props: true,
    },

  ]
},
];

export default routerConfig;
