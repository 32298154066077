<template>
  <div>
    <div class="whiteStyle"></div>
    <div>
      <div class="h5-wrapper">
        <div class="level" v-show="alert">等级偏低，需关注</div>
        <div class="content-section">
          <div class="title">纳税信用等级</div>
          <DebtPayingAbilityTabs
            :data="solvencyAnalysis"
            v-if="solvencyAnalysis"
          ></DebtPayingAbilityTabs>
        </div>
        <div class="content-section">
          <div class="title">历年纳税情况</div>

          <RingBarTabs :data="typeData" v-if="typeData"></RingBarTabs>
        </div>
        <div class="content-section">
          <div class="title">纳税资金流出情况</div>
          <button class="selectButton" @click="startSelect">选择税种</button>
          <dialogs ref="dialogs" @confirm="confirm($event)"></dialogs>

          <LineBarTabs
            :data="lineData"
            ref="LineBarTabs"
            v-if="lineData"
            @tabschange="tabschange($event)"
            :activeIndex="activeIndex"
          ></LineBarTabs>
        </div>
        <div class="content-section" v-if="yearData">
          <div class="title">{{ yearData.year + "年增值税申报情况" }}</div>
          <report :data="yearData"></report>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "../bossWatchReport.styl";
import DebtPayingAbilityTabs from "./components/DebtPayingAbilityTabs.vue";
import RingBarTabs from "./components/RingBarTabs.vue";
import LineBarTabs from "./components/LineBarTabs.vue";
import report from "./components/report.vue";
import dialogs from "./components/dialogs.vue";

import {
  getTaxCreditRating,
  getTaxYearSituation,
  getTaxOverFlowSituation,
  getVatDeclaration,
} from "@/api/bossWatchReport";
export default {
  metaInfo: {
    title: "纳税分析",
  },
  name: "IndustryComparison",
  components: { DebtPayingAbilityTabs, dialogs, RingBarTabs, LineBarTabs, report },
  data() {
    return {
      property: "value",
      alert: false,
      solvencyAnalysis: null,
      typeData: null,
      lineData: null,
      yearData: null,
      activeIndex: 3,
    };
  },
  computed: {
    // year() {
    //   return new Date().getFullYear()
    // }
  },
  mounted() {
    this.getLevel();
    this.getShenbao();
    this.$nextTick(() => {
      this.getLinian();
      this.getZijin();
    });
  },
  methods: {
    async tabschange(e) {
      this.lineData = null;
      this.activeIndex = e;
      await this.getZijin(e);
    },
    async confirm(e) {
      this.lineData = null;

      await this.getZijin();
    },
    startSelect() {
      this.$refs.dialogs.open();
    },
    async getShenbao() {
      let params = {
        uuid: this.$route.params.uuid,
      };
      let res = await getVatDeclaration(params);
      this.yearData = res.data;
    },
    async getZijin(e) {
      let str;
      if (this.$refs.dialogs.selected.length > 0) {
        str = this.$refs.dialogs.selected.join(",");
      }
      let params = {
        uuid: this.$route.params.uuid,
        taxNames: this.$refs.dialogs.selected ? str : "[]",
        span: this.$refs.LineBarTabs?.activeIndex ? this.$refs.LineBarTabs.activeIndex : 3,
      };
      if (e) {
        params.span = e;
      }
      let res = await getTaxOverFlowSituation(params);
      this.lineData = res.data;
    },
    async getLinian() {
      let params = {
        uuid: this.$route.params.uuid,
      };
      let res = await getTaxYearSituation(params);
      this.typeData = res.data;
    },
    async getLevel() {
      let params = {
        uuid: this.$route.params.uuid,
      };
      let res = await getTaxCreditRating(params);
      if (res.data.alert) {
        this.alert = true;
      }
      this.solvencyAnalysis = res.data.chart.invoiceImageMap;
    },
  },
};
</script>

<style lang="stylus" scoped>
.h5-wrapper {
  //padding 20px 20px 35px
  position relative
  >.level{
    position: absolute;
    top:20px;
    right: 20px;
    width: 132px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid #EA6C5B;
    font-size: 12px;
    background: rgba(234,108,91,0.08);
    text-align: center;
    line-height: 24px
    color: #EA6C5B;
  }
  .card-wrapper{
    display flex
    justify-content space-between
    .info-card{
      width: 43vw;
      height: 18vw;
      background: #E5F0FF;
      border-radius: 4px;
      padding 12px;
      box-sizing border-box
      .text0{
        margin-bottom 8px
        font-size: 14px;
        font-weight: 400;
        color: #4E5969;
        line-height: 20px;
      }
      .text1{
        font-size: 12px;
        font-weight: 400;
        color: #4E5969;
        line-height: 18px;
      }
    }
  }
}

.content-section {
  position :relative;
  padding: 0.3rem
  background: #FFFFFF;
  padding-bottom: 0.3rem;
  margin-bottom: 0.3rem;
  border-bottom: 1px dashed #7585A2;
  >.selectButton{
    position: absolute;
    width: 21vw;
    font-size: 12px;
    right: 0.3rem;
    top:0.3rem;
    height: 24px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #1677FF;
    color: #1677FF;
    line-height: 18px;
  }
  .title {
    display flex
    align-items center
    font-size: 20px;
    font-weight: 400;
    color: #1D2129;
    line-height: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
  }

  .title::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 14px;
    background: #1D2129;
    border-radius: 2px;
    margin-right: 5px;
  }
}


>>>.van-dialog{
  top: calc(100% - 199px);
  width: 100%;
}
</style>
