<template>
  <div class="orderInformation">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="150px"
      style="margin-bottom: 3rem"
      id="demo-ruleForm"
    >
      <div class="title"><div>基础信息设置</div></div>
      <el-form-item prop="name" class="demo-ruleForm">
        <div class="enterpriseName">海报名称<span>*</span></div>
        <el-input
          v-model="ruleForm.name"
          placeholder="请输入海报名称，最多10个字"
          maxlength="10"
        ></el-input>
      </el-form-item>
      <el-form-item prop="name">
        <div class="enterpriseName">联系人<span>*</span></div>
        <el-input v-model="ruleForm.name" placeholder="请输入推广人姓名"></el-input>
      </el-form-item>
      <el-form-item prop="name">
        <div class="enterpriseName">联系方式<span>*</span></div>
        <el-input v-model="ruleForm.name" placeholder="请输入推广人联系方式"></el-input>
      </el-form-item>
      <el-form-item prop="value1">
        <div class="enterpriseName">海报有效期<span>*</span></div>
        <el-date-picker v-model="ruleForm.value1" type="date" placeholder="请选择海报有效期">
        </el-date-picker>
      </el-form-item>
      <div class="title"><div>产品信息设置</div></div>
      <el-form-item prop="name" class="demo-ruleForm">
        <div class="enterpriseName">产品名称<span>*</span></div>
        <el-input v-model="ruleForm.name" placeholder="请选择要推广的产品"></el-input>
      </el-form-item>
      <el-form-item prop="name" class="demo-ruleForm">
        <div class="enterpriseName">报告单价<span>*</span></div>
        <el-select v-model="ruleForm.region" placeholder="请选择产品售卖单价">
          <el-option label="区域一" value="shanghai"></el-option>
          <el-option label="区域二" value="beijing"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="name" class="demo-ruleForm">
        <div class="enterpriseName">用户数量<span>*</span></div>
        <el-input v-model="ruleForm.name" placeholder="请输入可购买产品的用户数量"></el-input>
      </el-form-item>
      <el-form-item prop="name" class="demo-ruleForm">
        <div class="enterpriseName">检测次数<span>*</span></div>
        <el-input
          v-model="ruleForm.name"
          placeholder="请输入每位用户购买后可获得的检测次数"
        ></el-input>
      </el-form-item>
      <el-form-item prop="name" class="demo-ruleForm">
        <div class="enterpriseName">检测时效<span>*</span></div>
        <el-input v-model="ruleForm.name" placeholder="请输入用户获得的检测次数有效期"></el-input>
      </el-form-item>
      <div><div style="height: 0.18rem"></div></div>
    </el-form>
    <div class="btn">
      <div class="posterTip">
        <i class="el-icon-warning"></i>上述信息填写完整后可预览再生成海报！
      </div>
      <div class="btnList">
        <div class="order" @click="previewPoster('ruleForm')">预览海报</div>
        <div class="cost" @click="submitForm('ruleForm')">生成海报</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        region: "",
        value1: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        region: [{ required: true, message: "请选择活动区域", trigger: "change" }],
        value1: [{ type: "date", required: true, message: "请选择海报有效期", trigger: "change" }],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$router.push({
            path: "/previewPoster",
            query: {
              btnStatus: true,
            },
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    previewPoster(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$router.push({
            path: "/previewPoster",
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.orderInformation{
  width: 100vw;
  background: #fff;
  //padding: 0.4rem 0.3rem;
  box-sizing: border-box;
  .enterpriseName{
    span{
        color: #fa4f4f;
        margin-left: 0.1rem;
        font-size: 0.28rem;
    }
  }
  .title{
    font-size: 0.32rem;
    font-weight: bold;
    color: #0E1429;
    padding:0 0.3rem
    line-height: 1.07rem;
    background: #F5F7FA;
    div{
      height:1.05rem;
    }
  }
}
>>>.el-form-item {
  //display: flex;
  //flex-direction: column;
}
.el-icon-warning {
  margin-right: 0.16rem;
  color: rgba(0,0,0,0.4);
  font-size: 0.28rem;
}
.btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 0;
  background: #fff;
  width: 100vw;
  .posterTip{
    color:#FDB320;
    font-size:0.28rem;
    margin: 0.3rem 0.4rem;
    .el-icon-warning{
      color:#FDB320;
      font-size:0.28rem;
    }
  }

  .btnList{
    display: flex;
    margin-bottom: 0.92rem;
    .order, .cost {
      width: 3.3rem;
      height: 0.88rem;
      line-height: 0.88rem;
      background: rgba(63, 116, 246, 0.2);
      border-radius: 0.1rem;
      color: #3F74F6;
      font-size: 0.32rem;
      text-align: center;
    }

    .cost {
      margin-left: 0.3rem;
      background: #3F74F6;
      opacity: 1;
      color: #fff;
    }
  }

}
#demo-ruleForm{
  >>>.el-form-item__content{
    margin-left: 0rem !important;
  }
  >>>.el-form-item {
    margin: 0 0.3rem;
    margin-bottom: 0.25rem;

  }
  >>>.el-select{
    width: 6.9rem;
  }
  >>>.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 6.9rem;
  }
}
</style>
