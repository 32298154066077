<template>
    <div>
      <!-- 弹出层 -->
      <van-popup
        get-container="body"
        v-model="isPicker"
        position="bottom"
        @close="confirmOn"
      >
        <!-- 时间选择 -->
        <div v-if="isPicker">
          <van-picker
            ref="picker"
            show-toolbar
            :title="title"
            :columns="columns"
            @change="onChange"
            @cancel="cancelOn"
            @confirm="onConfirm"
          />
        </div>
      </van-popup>
    </div>
  </template>
  <script>
  export default {
    name: "popup",
    props: {
      showPicker: {
        type: Boolean,
        default: false,
      },
      values: {
        type: String,
        default: "",
      },
      title: {
        type: String,
        default: "请选择结束时间",
      },
      "value-format": {
        type: String,
        default: "yyyy-MM-dd HH:mm:ss",
      },
    },
    data() {
      return {
        isPicker: false, //是否显示弹出层
        columns: [],
        //所有时间
        Mdays: "", //弹窗关闭时月份所在值
        Dindex: null,
      };
    },
    watch: {
      isPicker(val) {
        !val && this.$emit("changeValue");
        this.columns = [];
        this.getcolumns();
      },
      showPicker(val) {
        this.isPicker = val;
        
      },
      values(val) {
        if (val == "") {
          this.Mdays = "";
          this.Dindex = null;
        }
      },
    },
    methods: {
      getCountDays(year, month) {
        //获取某年某月多少天
        var day = new Date(year, month, 0);
        return day.getDate();
      },
      getcolumns() {
        var strtime = this.values;
        if (this.values != "") {
          var vmoduledate = new Date(strtime.replace(/-/g, "/"));
        } else {
          var vmoduledate = new Date(); //没有传入时间则默认当前时刻
        }
        var Y = vmoduledate.getFullYear();
        var M = vmoduledate.getMonth();
        var D = vmoduledate.getDate();
        var h = vmoduledate.getHours();
        var m = vmoduledate.getMinutes();
        var s = vmoduledate.getSeconds();
        var currentDate = new Date();
        // 获取当前的月份（注意月份从0开始计数）
        var months = currentDate.getMonth() + 1; // 加上1是因为月份从0开始计数
        
        // 根据月份判断当前所属的季度
        if (months >= 1 && months <= 3) {
            var q = "1季度";
        } else if (months >= 4 && months <= 6) {
            var q = "2季度";
        } else if (months >= 7 && months <= 9) {
            var q = "3季度";
        } else if (months >= 10 && months <= 12) {
            var q = "4季度";
        }

        var quarter = {};
        quarter.values = [];
        var Currentday = 4;
        for (var i = 1; i <= Currentday; i++) {
          quarter.values.push(i+'季度');
        }
        quarter.defaultIndex = quarter.values.indexOf(q);





        var year = {}; //获取前后十年数组
        year.values = [];
        var Currentday = new Date().getFullYear();
        for (var i = Currentday - 100; i < Currentday + 100; i++) {
          year.values.push(i);
        }
        year.defaultIndex = year.values.indexOf(Y);
   
        var month = {};
        //获取12月数组
        month.defaultIndex = M;
        month.values = Object.keys(Array.apply(null, { length: 13 })).map(
          function (item) {
            if (+item + 1 <= 10) {
              return "0" + item;
            } else if (+item + 1 == 11) {
              return +item;
            } else {
              return (+item + 0).toString();
            }
          }
        );
        month.values.splice(0, 1);
        var days = this.getCountDays(Y, this.Mdays == "" ? M + 1 : this.Mdays);
        var day = {}; //创建当月天数数组
        day.defaultIndex = this.Dindex == null ? D - 1 : this.Dindex;
        day.values = Object.keys(Array.apply(null, { length: days + 1 })).map(
          function (item) {
            if (+item + 1 <= 10) {
              return "0" + item;
            } else if (+item + 1 == 11) {
              return +item;
            } else {
              return (+item + 0).toString();
            }
          }
        );
        day.values.splice(0, 1);
        var hour = {}; //创建小时数组
        hour.defaultIndex = h;
        hour.values = Object.keys(Array.apply(null, { length: 24 })).map(
          function (item) {
            if (+item + 1 <= 10) {
              return "0" + item;
            } else if (+item + 1 == 11) {
              return +item;
            } else {
              return (+item + 0).toString();
            }
          }
        );
        var mi = {}; //创建分钟数组
        mi.defaultIndex = m;
        mi.values = Object.keys(Array.apply(null, { length: 60 })).map(function (
          item
        ) {
          if (+item + 1 <= 10) {
            return "0" + item;
          } else if (+item + 1 == 11) {
            return +item;
          } else {
            return (+item + 0).toString();
          }
        });
        var ss = {}; //创建秒数数组
        ss.defaultIndex = s;
        ss.values = Object.keys(Array.apply(null, { length: 60 })).map(function (
          item
        ) {
          if (+item + 1 <= 10) {
            return "0" + item;
          } else if (+item + 1 == 11) {
            return +item;
          } else {
            return (+item + 0).toString();
          }
        });
        //设置默认选项当前年
        if (this.valueFormat.includes("yyyy")) {
          this.columns.push(year);
        }
        if (this.valueFormat.includes("MM")) {
          this.columns.push(month); //获取当月的天数
        }
        if (this.valueFormat.includes("dd")) {
          this.columns.push(day);
        }
        if (this.valueFormat.includes("HH")) {
          this.columns.push(hour);
        }
        if (this.valueFormat.includes("mm")) {
          this.columns.push(mi);
        }
        if (this.valueFormat.includes("ss")) {
          this.columns.push(ss);
        }
        if (this.valueFormat.includes("quarter")) {
          this.columns.push(quarter);
        }
      },
      onChange(values, a) {
        //a为所有列备选项值的数组
        var days = this.getCountDays(a[0], a[1]);
        var newdays = {};
        newdays.values = Object.keys(Array.apply(null, { length: days + 1 })).map(
          function (item) {
            if (+item + 1 <= 10) {
              return "0" + item;
            } else if (+item + 1 == 11) {
              return +item;
            } else {
              return (+item + 0).toString();
            }
          }
        );
        newdays.values.splice(0, 1);
        this.$refs.picker.setColumnValues(2, newdays.values); //设置第三列的值
        this.$refs.picker.setColumnIndex(2, a[2] - 1); //设置第三列索引
      }, // 关闭弹框
      confirmOn() {
        var currentM = this.$refs.picker.getColumnValue(1);
        if(!currentM) return
        // this.Mdays = currentM.substr(0, 2);
        // this.Dindex = this.$refs.picker.getColumnIndex(2);
      },
      //时间选择器关闭
      cancelOn() {
        this.$emit("changeValue");
      },
      // 时间选择器确定
      onConfirm(val, index) {
        let endval = "";
   
        for (let i = 0; i < this.columns.length; i++) {
          endval += val[i];
          if (i === 2) {
            endval += " ";
          } else if (i >= 3 && i <= 5) {
            endval += ":";
          } else if (i < this.columns.length - 1) {
            endval += "-";
          }
        }
        // 判断最后一个字符是否是分隔符
        if (endval.endsWith("-") || endval.endsWith(":")) {
          endval = endval.slice(0, -1); // 删除最后一个字符
        }
        console.log(endval)
        this.$emit("changeValue", endval);
        this.$emit("confirm", endval);
      },
    },
  };
  </script>
  <style lang="stylus" scoped>
  .yearPicker {
  .picker-items {
    .picker-slot:nth-child(2), .picker-slot:nth-child(3) {
      display: none;
    }
  }
}
</style>