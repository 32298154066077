<template>
  <div class="reportBeing">
    <img class="statusImg" src="@/assets/img_success.png" alt="" />
    <div class="title">报告生成中…</div>
    <div class="waring">
      <div>风险报告生成大约需要 2 个小时</div>
      <div>报告生成后将短信通知您，请耐心等待…</div>
    </div>
    <div class="btn">
      <div class="cost" @click="inspectionRecords">检测记录</div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "报告生成中",
  },
  data() {
    return {};
  },
  async mounted() {
    await this.clearBrowserNavigatorHistory();
  },
  methods: {
    clearBrowserNavigatorHistory() {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", function () {
        history.pushState(null, null, document.URL);
      });
    },
    goHome() {
      this.$router.push({
        path: "/",
      });
    },
    inspectionRecords() {
      this.$router.push({
        path: "/my",
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.reportBeing{
background: #fff;
height:100vh;
display: flex;
flex-direction: column;
align-items: center;
.title{
font-size: 0.36rem;
font-family: PingFang SC-Medium, PingFang SC;
font-weight: 600;
color: #0E1429;
margin-top: 0.1rem
}
.waring{
    width: 5.74rem;
    height: 0.80rem;
    font-size: 0.28rem;
    font-family: PingFangSC-Regular-, PingFangSC-Regular;
    font-weight: normal;
    color: #818590;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.3rem;
    line-height: 0.4rem;
}
.statusImg{
    height:4rem;
    width:4rem;
    margin-top: 1.6rem;
}
.btn {
    display: flex;
    align-items: center;
    margin: 0.8rem 0;
.order, .cost {
    width: 4rem;
    height: 0.8rem;
    line-height: 0.8rem;
    background: rgba(63, 116, 246, 0.2);
    border-radius: 0.1rem;
    color: #3F74F6;
    font-size: 0.32rem;
    text-align: center;
  }

  .cost {
    margin-left: 0.3rem;
    background: #3F74F6;
    opacity: 1;
    color: #fff;
  }
}
}
</style>
