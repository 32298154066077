<template>
  <div class="orderInformation">
    <div class="card-item">
      <div class="card-tab">
        <div class="tab" @click="toTab(1)" :class="{ activeText: active == 1 }">
          <div>海报信息</div>
          <div class="active" v-if="active == 1"></div>
        </div>
        <div class="tab" @click="toTab(2)" :class="{ activeText: active == 2 }">
          <div>推广详情</div>
          <div class="active" v-if="active == 2"></div>
        </div>
      </div>
    </div>
    <el-form
      v-if="active == 1"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="150px"
      style="margin-bottom: 0.8rem"
      id="demo-ruleForm"
    >
      <div class="title"><div>基础信息</div></div>
      <el-form-item prop="name" class="demo-ruleForm">
        <div class="enterpriseName">海报名称<span>*</span></div>
        <el-input
          v-model="ruleForm.name"
          placeholder="请输入海报名称，最多10个字"
          maxlength="10"
        ></el-input>
      </el-form-item>
      <el-form-item prop="name">
        <div class="enterpriseName">联系人<span>*</span></div>
        <el-input v-model="ruleForm.name" placeholder="请输入推广人姓名"></el-input>
      </el-form-item>
      <el-form-item prop="name">
        <div class="enterpriseName">联系方式<span>*</span></div>
        <el-input v-model="ruleForm.name" placeholder="请输入推广人联系方式"></el-input>
      </el-form-item>
      <el-form-item prop="value1">
        <div class="enterpriseName">海报有效期<span>*</span></div>
        <el-date-picker v-model="ruleForm.value1" type="date" placeholder="请选择海报有效期">
        </el-date-picker>
      </el-form-item>
      <div><div style="height: 0.18rem"></div></div>
      <div class="title"><div>产品信息</div></div>
      <el-form-item prop="name" class="demo-ruleForm">
        <div class="enterpriseName">产品名称<span>*</span></div>
        <el-input v-model="ruleForm.name" placeholder="请选择要推广的产品"></el-input>
      </el-form-item>
      <el-form-item prop="name" class="demo-ruleForm">
        <div class="enterpriseName">报告单价<span>*</span></div>
        <el-select v-model="ruleForm.region" placeholder="请选择产品售卖单价">
          <el-option label="区域一" value="shanghai"></el-option>
          <el-option label="区域二" value="beijing"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="name" class="demo-ruleForm">
        <div class="enterpriseName">用户数量<span>*</span></div>
        <el-input v-model="ruleForm.name" placeholder="请输入可购买产品的用户数量"></el-input>
      </el-form-item>
      <el-form-item prop="name" class="demo-ruleForm">
        <div class="enterpriseName">检测次数<span>*</span></div>
        <el-input
          v-model="ruleForm.name"
          placeholder="请输入每位用户购买后可获得的检测次数"
        ></el-input>
      </el-form-item>
      <el-form-item prop="name" class="demo-ruleForm">
        <div class="enterpriseName">检测时效<span>*</span></div>
        <el-input v-model="ruleForm.name" placeholder="请输入用户获得的检测次数有效期"></el-input>
      </el-form-item>
      <div><div style="height: 0.18rem"></div></div>
      <div class="title"><div style="height: 0.3rem"></div></div>
      <div class="btn-item" @click="submitForm('ruleForm')">查看海报</div>
    </el-form>

    <div v-if="active == 2" style="background: #f5f7fa">
      <div style="height: 0.3rem"></div>
      <div class="detailNum">
        <ul>
          <span>99999</span>
          <li>海报扫码次数</li>
        </ul>
        <ul>
          <span>99</span>
          <li>推广用户数</li>
        </ul>
        <ul>
          <span>996</span>
          <li>销售总金额</li>
        </ul>
      </div>
      <div class="title">推广用户信息</div>
      <div class="detailItem">
        <div class="itemHeader">
          <div><span>下单时间</span></div>
          <div><span>微信昵称</span></div>
          <div><span>手机号</span></div>
        </div>
        <div class="detailBody">
          <div class="tiem">2023.08.28 16:01:30</div>
          <div class="name">路子</div>
          <div class="tel">18866668888</div>
        </div>
        <div class="detailBody">
          <div class="tiem">2023.08.28 16:01:30</div>
          <div class="name">路子</div>
          <div class="tel">18866668888</div>
        </div>
        <!-- <div class="detailBodyNull">
          <img class="backPic" src="@/assets/暂无数据.png" alt="" />
          暂无推广用户信息～
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        region: "",
        value1: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        region: [{ required: true, message: "请选择活动区域", trigger: "change" }],
        value1: [{ type: "date", required: true, message: "请选择海报有效期", trigger: "change" }],
      },
      active: 2,
    };
  },
  methods: {
    toTab(val) {
      this.active = val;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$router.push({
            path: "/previewPoster",
            query: {
              btnStatus: true,
            },
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    previewPoster(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$router.push({
            path: "/previewPoster",
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.orderInformation{
  width: 100vw;
  background: #fff;
  //padding: 0.4rem 0.3rem;
  box-sizing: border-box;
  .enterpriseName{
    span{
        color: #fa4f4f;
        margin-left: 0.1rem;
        font-size: 0.28rem;
    }
  }
  .title{
    font-size: 0.32rem;
    font-weight: bold;
    color: #0E1429;
    padding:0 0.3rem
    line-height: 1.07rem;
    background: #F5F7FA;
    div{
      height:1.05rem;
    }
  }
}
>>>.el-form-item {
  //display: flex;
  //flex-direction: column;
}
.el-icon-warning {
  margin-right: 0.16rem;
  color: rgba(0,0,0,0.4);
  font-size: 0.28rem;
}

.card-item {
    background: #fff;
    border-radius: 0.24rem 0.24rem 0 0;
    font-size: 0.32rem;
    box-sizing: border-box;
    //margin-left: 0.2rem;

    .card-tab {
      display: flex;

      .tab {
        position: relative;
        width: 50%;
        text-align: center;
        margin-top: 0.22rem;
        padding-bottom: 0.22rem;
        font-weight: bold;

        .active {
          position: absolute;
          left: 50%;
          bottom: 0;
          margin-left: -0.28rem;
          width: 0.56rem;
          height: 0.05rem;
          background: #3F74F6;
          border-radius: 0.04rem;
          opacity: 1;
        }
      }

      .activeText {
        color: rgba(63, 116, 246, 1);
      }
    }
  }
  .btn-item {
    margin: 0.2rem 0.3rem;
    height: 0.88rem;
    line-height: 0.88rem;
    background: #3F74F6;
    border-radius: 0.1rem;
    opacity: 1;
    color: #fff;
    text-align: center;
    font-size: 0.34rem;
  }
  .title{
    font-size: 0.32rem;
    font-weight: bold;
    color: #0E1429;
    padding:0 0.3rem
    line-height: 1.07rem;
    background: #F5F7FA;
  }
.detailNum{
    background: #FFFFFF;
    border-radius: 14px 14px 14px 14px;
    margin: 0 0.3rem 0.2rem;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    padding: 0.3rem 0.4rem;
    ul{
        display: flex;
        flex-direction: column;
        align-items: center;
        span{
            font-size: 0.4rem;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #3F74F6;
            line-height: 36px;
        }
        li{
            list-style:none;
            font-size: 0.28rem;
            color: #0E1429;
        }
    }
}
.detailItem{
    margin: 0 0.3rem 0.3rem;
    padding: 0.24rem 0.3rem;
    background: #fff;
    border-radius: 14px 14px 14px 14px;
    .itemHeader{
        font-size: 0.28rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #818590;
        display: flex;
        justify-content: space-between;
        padding-bottom: 0.24rem;
        border-bottom:1px solid rgba(14,20,41,0.1);
        div{
            flex-basis: 33%
        }
    }
    .detailBody{
        padding: 0.18rem 0;
        font-size: 0.28rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #0E1429;
        display: flex;
        justify-content: space-around
        border-bottom:1px solid rgba(14,20,41,0.1);
        div{
            flex-basis: 33%
        }
    }
    .detailBody:last-child{
        border:none;
    }
    .detailBodyNull{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2.29rem;
        margin-top: 1.4rem;
        font-size: 0.3rem;
            .backPic{
                width:3.6rem;
                height:3.6rem;
              }

    }
}
#demo-ruleForm{
  >>>.el-form-item__content{
    margin-left: 0rem !important;
  }
  >>>.el-form-item {
    margin: 0 0.3rem;
    margin-bottom: 0.25rem;

  }
  >>>.el-select{
    width: 6.9rem;
  }
  >>>.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 6.9rem;
  }
}
</style>
