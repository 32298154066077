<template>
  <div class="card-container">
    <div class="img-wd">
      <img src="@/assets/img_wd_bg切图用.png" alt="" />
    </div>
    <div class="card-info">
      <div class="card-icon">
        <img :src="icon" alt="" v-if="icon" />
      </div>
      <div class="card-person">
        <div class="card-name">{{ nickname }}</div>
        <div class="card-phone">
          <div class="card-phone-icon" v-if="phoneNumber">
            <i class="iconfont icon-icon_shouji"></i>
          </div>
          <div class="card-person-phone" v-if="phoneNumber">{{ phoneNumber }}</div>
          <div v-else class="card-add-phone" @click="addPhoneMeth">
            <img src="@/assets/icon_edit.png" alt="" />
            <div>完善手机号</div>
          </div>
        </div>
      </div>
    </div>
    <div class="showcase">
      <div>
        <img src="../../assets/showcase.png" alt="">
        <div class="shu"></div>
        <!-- <van-notice-bar  :scrollable="false" :background="'#ffffff00'"> -->
        <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false">
          <van-swipe-item v-for="(v, k) of goodsList" :key="'goodList' + k">{{ v.productName }}</van-swipe-item>

        </van-swipe>
        <!-- </van-notice-bar> -->
      </div>
      <div class="toseesee" @click="toseesee">
        <span>去看看</span>
        <i class="iconfont icon-icon_arrow_right"></i>
      </div>


    </div>
    <div class="card-item">
      <div class="card-tab">
        <!-- <div
          class="tab"
          @click="toTab(item.menuId)"
          v-for="item in menuAuthority"
          :key="item.menuId"
          :class="{ activeText: active == item.menuId }"
        >
          <div>{{ item.menuName }}</div>
          <div class="active" v-if="active == item.menuId"></div>
        </div> -->
        <div class="tab" @click="toTab(3)" :class="{ activeText: active == 3 }">
          <div>风险检测</div>
          <div class="active" v-if="active == 3"></div>
        </div>
        <div class="tab" @click="toTab(1)" :class="{ activeText: active == 1 }">
          <div>我的订单</div>
          <div class="active" v-if="active == 1"></div>
        </div>

        <!-- <div class="tab" @click="toTab(4)" :class="{ activeText: active == 4 }">
          <div>推广海报</div>
          <div class="active" v-if="active == 4"></div>
        </div> -->
        <!-- <div class="tab" @click="toTab(2)" :class="{ activeText: active == 2 }">
          <div>联系我们</div>
          <div class="active" v-if="active == 2"></div>
        </div> -->
      </div>
    </div>
    <mobile class="home-mobile" v-if="isMobile" @closeMobile="closeMobile" @bindPhoneWx="bindPhoneWx"
      style="display: none"></mobile>
  </div>
</template>

<script>
import mobile from "./mobile.vue";
import { getProductListByChannelUniqueId } from "@/api/fri";

export default {
  components: {
    mobile,
  },
  mounted() {
    this.getProductLis();
  },
  data() {
    return {
      icon: localStorage.getItem("headImgUrl") || "",
      nickname: localStorage.getItem("nickname") || "暂无昵称",
      phoneNumber: localStorage.getItem("phoneNumber"),
      active: 3,
      isMobile: false,
      menuAuthority: ["风险检测", "我的订单"],
      channelUniqueId: '',
      posterUniqueId: '',
      goodsList: []
    };
  },
  methods: {
    toseesee() {
      this.$router.push({
        path: "/",
        query: {
          channelUniqueId: localStorage.getItem("channelUniqueId"),
        },
      });
    },
    async getProductLis() {
      let params = {
        channelUniqueId: this.channelUniqueId ? this.channelUniqueId : localStorage.getItem("channelUniqueId"),
        posterUniqueId: this.posterUniqueId ? this.posterUniqueId : localStorage.getItem("posterUniqueId") != null && localStorage.getItem("posterUniqueId") != 'null' ? localStorage.getItem("posterUniqueId") : '',

      };
      const res = await getProductListByChannelUniqueId(params)
      if (res.code == 200) {
        this.goodsList = res.data;
      }
    },
    toTab(val) {
      this.active = val;
      this.$emit("changeActive", this.active);
    },
    addPhoneMeth() {
      this.isMobile = true;
    },
    bindPhoneWx() {
      this.isMobile = false;
      this.phoneNumber = localStorage.getItem("phoneNumber");
    },
    closeMobile() {
      this.isMobile = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.notice-swipe {
  height: 40px;
  line-height: 40px;
}
.showcase{
  box-sizing: border-box
  width: 6.75rem;
  height: 0.78rem;
  margin: 0 auto;
  background: rgba(0,0,0,0.3);
  border-radius: 12px 12px 0px 0px;
  position: absolute;
  left: .4rem;
  top:2.4rem;
  display: flex;
  justify-content: space-between;
  padding: 0.18rem 0.24rem;
  color: #fff;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 13px;
  >div{
  display: flex;
  align-items: center
  }
  img{
    width: 1.44rem;
    height: 0.43rem;
    margin-right :0.24rem;
  }
  .shu{
    width: 0px;
    border-left: 1px solid #fff;
    height: 0.28rem;
    margin-right :0.24rem;

  }
  >.toseesee{
    background: #FDB320;
    border-radius:4px;
    width: 1.32rem;
    height: 0.49rem;
    display: flex;
    justify-content: center
    align-items: center;
    i{
      font-size: 10px;
    }
  }
}
.card-container {
  background: url("../../assets/img_wd_bg.png") no-repeat center 0px;
  // padding-top: 1.27rem;
  height: 3.6rem;

  .img-wd {
    position: absolute;
    top: 0;
    width: 100%;

    img {
      width: 100%;
    }
  }
}

.card-item {
  position: relative;
  //top: 2.62rem;
  top: 3.2rem;

  //width: 7.1rem;
  background: #F5F7FA;
  border-radius: 0.24rem 0.24rem 0 0;
  font-size: 0.32rem;
  box-sizing: border-box;
  //margin-left: 0.2rem;



  .card-tab {
    display: flex;

    .tab {
      position: relative;
      width: 50%;
      text-align: center;
      margin-top: 0.3rem;
      padding-bottom: 0.26rem;

      font-weight: bold;
      .txt {
        height: 0.45rem;
        font-size: 0.32rem;
        font-weight: 600;
        color: #3F74F6;
      }

      .active {
        position: absolute;
        left: 50%;
        bottom: 0;
        margin-left: -0.28rem;
        width: 0.56rem;
        height: 0.05rem;
        background: #165DFF;
        border-radius: 0.04rem;
        opacity: 1;
      }
    }

    .activeText {
      color: #165DFF;
    }
  }
}

.home-mobile {
  position: fixed;
  bottom: 0;
  z-index: 1000;
}

.home-mobile {
  z-index: 1001;
}
.card-info {
  display: flex;
  padding: 0.7rem 0 0 0.3rem;
  position: absolute;

  .card-icon {
    width: 1.44rem;
    height: 1.44rem;
    border-radius: 50%;
    background: rgba(255,255,255,0.2);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 1.28rem;
      height: 1.28rem;
      border-radius: 50%;
    }
  }

  .card-person {
    margin-top: 0.09rem;
    margin-left: 0.36rem;

    .card-name {
      line-height: 0.5rem;
      line-height: 0.5rem;
      font-weight: 500;
      color: #FFFFFF;
      font-size: 0.36rem;
    }

    .card-phone {
      display: flex;
      align-items: center;
      font-size: 0.32rem;
      margin-top: 0.23rem;

      .icon-icon_shouji {
        margin-right: 0.25rem;
        color:#fff
      }

      .card-person-phone {
        font-family: PingFang SC-Regular, PingFang SC;
        font-size: 0.30rem;
        color:#fff
      }

      .card-add-phone {
        display: flex;
        width: 2.08rem;
        height: 0.54rem;
        //background: #3F74F6;
        border-radius: 0.1rem;
        opacity: 1;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 0.24rem;
        border: 1px solid #FFFFFF;
        img {
          width: 0.28rem;
          height: 0.28rem;
          margin-right: 0.16rem;
        }
      }
    }
  }
}
</style>
