<template>
  <div class="chart-wrapper">
    <div class="unit">单位：{{ unit }}</div>
    <div ref="chartColumn" class="chartColumn"></div>
  </div>
</template>

<script>
// 进销项
import { mapState } from "vuex";
// import echarts from "echarts";
import * as echarts from "echarts";

export default {
  props: {
    data: Object,
    width: String,
    height: String,
  },
  async mounted() {
    this.showMap();
  },
  watch: {
    data: {
      handler(val) {
        //设置为最近一年(倒数第一)
        // this.activeIndex = Object.entries(this.data.yearChart).length - 1
      },
      immediate: true,
    },
  },
  methods: {
    FontChart(res) {
      //获取到屏幕的宽度
      var clientWidth =
        window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (!clientWidth) return; //报错拦截：
      let fontSize = 10 * (clientWidth / 1920);
      return res * fontSize;
    },
    // echart
    showMap() {
      if (this.width) {
        this.$refs.chartColumn.style.width = this.width;
      }

      if (this.height) {
        this.$refs.chartColumn.style.height = this.height;
      }
      console.log(this.data);
      this.chartColumn = echarts.init(this.$refs.chartColumn);
      const _this = this;
      const option = {
        tooltip: {
          trigger: "axis",
          // formatter: `{b}<br>{a}:{c}${this.unit}`,
          formatter: function (params) {
            let str = `<div>${params[0].name}</div>`;
            params.forEach((i) => {
              str += `<div>${i.marker}${i.seriesName}：${i.value}&nbsp;&nbsp;${_this.unit}</div>`;
            });
            return str;
          },
          confine: true,
        },
        legend: {
          x: "center",
          // bottom: "0%",
          data: this.headers,
          textStyle: {
            //fontSize: this.FontChart(4), //图例文字字体大小
            // color: "#8A90A3", //图例文字颜色
            fontSize: "14",
          },
        },
        grid: {
          left: "2%",
          right: "3%",
          top: "8%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.data.xLabels,
          splitLine: {
            show: true,
            lineStyle: {
              color: "#EEEEEE",
              width: 1,
              type: "solid",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#666",
            },
          },
          axisLabel: {
            rotate: 35,
            interval: this.data.xLabels.length > 12 ? 3 : this.data.xLabels.length > 24 ? 6 : 1,
            textStyle: {
              fontSize: 11,
              fontFamily: "PingFangSC-Regular,PingFang SC",
              color: "rgba(0,0,0,0.6)",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#666",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#EEEEEE",
              width: 1,
              type: "dashed",
            },
          },
          axisLabel: { show: true, formatter: `{value}` },
        },
        series: this.series,
      };
      this.chartColumn.setOption(option);
    },
  },
  computed: {
    ...mapState(["Result"]),
    unit() {
      return this.data.unit || "万元";
    },
    headers() {
      return this.data.series.map((value) => value.name);
    },
    series() {
      const ret = [];

      const colors = ["#2F54EB", "#FAAD14", "#52C41A"];

      for (let headersIndex = 0; headersIndex < this.headers.length; headersIndex++) {
        const headerName = this.headers[headersIndex];
        const color = colors[colors.length - headersIndex - 1];

        const { data } = this.data.series[headersIndex];

        const theSeries = {
          name: headerName,
          type: "line",
          data,
          color,
          symbol: "circle",
          symbolSize: 7,
        };
        ret.push(theSeries);
      }
      return ret;
    },
  },
};
</script>

<style lang="stylus" scoped>
.chart-wrapper {
  position relative

  .unit {
    margin-top 12px;
    text-align right
    font-size: 12px;
    font-weight: 400;
    color: #4E5969;
    line-height: 18px;
  }
}
.chartColumn {
  width: 6.5rem;
  height: 5.9rem;
  z-index: 1001;

  >>> div:first-child {
    width: 100% !important;
  }
}

@media screen and (min-width:500px) and (max-width: 2000px){
  .chartColumn {
    width: 3.5rem;
    height: 3.9rem;
    z-index: 1001;

    >>> div:first-child {
      width: 100% !important;
    }
  }
}
</style>
