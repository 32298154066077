import axios, {
  get,
  post
} from "@/axios";

const base = process.env.VUE_APP_API_ROOT;
const microServiceVue2 = process.env.VUE_APP_MICRO_SERVICE_VUE2;
console.log(microServiceVue2);
/**
 * 获取公司经营信息 （用户新）
 * @param uuid
 * @returns {*}
 */
export const getBusinessInfo = uuid => {
  return get(`${base}/shuianReport/boss/report/index/getBusinessInfo`,{uuid})
    .then(res => res.data);
};
/**
 * 获取公司风险信息 （用户新）
 * @param uuid
 * @returns {*}
 */
export const getRiskInfo = (uuid,desensitization) => {
  return get(`${base}/shuianReport/boss/report/index/getRiskInfo`,{uuid,desensitization})
    .then(res => res.data);
};
/**
 * 获取公司风险列表 （用户新）
 * @param uuid string
 * @param active bool
 * @returns {*}
 */
export const getRiskList = ({uuid,riskLevel}) => {
  const params = {
    uuid
  }
  if(riskLevel!=undefined){
    params.riskLevel = riskLevel
  }
  return get(`${base}/shuianReport/boss/report/index/getRiskList`,params)
    .then(res => res.data);
};
/**
 * 获取公司风险详情 （用户新）
 * @param params string
 * @returns {*}
 */
// export const getRiskDetailList = (params) => {
//   return get(`${base}/plat/boss/report/index/getRiskDetailList`,params)
//     .then(res => res.data);
// };
export const getRiskDetailList = (reportId) => {
  return get(`${base}/shuianReport/report/manage/get/v2/${reportId}`)
    .then(res => res.data);
};

/**
 * 获取一键看票发票类型饼图 
 * 获取发票类型卡片 （用户新）
 * @param params object
 * @returns {*}
 */
export const getInvoiceTypeCard = params => {
  return get(`${base}/shuianReport/boss/report/invoice/getInvoiceTypeCard`,params)
    .then(res => res.data);
};
/**
 * 获取一键看票发票状态柱状图
 * 获取发票状态卡片 （用户新）
 * @param params object
 * @returns {*}
 */
export const getInvoiceStatusCard = params => {
  return get(`${base}/shuianReport/boss/report/invoice/getInvoiceStatusCard`,params)
    .then(res => res.data);
};
/**
 * 获取一键看票特殊发票柱状图
 * 获取特殊发票卡片 （用户新）
 * @param params object
 * @returns {*}
 */
export const getSpecialInvoiceCard = params => {
  return get(`${base}/shuianReport/boss/report/invoice/getSpecialInvoiceCard`,params)
    .then(res => res.data);
};
/**
 * 获取一键看票风险发票柱状图
 * 获取风险发票卡片 （用户新）
 * @param params object
 * @returns {*}
 */
export const getRiskInvoiceCard = params => {
  return get(`${base}/shuianReport/boss/report/invoice/getRiskInvoiceCard`,params)
    .then(res => res.data);
};
/**
 * 获取一键看票特殊发票详情
 * @param params object
 * @returns {*}
 */
export const getSpecialInvoiceDetail = params => {
  return get(`${base}/shuianReport/boss/report/invoice/getSpecialInvoiceDetail`,params)
    .then(res => res.data);
};



//核心财务数据   （用户新）
export const getCoreFinancialData = ({uuid}) => {
  const params = {
    uuid
  }
  return get(`${base}/shuianReport/boss/report/finance/coreFinancialData`,params)
    .then(res => res.data);
};
/**
 * 收入类型分析
 * 收入类型分析   （用户新）
 * @param uuid
 * @returns {*}
 */
export const getRevenueTypeAnalysis = ({uuid}) => {
  const params = {
    uuid
  }
  return get(`${base}/shuianReport/boss/report/finance/revenueTypeAnalysis`,params)
    .then(res => res.data);
};
/**
 * 成本费用分析  （用户新）
 * @param uuid
 * @returns {*}
 */
export const getCostAnalysis = ({uuid}) => {
  const params = {
    uuid
  }
  return get(`${base}/shuianReport/boss/report/finance/costAnalysis`,params)
    .then(res => res.data);
};

/**
 * 偿债能力分析   （用户新）
 * @param uuid
 * @returns {*}
 */
export const getSolvencyAnalysis = ({uuid}) => {
  const params = {
    uuid
  }
  return get(`${base}/shuianReport/boss/report/finance/solvencyAnalysis`,{uuid})
    .then(res => res.data);
};


/**
 * 纳税分析-信用等级 （用户新）
 * @param uuid
 * @returns {*}
 */
export const getTaxCreditRating = (params) => {
  return get(`${base}/shuianReport/boss/report/tax/getTaxCreditRating`,params)
    .then(res => res.data);
};


/**
 * 纳税分析-历年纳税 （用户新）
 * @param uuid
 * @returns {*}
 */
export const getTaxYearSituation = (params) => {
  return get(`${base}/shuianReport/boss/report/tax/getTaxYearSituation`,params)
    .then(res => res.data);
};

/**
 * 纳税分析-纳税资金流出情况 （用户新）
 * @param uuid
 * @returns {*}
 */
export const getTaxOverFlowSituation = (params) => {
  return get(`${base}/shuianReport/boss/report/tax/getTaxOverFlowSituation`,params)
    .then(res => res.data);
};

/**
 * 纳税分析-增资税申报情况 （用户新）
 * @param uuid
 * @returns {*}
 */
export const getVatDeclaration = (params) => {
  return get(`${base}/shuianReport/boss/report/tax/getVatDeclaration`,params)
    .then(res => res.data);
};

//行业对比 （用户新）
export const getIndustryCompareAPI = ({uuid}) => {
  return get(`${base}/shuianReport/boss/report/finance/industryCompare`,{uuid})
    .then(res => res.data);
};

export const getReportUrl = ({reportId,token,desensitizationStatus}) => {
  return microServiceVue2 + "reportCenter/" + reportId + "?token=" + token+'&desensitizationStatus='+desensitizationStatus+'&device=mobile'
};