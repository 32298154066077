<template>
  <div class="pay" @click="toDetail(item.orderNo)">
    <div class="pay-top">
      <div class="pay-icon">
        <!-- <img src="../../assets/老板看报告插图 (1).png" alt="" /> -->
        <img :src="item.showImageUrl" alt="" />
      </div>
      <div style="display: flex; width: 4.7rem; justify-content: space-between">
        <div style="display: flex;flex-direction: column;justify-content: space-between;">
          <span class="pay-title">
            <span class="pay-titles">{{ item.productName }}</span>
            <template v-if="type == 2">
              <img v-if="item.orderStatus == 2" class="yizhifu" src="@/assets/yizhifu.png" alt="" />
              <img
                v-if="item.orderStatus != 2"
                style="padding-right: 0.15rem"
                class="yizhifu"
                src="@/assets/icon_yiquxiao.png"
                alt=""
              />
            </template>
          </span>
          <span class="pay-price">
            <span class="productUnitPrice" :class="{ productUnitPrice20: type == 2 }">
              <span class="icon">￥</span>
              <span class="price-title">
                <span class="intPrice">{{ parseInt(item.price) }}</span>
                <span style="font-weight: 200" v-if="String(item.totalAmount).indexOf('.') > -1">
                  .
                </span>
                <span class="floatPrice" v-if="String(item.totalAmount).indexOf('.') > -1">
                  {{ intPriceShow(item.price) }}
                </span>
              </span>
              <span class="goodsCount" v-if="this.$route.name != 'detail'"
                >&nbsp; x{{ item.goodsCount }}</span
              >
              <span class="goodsCount" v-else>&nbsp;&nbsp; </span>
            </span>
            <span class="pay-del" v-if="type == 2">×{{ item.goodsCount }}</span>
          </span>
        </div>
        <div class="pay-status" v-if="type != 2">
          <div class="status-top">
            <div v-if="item.orderStatus == 2" class="pay-status-icon"></div>
            <div v-else class="pay-status-icon pay-status-icon2"></div>
            <div v-if="item.orderStatus == 2">已支付</div>
            <div v-else class="pay-status-txt">订单取消</div>
          </div>

          <div class="status-bottom">
            <div>{{ item.orderStatus == 2 ? " 实付款" : "需付款" }}</div>
            <span>&nbsp;¥&nbsp;</span>
            <span class="intPrice">{{ parseInt(item.totalAmount) }}</span>
            <div
              v-if="String(item.totalAmount).indexOf('.') > -1"
              style="width: 0.1rem; margin-top: 0.05rem"
            >
              .
            </div>
            <div class="floatPrice" v-if="String(item.totalAmount).indexOf('.') > -1">
              {{ intPriceShow(item.totalAmount) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pay-bottom" v-if="type != 2">
      <div class="pay-time">{{ item.createTime }}</div>
      <div class="pay-btn">查看订单</div>
    </div>
    <div class="pay-total" v-if="this.$route.name != 'my'">
      <div class="txt">{{ item.orderStatus == 2 ? " 实付款" : "需付款" }}</div>
      <!-- <div class="txtMoney">￥{{ item.totalAmount }}</div> -->
      <div class="txtMoney" style="color: #165dff !important">
        <span >¥&nbsp;</span>
        <span class="intPrice" style="color: #165dff !important; line-height:1">
          {{ parseInt(item.totalAmount) }}
        </span>
        <div
          v-if="String(item.totalAmount).indexOf('.') > -1"
          style="width: 0.1rem; color: #165dff !important; margin-top: 0.05rem"
        >
          .
        </div>
        <div
          class="floatPrice"
          style="color: #165dff !important; margin-top: 0.1rem"
          v-if="String(item.totalAmount).indexOf('.') > -1"
        >
          {{ intPriceShow(item.totalAmount) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: [Number, String],
    item: [Object, Array],
  },
  data() {
    return { num: 1, floatPrice: "" };
  },
  computed: {},
  methods: {
    intPriceShow(v) {
      const str = v + ""; //转成字符串
      const arr = str.split("."); //使用分隔符分割字符串成数组
      // console.log(arr, "arr"); // ["8", "01"]
      let tempStr = arr[1] ? arr[1] : "00"; //如果小数点后有值就用该值，没有默认'00'
      // this.floatPrice = tempStr.length == 1 ? tempStr + "0" : tempStr; //小数点后只有一位的话，补0
      return arr[1];
    },

    getBaseInfos() {
      if (this.isWeiXin()) {
        const code = this.getUrlParam("code"); // 截取路径中的code
        if (code == null || code === "") {
          let url = "https://channel.ai-tax.cn";
          window.location.href =
            "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx4de932054829ef8c&redirect_uri=" +
            encodeURIComponent(url) +
            "&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect";
        } else {
        }

        if (code != "" && code != null) {
          this.wxCode = code;
          // alert(code);
          // this.getOpenid(code);
        }
      } else {
      }
    },
    //判断是不是微信浏览器。
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    // 编码函数
    getUrlParam(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
      var r = window.location.search.substr(1).match(reg); //匹配目标参数
      if (r != null) return unescape(r[2]);
      return null; //返回参数值
    },
    toDetail(orderNo) {
      this.$router.push({
        path: "/detail",
        query: {
          orderNo: orderNo,
        },
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.pay {
  width: 7.1rem;
  // height: 2.76rem;
  background: #FFFFFF;
  border-radius: 0.2rem 0.2rem 0.2rem 0.2rem;
  opacity: 1;
  font-size: 0.24rem;
  padding: 0.2rem;
  box-sizing: border-box;
  margin-bottom: 0.2rem;

  .pay-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.28rem;
    padding-top: 0.3rem;
    padding-bottom: 0.1rem;
    border-top: 1px solid #E6E8EA;
    margin-top: 0.2rem;
    .txt{
      line-height: 0.4rem;
    }
    .txtMoney{
      color:#165DFF;
      vertical-align: baseline;
      display: flex;
    }
    .floatPrice{
      margin-top: 0.12rem;
    }
  }

  .pay-top {
    display: flex;
    justify-content: space-between;
    .pay-title {
      position: relative;
      font-size: 0.28rem;
      font-weight: 600;
      color: #0E1429;
      //margin-top: 0.14rem;
      //height: 0.86rem;
      .pay-titles {
        display:inline-block;
        font-weight: bold;

        overflow: hidden;
        text-overflow: ellipsis;
        width: 2.5rem;
        white-space: nowrap;
      }

      .yizhifu {
        position: absolute;
        top: 0;
        right: 0rem;
        width: 1.14rem;

      }
    }

    .pay-price {
      display: flex;
      vertical-align: baseline;
      align-items: center;
      font-weight: 600;
      font-size: 0.36rem;
      //line-height: 0.42rem;
      color: #3F74F6;
      //margin-top: 0.1rem;
      .pay-del {
        display: flex;
        align-items: center;
        color: #A4A6A9;
        font-size: 0.28rem;
        font-weight: 400;
      }

      .productUnitPrice {
        display: flex;
        width: 2rem;
        color: #1D2129;
        align-items: flex-end;
        font-size: 0.24rem;
        line-height: 1
        .price-title {
          display: flex;
          font-family: DIN Alternate-Bold, DIN Alternate;
          font-size: 0.36rem;
          font-weight: bold;
          margin-left: 0.05rem;
        }
        .goodsCount{
          font-weight: 400;
          color: #A4A6A9;
          font-size: 0.24rem;
          margin-top:0.05rem;
        }
      }

      .productUnitPrice20 {
        margin-right: 2.4rem;
      }
    }

    .pay-status {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      align-self: start;
      color: #18CC87;
      flex-direction: column;
      height: 100%;
      .status-top{
        font-size: 0.24rem;
        display: flex;
        align-items: center;
        .pay-status-icon {
          width: 0.14rem;
          height: 0.14rem;
          background: #18CC87;
          opacity: 1;
          border-radius: 50%;
          margin-right: 0.14rem;
        }

        .pay-status-icon2 {
          background: rgba(250, 79, 79, 1);
          color: rgba(250, 79, 79, 1);
        }

        .pay-status-txt {
          color: rgba(250, 79, 79, 1);
        }
      }
      .status-bottom{
        height: 0.33rem;
        font-size: 0.24rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #1D2129;
        display: flex;
        margin-top: 0.1rem;

        span{
          font-weight: bold;
          color: #1D2129;
          font-size: 0.24rem;
        }

      }

    }
  }

  .pay-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.2rem;

    .pay-time {
      font-size: 0.26rem;
      color: #A4A6A9;
      line-height: 0.37rem;
    }

    .pay-btn {
      width: 1.44rem;
      line-height: .34rem;
      background: #fff;
      border-radius: .1rem;
      opacity: 1;
      border: 1px solid #165DFF;
      text-align: center;
      color: #165DFF;
      font-size: .26rem;
      box-sizing: border-box;
      padding: 0.09rem 0;
    }
  }

  .pay-bottom-text {
    display: flex;
    align-items: baseline;
    width: 6.5rem;
    background: rgba(253,179,32,0.08);
    border-radius: 0.1rem;
    opacity: 1;
    margin-top: 0.3rem;
    color: #FDB320;
    font-size: 0.24rem;
    padding: 0.2rem 0.4rem 0.2rem 0.2rem;
    box-sizing: border-box;
    line-height: 1.7;

    .el-icon-warning-outline {
      margin-right: 0.1rem;
    }
  }
}

.pay-icon {
  width: 1.61rem;
  height: 1.61rem;
  padding-right: 0.3rem;

  img {
    width: 100%;
    height:100%;
    border-radius: 0.16rem;
  }
}
.intPrice{
  font-size: 0.36rem;
  font-weight: bold;
  color: #1D2129;
  font-family: DIN Alternate-Bold, DIN Alternate;

}
.floatPrice{
  font-size: 0.24rem;
  font-weight: bold;
  color: #1D2129;
  margin-top: 0.04rem;
}
.icon{
  margin-top: 0.05rem;
}
</style>
