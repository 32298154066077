<template>
  <div
    class="main-wrapper"
    v-loading="!loaded"
    element-loading-text="正在验证授权"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <keep-alive>
      <router-view v-if="isAuthored" />
    </keep-alive>
  </div>
</template>

<script>
import { checkShareCode } from "../../api/api";

export default {
  metaInfo: {
    title: localStorage.getItem("goodsName"),
  },
  name: "UserOnly",
  components: {},
  props: {
    uuid: String,
    shareCode: String,
  },
  data() {
    return {
      loaded: false,
      isAuthored: false,
    };
  },

  mounted() {
    console.log(this.$route)
    // 设置标题
    var titleElement = document.querySelector("title");
    titleElement.innerText = localStorage.getItem("title");
    this.verify();
  },
  methods: {
    async verify() {
      if (this.$route.name) {
        if (this.$route.query.admin == 1) {
          sessionStorage.setItem("isFromAdmin", 1);
        } else {
          //localStorage.removeItem("isFromAdmin")
        }
      }
      //如果来自管理后台
      if (sessionStorage.getItem("isFromAdmin") == 1) {
        this.isAuthored = true;
        this.loaded = true;
      } else {
        const res = await checkShareCode(
          this.uuid,
          this.shareCode
          // this.$store.state.authorization
        );

        this.loaded = true;
        //todo 占时放开权限
        this.isAuthored = true;

        // if (res.data&&res.data.result) {
        //   this.isAuthored = true
        // }else{
        //   this.$alert('权限错误，请登录报告对应的账号查看。', '消息', {
        //     confirmButtonText: '',
        //     showConfirmButton: true,
        //     showClose: false,
        //     callback: action => {
        //       this.$router.push({ name: "login" });
        //     }
        //   });
        //
        // }
      }
    },
  },
  computed: {},
};
</script>

<style lang="stylus" scoped>
.main-wrapper {
  min-height 100vh
}
</style>
