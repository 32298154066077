<template>
  <div>
    <van-tabs v-model="activeIndex" type="card" class="btn-tabs">
      <van-tab :title="year" :key="year" v-for="(yearChart, year) in data.yearChart">
        <div class="tab-content">
          <ColumnChart2 :data="yearChart.invoiceImageMap.ruleOfInvoiceImageList[0]"></ColumnChart2>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import ColumnChart2 from "../../component/ColumnChart2.vue";

export default {
  name: "CoreFinanceData",
  components: { ColumnChart2 },
  props: {
    data: Object,
  },
  watch: {
    data: {
      handler() {
        //设置为最近一年(倒数第一)
        this.activeIndex = Object.entries(this.data.yearChart).length - 1;
      },
      immediate: true,
    },
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  computed: {
    chartData() {
      const ret = [];
      for (const i in this.data.xLabels) {
        const label = this.data.xLabels[i];
        const value = this.data.series[0].data[i];

        ret.push({ name: label, value });
      }
      return ret;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="stylus" scoped>
.btn-tabs {

  border-bottom 1px dashed #7585A2;
  padding-bottom 20px
}

.tab-content {
  padding-bottom 30px
}
>>> .btn-tabs.van-tabs--card .van-tabs__nav--card .van-tab{
  font-weight: bold
  font-size: 16px;
}
@media screen and (min-width:500px) and (max-width: 2000px){
  >>> .btn-tabs.van-tabs--card .van-tabs__nav--card .van-tab{
    flex-basis: 5vw;
  }
}
</style>
