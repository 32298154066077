<template>
  <div class="table-wrapper">
    <BigPowerTable :data="data" v-if="loaded" :loading="true" class="table"></BigPowerTable>

    <div v-else class="table-wrapper loading">
      <van-loading size="24px">载入中</van-loading>
    </div>
  </div>
</template>

<script>
// import BigPowerTable from "big-power-table";
import BigPowerTable from "@/components/bigPowerTable/BigPowerTable.vue";

export default {
  name: "BossTable",
  props: {
    data: Object,
    loaded: Boolean,
  },
  components: {
    BigPowerTable,
  },
};
</script>

<style lang="stylus" scoped>
.loading{
  display flex
  align-items center
  justify-content center
}
.table-wrapper
  background: #FFFFFF;
  position: relative;

  width 100%

  .sc {
    position: absolute;
    right: 0px;
    bottom: 0px;
    padding: 5px;

    img {
      width: 0.25rem;
      height: 0.25rem;
      background: #FFFFFF;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
      border-radius: 0.08rem;
    }
  }

// .table {
//   >>> .bigPowerTable {
//     max-height 4.04rem
//     overflow auto
//   }
// }

.full-sc {
  .full-tab {
    transform-origin: center center;
    transform: rotateZ(90deg);
  }

  >>> .el-drawer__header {
    padding: 0;
  }

  >>> .el-drawer__body {
    overflow: hidden;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  >>> .el-drawer__open .el-drawer.btt {
    border-radius: 0;
  }

  >>> .el-drawer__close-btn {
    font-size: 30px;
    position: relative;
    right: 10px;
    top: 10px;
  }

  .table {
    >>> .bigPowerTable {
      max-height 5.8rem
      max-width 85vh
      overflow auto
    }

    >>> table.table th, >>> table.table td {
      font-size: 0.2rem;
    }
  }

}

>>> .bigPowerTable {
  max-height 457px
  overflow auto
}

:deep(table) {
  border-top: 1px solid #73A0FA;
  border-left: 1px solid #73A0FA;
  border-bottom: 1px solid #73A0FA;


  th {
    background: #EFF4FF;
    font-weight: bold
    text-decoration-color:#EFF4FF !important
    padding 0.34rem 0.16rem

  }

  th, td {
    border-right: 1px solid #73A0FA;
    border-bottom: 1px solid #73A0FA ;
  }
  .clickable{
    color #A4A6A9;
    //text-decoration underline
    cursor pointer
  }

}
</style>
