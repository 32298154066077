<template>
  <div class="try-info">
    <div class="top"></div>
    <tryInfo type="2"></tryInfo>
  </div>
</template>

<script>
import tryInfo from "./components/try-info.vue";

export default {
  components: {
    tryInfo,
  },
};
</script>

<style lang="stylus" scoped>
.top {
  width: 100%;
  height: 0.2rem;
  background: #F8F9FB;
}
.try-info {
  width: 100%;
  height: 100vh;
  background: #fff;
}
</style>
