<template>
    <div class="paymentSuccess">
      <img class="icon-wode" src="@/assets/noposter.png" alt="" />
      <div class="successText">海报信息不存在或者已被删除</div>
      <div class="info">
        您可以用手机验证码快速登录电脑端官方网站，查看海报的相关信息
      </div>
      <div class="inputs" id="copyInner">
        www.ai-tax.cn
      </div>
      <div class="copy" @click="copy">
        复制链接
      </div>
      <!-- <div class="btn">
        <div class="cost" @click="goText">立即检测</div>
        <div class="order" @click="goMy">我的订单</div>
  
        <div class="order" @click="goHome">商品橱窗</div>
      </div> -->
    </div>
  </template>
  
  <script>
  import { copyText} from "@/utils/help.js"
  export default {
    metaInfo: {
      title: "温馨提示",
    },
    methods: {
      copy(){
            copyText("copyInner");
        
      }
    },
  };
  </script>
  
  <style lang="stylus" scoped>
  .icon-wode{
      width:4rem;
      height:4rem;
      margin-top: 1.08rem;
      margin-bottom: 0.78rem;
  }
  .paymentSuccess{
    .copy{
        width: 5.6rem;
        height: 0.88rem;
        background: #165DFF;
        border-radius: 4px 4px 4px 4px;
        color: #fff;
        text-align: center;
        font-size: 14px;
        box-sizing: border-box;
        padding-top: 12px;
    }
    >.inputs{
        width: 5.6rem;
        height: 0.88rem;
        background: #FFFFFF;
        box-sizing: border-box;
        padding: 10px 15px;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #DFDFE0;
        font-size: 17px;
        color: #1D2129;
        margin-bottom: 0.48rem;

    }
    >.info{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
      width: 5.88rem;
      color: #606368;
      margin-top: 0.4rem;
      margin-bottom: 0.8rem;
      text-align: center;
    }
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #fff;
      height: 100vh;
      .successText{
        font-family: PingFang SC, PingFang SC;
        font-size: 17px;
        color: #1D2129;
        font-size: 0.36rem;
        font-weight: 600;
      }
  }

  
  .btn {
      display: block;
      //align-items: center;
      //margin-top: 1.2rem;
  
      .order, .cost {
        width: 4rem;
        height: 0.8rem;
        line-height: 0.8rem;
        background: rgba(63, 116, 246, 0.2);
        border-radius: 0.1rem;
        color: #3F74F6;
        margin-top :20px;
        font-size: 0.32rem;
        text-align: center;
      }
  
      .cost {
        //margin-left: 0.4rem;
        margin-top :1.2rem;
  
        background: #165DFF;
        opacity: 1;
        color: #fff;
      }
    }
  </style>
  