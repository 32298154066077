<template>
  <div>
    <van-tabs v-model="Index" type="card" class="btn-tabs" @change="tabschange">
      <van-tab title="近1年" :name="1">
        <div class="tab-content">
          <LineChart
            :data="lineData.invoiceImageMap.ruleOfInvoiceImageList[0]"
            v-if="lineData.invoiceImageMap"
            ref="charts1"
          ></LineChart>
        </div>
      </van-tab>
      <van-tab title="近2年" :name="2">
        <div class="tab-content">
          <LineChart
            :data="lineData.invoiceImageMap.ruleOfInvoiceImageList[0]"
            v-if="lineData.invoiceImageMap"
            ref="charts1"
          ></LineChart>
        </div>
      </van-tab>
      <van-tab title="近3年" :name="3">
        <div class="tab-content">
          <LineChart
            :data="lineData.invoiceImageMap.ruleOfInvoiceImageList[0]"
            v-if="lineData.invoiceImageMap"
            ref="charts1"
          ></LineChart>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import LineChart from "./LineChart.vue";
export default {
  name: "LineBarTabs",
  components: { LineChart },
  props: {
    data: Object,
    activeIndex: Number,
  },
  data() {
    return {
      lineData: {},
      Index: null,
      //data:{"imageSort":0,"imageType":"annulus","imageUnit":"%","series":[{"data":["0.3561","0.2048","0.0206","0.0203","0.0187","0.0136","0.0121","0.0119","0.0102","0.0102","0.3215"],"name":"比例"}],"xLabels":["*鉴证咨询服务*咨询服务","*一般税务咨询*顾问费","*鉴证咨询服务*咨询费","*涉税鉴证*审计费","*涉税鉴证*所得税汇算清缴262","*涉税鉴证*土增292","*经纪代理服务*代理费","*涉税鉴证*所得税汇算清缴129","*涉税鉴证*土增228","*鉴证咨询服务*其他鉴证服务224","其他"]}
    };
  },
  mounted() {
    this.lineData = this.data.yearChart;
    this.Index = this.activeIndex;
  },
  computed: {
    // lineData(){
    //   return  this.data.yearChart
    //   // return  Object.values(this.data.yearChart)[0]
    // },
  },
  methods: {
    tabschange(e) {
      this.$emit("tabschange", e);
      // this.$refs.charts1.showMap()
    },
  },
  watch: {
    data: {
      handler(val) {
        //设置为最近一年(倒数第一)
        // this.activeIndex = Object.entries(this.data.yearChart).length - 1
        this.lineData = val.yearChart;
      },
      immediate: true,
    },
    activeIndex: {
      handler(val) {
        this.Index = val;
      },
    },
  },
};
</script>

<style lang="stylus" scoped>

.btn-tabs{
  //border-bottom 1px dashed #7585A2;
  padding-bottom 20px
  font-weight: bold
}

.tab-content {
  padding-bottom 20px
  font-weight: bold;
}
.btn-tabs >>>.van-tab__text{
  font-weight: 600;
  font-size: 14px;
}
@media screen and (min-width:500px) and (max-width: 2000px){
  >>> .btn-tabs.van-tabs--card .van-tabs__nav--card .van-tab{
    flex-basis: 5vw;
  }
}
</style>
