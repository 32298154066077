import {
    Message
  } from 'element-ui'
export function copyText(elementId) {
    var element = document.getElementById(elementId); // 获取需要复制的元素
    
    if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(element.innerText).then(() => {
            console.log("已成功将文本复制到剪贴板");
            Message.success({
                message: "已成功将文本复制到剪贴板"
              });
        }, () => {
            console.error("无法将文本复制到剪贴板");
        });
    } else {
        var range = document.createRange();
        range.selectNodeContents(element);
        
        var selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        
        try {
            var successful = document.execCommand('copy');
            
            if (!successful) {
                throw new Error('无法复制文本');
            }
            
            console.log("已成功将文本复制到剪贴板");
            Message.success({
                message: "已成功将文本复制到剪贴板"
              });
        } catch (err) {
            console.error("无法将文本复制到剪贴板", err);
        } finally {
            selection.removeAllRanges();
        }
    }
}


export function copyTextForText(text) {
    // 创建一个隐藏的textarea
    var textarea = document.createElement('textarea');
    
    // 将要复制的文字放入textarea
    textarea.value = text;
    
    // 将textarea添加到DOM中，以便可以执行选择和复制操作
    document.body.appendChild(textarea);
    
    // 选中文本
    textarea.select();
    
    try {
        // 执行浏览器复制命令
        var successful = document.execCommand('copy');
        if (successful) {
            Message.success({
                message: "已成功将文本复制到剪贴板"
              });
        } else {
            console.log('复制失败');
        }
    } catch (err) {
        console.log('复制失败：' + err);
    }

    // 删除创建的textarea
    document.body.removeChild(textarea);
}
