<template>
  <div class="chart-wrapper">
    <div class="unit">单位：万元</div>
    <div ref="theChart" class="echarts1"></div>
  </div>
</template>
<script>
// import echarts from "echarts";
import * as echarts from "echarts";

export default {
  name: "ColumnChart2",
  data() {
    return {
      chartColumnM: null,
      chartData: [],
    };
  },
  props: {
    data: Object,
    width: String,
    height: String,
  },
  computed: {
    unit() {
      return this.data.unit || "";
    },
    headers() {
      return this.data.series.map((value) => value.name);
    },
    series() {
      const ret = [];

      const colors = [
        {
          type: "bar",
          itemStyle: {
            normal: {
              color: (params) => {
                //根据数值大小设置相关颜色
                if (params.value[this.headers[0]] < 0) {
                  return "#F7C739";
                } else {
                  return "#73A0FA";
                }
              },
              // label: {
              //   show: true,
              //   position: (params) => {
              //     if (params.value[this.headers[0]] < 0) {
              //       return 'left'
              //     } else {
              //       return 'right'
              //     }
              //   },
              //   textStyle: {
              //     fontSize: 12,
              //     color: '#1D2129'
              //   }
              // }
            },
          },
          barWidth: "21",
          label: {
            formatter: function (data, b, c, d, e) {
              return `${data.name}\n${data.value[""]}`;
            },
            show: true,
            position: "top",
            textStyle: {
              fontSize: 10,
              color: "#1D2129",
            },
          },
        },
      ];

      for (let i = 0; i < this.headers.length; i++) {
        const element = this.headers.length[i];
        const color = colors[colors.length - i - 1];
        ret.push(color);
      }

      return ret;
    },
    source() {
      const ret = [];

      this.data.xLabels.forEach((productName, productIndex) => {
        const item = {};
        item.product = productName;
        this.data.series.forEach((aSeries) => {
          item[aSeries.name] = aSeries.data[productIndex];
        });
        ret.push(item);
      });

      // grp.data.forEach((v,index) => {
      //   item[this.data.xLabels[index]] = parseFloat(v);
      // });

      return ret;
    },
  },
  mounted() {
    if (this.width) {
      this.$refs.chartColumn.style.width = this.width;
    }

    if (this.height) {
      this.$refs.chartColumn.style.height = this.height;
    }
    const myChart = echarts.init(this.$refs.theChart, null, {
      devicePixelRatio: 4,
      renderer: "svg",
    });
    const options = {
      legend: {
        x: "center",
        bottom: "5%",
        show: false,
      },
      tooltip: {
        formatter: (params) => {
          // const text = s.unSmooth(params.value[params.seriesName]);
          const text = params.value[""];
          return text + this.unit;
        },
      },
      dataset: {
        dimensions: ["product", ...this.headers],
        source: this.source,
      },

      xAxis: {
        type: "value",
        position: "top",
        splitLine: {
          show: true,
          lineStyle: {
            color: "#EEEEEE",
            width: 1,
            type: "solid",
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "rgba(0,0,0,0.45)",
          },
        },
        axisLabel: {
          // rotate: 35,
          interval: 0,
          textStyle: {
            fontSize: 10,
            fontFamily: "PingFangSC-Regular,PingFang SC",
            color: "#7585A2",
          },
        },
      },
      grid: {
        left: "5%",
        right: "8%",
        top: 20,
        bottom: "0%",
        containLabel: true,
      },
      yAxis: {
        type: "category",
        axisLabel: {
          show: false,
          formatter: `{value} ${this.unit}`,
          interval: 0,
          textStyle: {
            fontSize: 10,
            fontFamily: "PingFangSC-Regular,PingFang SC",
            color: "#7585A2",
          },
        },
        axisTick: {
          //y轴刻度线
          show: false,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "rgba(0,0,0,0.45)",
          },
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: "#E5E6EB",
            width: 1,
            type: "solid",
          },
        },
      },
      // Declare several bar series, each will be mapped
      // to a column of dataset.source by default.
      series: this.series,
    };
    myChart.setOption(options);
  },
};
</script>
<style lang="stylus" scoped>
.chart-wrapper {
  position relative

  .unit {
    text-align right
    font-size: 12px;
    font-weight: 400;
    color: #4E5969;
    line-height: 18px;
    margin-bottom:20px
  }
}

.echarts1 {
  width: 89vw;
  height: 125vw;

  >>> div:first-child {
    width: 100% !important;
  }
}
@media screen and (min-width:500px) and (max-width: 2000px){
  .echarts1 {
    width: 350px;
    height: 500px;

    >>> div:first-child {
      width: 100% !important;
    }
  }

}
</style>
