import axios, {
  get,
  post
} from "@/axios";

const base = process.env.VUE_APP_API_ROOT;

// 微信授权获取用户信息
export const wechatAuth = params => {
  return get(`${base}/channel/auth/wechatAuth`, params)
    .then(res => res);
};

// 通过渠道唯一标识获取商品列表
export const getProductListByChannelUniqueId = params => {
  return get(`${base}/channel/channelCustomer/getProductListByChannelUniqueId`, params)
    .then(res => res.data);
};

// 获取产品套餐管理表信息详情
// export const channelProductConfig = params => {
//   return get(`${base}/channel/channelProductConfig/getInfo`, params)
//     .then(res => res.data);
// };
export const getChannelInfo = params => {
  return get(`${base}/channel/channelPolicyConfig/getInfo`, params)
    .then(res => res.data);
};
export const getPayInfo = params => {
  return get(`${base}/channel/channelCustomer/getPayInfo`, params)
    .then(res => res.data);
};

// 发起预支付
export const unifiedOrder = params => {
  return post(`${base}/channel/channelOrdOrder/unifiedOrder`, params)
    .then(res => res.data);
};
//秒杀支付
export const discount = params => {
  return post(`${base}/channel/channelOrdOrder/unifiedOrder/discount`, params)
    .then(res => res.data);
};


// 绑定手机号码
export const bindPhone = params => {
  return post(`${base}/channel/auth/bindPhone`, params)
    .then(res => res);
};

// 房地产项目列表详情
export const bSuperiorProjectDetail = id => {
  return get(`${base}/bSuperiorProject/getInfo?id=${id}`)
    .then(res => res.data);
};

// 获取渠道订单信息表分页列表
// export const channelOrdOrderPage = params => {
//   return post(`${base}/channel/channelOrdOrder/getPage`, params)
//     .then(res => res.data);
// };
export const channelOrdOrderPage = params => {
  return post(`${base}/channel/channelOrdOrder/customer/getPage`, params)
    .then(res => res.data);
};

// 通过订单号获取订单信息详情
export const getOrderInfo = orderNo => {
  return get(`${base}/channel/channelOrdOrder/getOrderInfo?orderNo=${orderNo}`)
    .then(res => res.data);
};

// 创建用户提交信息表
export const userMessage = params => {
  return post(`${base}/channel/channelCustomer/TrialCustomer`, params)
    .then(res => res.data);
};

// 
export const autoLogin = params => {
  return get(`${base}/channel/auth/autoLogin`, params)
    .then(res => res.data);
};

// 发送验证码
export const sendVerifyCode = mobile => {
  return get(`${base}/channel/auth/sendVerifyCode?mobile=${mobile}`)
    .then(res => res.data);
};

// 支付订单前校验系统登录手机号
export const checkPhoneNumber = params => {
  return get(`${base}/channel/channelOrdOrder/checkPhoneNumber`, params)
    .then(res => res.data);
};

// 获取已经购买过的手机号公司名称
export const getBuyLoginInfoList = goodsId => {
  return get(`${base}/channel/channelOrdOrder/getBuyLoginInfoList?goodsId=${goodsId}`)
    .then(res => res.data);
};



// 公司名称搜索
export const companySearch = params => {
  return get(`${base}/shuian-home/business/info/search`, params)
    .then(res => res.data);
};


export const getCompanyMsgByTaxNo = (params) => {
  return get(`${base}/daqc/thirdAuth/getCompanyMsgByTaxNo`, params).then(res => res.data);
};


export const getUuid = () => {
  return get(`${base}/shuian-home/testing/record/getUuid`).then(res => res.data);
};


export const getAreaMsg = (params) => {
  return get(`${base}/daqc/thirdAuth/getAreaMsg`, params).then(res => res.data);
};

export const commitAuthorized = (params) => {
  return post(`${base}/shuian-home/testing/risk/v2/commitAuthorized`, params).then(res => res.data);
};


export const getAuthorizeStatus = (params) => {
  return get(`${base}/daqc/thirdAuth/getAuthorizeStatus`, params).then(res => res.data);
};
//获取渠道配置标题
export const getTitleApi = channelUniqueId => {
  return get(`${base}/channel/auth/getTitle?channelUniqueId=${channelUniqueId}`)
    .then(res => res.data);
};


//存储发起检测用户信息
export const saveCheckUser = programId => {
  return get(`${base}/shuian-home/testing/risk/saveCheckUser?programId=${programId}`)
    .then(res => res.data);
};


//获取发起检测用户是否为同一人
export const getCheckUser = programId => {
  return get(`${base}/shuian-home/testing/risk/getCheckUser?programId=${programId}`)
    .then(res => res.data);
};



