<template>
  <div class="wrapper">
    <div class="chart-wrapper">
      <div class="unit">单位：{{ data.unit }}</div>
      <div v-if="isshowcharts" class="isshowcharts">本年度系统采集到企业<span>{{ activeIndex == 'entry' ? '进项' : '销项'
      }}</span>发票数据为0，请及时确认！</div>
      <PieChart :colors="colors" :data="chartData" :title="data.imageTitle" :total="total" v-if="!isshowcharts"
        :key="JSON.stringify(chartData)"></PieChart>
    </div>

    <div class="legends">


      <div class="legend" v-for="(item, index) in chartData" :key="index">
        <svg class="icon" aria-hidden="true">
          <use :xlink:href="getIcons(item.name)"></use>
        </svg>

        <div class="legend-right">
          <div class="item-text-wrapper">
            <div class="item-text-item title">
              {{ item.name }}
            </div>
            <div class="item-text-item percentage">
              {{ (Math.round(item.value * 100 * 10000) / 10000).toFixed(2) }}%
            </div>
            <div class="item-text-item value">
              {{ item.quantity }}
            </div>
          </div>

          <el-progress :show-text="false" :text-inside="true" :stroke-width="17" :percentage="(Math.round(item.value * 100 * 100) / 100)" 
            :color="colors[index % colors.length]">

          </el-progress>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import PieChart from "../../component/PieChart";

export default {
  name: "RingBar",
  props: {
    activeIndex: String,
    data: Object,
    total: {
      default: "",
      type: [String, Number]
    },
    height: {
      type: String,
      default: "362px",
    },
    colors: {
      type: Array,
      default() {
        return [
          "#73A0FA",
          "#73DEB3",
          "#7585A2",
          "#F7C739",
          "#E8684A",
          "#269A99",
          "#26609A",
          "#F5B46F",
          "#C7CC8A",
          "#F48787",
          "#A28BEE",
          "#F5A34A",
          "#F899D0",
          "#6F82EF",
          "#6FC1AF",
          "#D19EE1",
          "#F5966F",
          "#B5D4A3",
          "#000000",
        ];
      },
    },
  },
  components: {
    PieChart,
  },
  mounted() {

  },
  computed: {
    isshowcharts() {
      return this.chartData.every(item => {
        return item.value === 0
      })
    },
    chartData() {
      const ret = [];
      const total = this.data.series[0].data.reduce((acc, cur) => {
        return parseFloat(acc) + parseFloat(cur)
      }, 0)
      for (const i in this.data.xLabels) {
        const label = this.data.xLabels[i];
        const value = this.data.series[0].data[i] * 1 / total;
        const quantity = this.data.series[0].data[i];
        ret.push({ name: label, value: isNaN(value) ? 0 : value, quantity });
      }
      return ret;
    },
  },
  methods: {
    getIcons(name) {
      const icons = {
        '*鉴证咨询服务*咨询服务': '#iconguanlifeiyong',
        '专票': '#iconhuowu',
        '普票': '#iconpupiao',
        '其他': '#iconqita',
        '印花税': '#iconyinhuashui',
        '企业所得税': '#iconqiyesuodeshui',
        '增值税': '#iconzengzhishui',
        '城建税': '#iconchengjianshui',
        '教育费附加': '#iconjiaoyufeifujia',
        '地方教育附加': '#icondifangjiaoyufujia',
        '城市维护建设税': '#iconchengshiweihujiansheshui1',
        '城镇土地使用税': '#iconchengzhentudishiyongshui',
        '环境保护税': '#iconhuanjingbaohushui1',
        '个人所得税': '#icongerensuodeshui',
        '车船税': '#iconchechuanshui',
        '房产税': '#iconfangchanshui',
        '耕地占用税': '#icongengdizhanyongshui',
        '车辆购置税': '#iconchelianggouzhishui',
        '烟叶税': '#iconyanyeshui',
        '消费税': '#iconxiaofeishui',
        '资源税': '#iconziyuanshui',
        '契税': '#iconqishui',

      }
      return icons[name] || '#iconhuowu'
    }
  }
};
</script>

<style lang="stylus" scoped>
.isshowcharts{
  margin :20px 0;
  height: 24px;
  line-height: 24px
background: rgba(234,108,91,0.08);
border-radius: 4px;
text-align: center;
padding:6px
border: 1px solid #EA6C5B;
  font-size: 12px;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #EA6C5B;
}
.wrapper {
  // display: flex;
  width: 100%;
  padding: 0;

  .chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .unit {
      position absolute
      right: 0
      top: 0
      font-size: 12px;
      font-weight: 400;
      color: #4E5969;
      line-height: 18px;
    }
  }

  .legends {
    flex-shrink: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    font-size 0.2rem

    .legend {
      width: 100%;
      margin-bottom 20px
      display flex
      align-items center

      .icon {
        width 32px
        height 32px
        margin-right 12px
      }

      .legend-right {

        flex-grow 1

        .item-text-wrapper {
          .title {
            width 20%
          }

          .percentage {
            width 50%
            padding-left 20px
            box-sizing border-box
          }

          .value {
            width 30%
            text-align right
          }
          font-size: 12px;
          font-weight: 400;
          color: #4E5969;
          line-height: 18px;
          margin-bottom 8px
          display flex
        }
      }

    }
  }
}
</style>
