<template>
  <div style="background: #f5parts-headerf7fa">
    <div class="whiteStyle"></div>
    <div style="padding: 12px; font-size: 14px; display: none" class="h5-wrapper">
      <div v-for="(v, k) of detailsList" :key="k">
        <div v-for="(a, b) of v.children" :key="b">
          <div v-for="(value, index) of a.children" :key="index" class="single">
            <div class="title" :id="'title' + value.id">{{ value.titleName }}</div>
            <!-- 发票模型开始 -->
            <div class="shuoming" v-if="value.type == 1">分析结果</div>
            <div v-if="value.type == 1" class="fontStyle">{{ value.dataText }}</div>
            <GenericTable :data="value.table" class="table" v-if="value.type == 1"></GenericTable>
            <!-- 发票模型结束 -->
            <!-- 税务开始 -->
            <ColumnChart
              v-if="value.type == 2 && value.pdfDatas.ruleParamsTemplete"
              :data="JSON.parse(value.pdfDatas.ruleParamsTemplete)"
            >
            </ColumnChart>
            <div class="shuoming" v-if="value.type == 2">风险说明</div>
            <div v-if="value.type == 2" class="fontStyle">
              {{ value.pdfDatas.ruleRiskStatement }}
            </div>
            <div class="fangan green" v-if="value.type == 2">应对方案</div>
            <div v-if="value.type == 2" class="fontStyle">{{ value.pdfDatas.ruleAdvise }}</div>
            <div class="fangan" v-if="value.type == 2 && value.pdfDatas.caseText != ''">
              法规案例
            </div>
            <div v-if="value.type == 2" class="fontStyle">{{ value.pdfDatas.caseText }}</div>
            <!-- 税务结束 -->
          </div>
        </div>
      </div>
    </div>
    <div class="parts">
      <div class="parts-content" v-for="(item, index) in parts" :key="item.index">
        <div class="parts-header">{{ index + 1 }} {{ item.name }}</div>
        <div class="parts-header-line"></div>
        <div class="parts-header-two" v-for="(item2, index) in item.children" :key="item2.index">
          <div class="left-line"></div>
          <div class="title">{{ index + 1 + "." + item2.orders }} {{ item2.name }}</div>
        </div>
        <div
          class="onAnalysis"
          :class="riskLevel == 1 ? 'redOne' : 'yellowOne'"
          v-for="(item2, index) in item.children"
          :key="item2.index"
        >
          <div
            class="onAnalysis-icon"
            v-if="riskLevel != 0"
            :class="riskLevel == 1 ? 'redTwo' : 'yellowOne'"
          >
            <span :class="riskLevel == 1 ? 'redThree' : 'yellowOne'"
              >{{ riskLevel == 1 ? "高" : riskLevel == 2 ? "中" : riskLevel == 3 ? "低" : ""
              }}{{ item2.data.riskLevel }}</span
            >
          </div>
          <div class="onAnalysis-body">
            <div class="title">分析结果</div>
            <div class="onAnalysis-content">
              <span> {{ item2.data }}</span>
            </div>
            <img v-if="riskLevel == 0" src="../../../../assets/img_gfx@2x.png" alt="" />
            <img v-if="riskLevel == 1" src="../../../../assets/img_gfx@2x (1).png" alt="" />
            <img v-if="riskLevel == 2" src="../../../../assets/img_gfx@2x (2).png" alt="" />
            <img v-if="riskLevel == 3" src="../../../../assets/img_gfx@2x (3).png" alt="" />
          </div>
        </div>
        <div class="verificationIdeas" v-for="(item2, index) in item.children" :key="item2.index">
          <div class="title">核实思路</div>
          <div class="verificationIdeas-content">
            {{ item2.initData }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getRiskDetailList } from "@/api/bossWatchReport";
import GenericTable from "./components/GenericTable.vue";
import ColumnChart from "./components/ColumnChart.vue";

export default {
  components: {
    GenericTable,
    ColumnChart,
  },
  computed: {},
  data() {
    return {
      detailsList: [],
      parts: [],
      riskLevel: 1,
      companyInfo: {
        zq: "",
        taxNo: "",
      },
      pdfs: [],
    };
  },
  watch: {
    $route(val) {
      if (val.name == "ReportDetails") {
        let name = this.$route.params.name;
        let titles = document.querySelectorAll(".title");
        let value = Array.from(titles).find((i) => i.innerText === name);
        if (value) {
          document.getElementById(value.id).scrollIntoView();
        }
      }
    },
  },
  async mounted() {
    this.getData();
    setTimeout(() => {
      let name = this.$route.params.name;
      let titles = document.querySelectorAll(".title");
      let value = Array.from(titles).find((i) => i.innerText == name);
      if (value) {
        document.getElementById(value.id).scrollIntoView();
      }
    }, 500);
  },

  methods: {
    generalTableData(pdfData) {
      if (!pdfData) {
        return null;
      }

      if (!pdfData.body) {
        return null;
      }
      const ret = [];

      for (let i = 0; i < pdfData.body.length; i++) {
        ret[i] = [];
        if (pdfData.pdfHeader) {
          pdfData.pdfHeader.forEach((key) => {
            ret[i].push(pdfData.body[i][key]);
          });
        } else {
          pdfData.header.forEach((key) => {
            ret[i].push(pdfData.body[i][key]);
          });
        }
      }

      return {
        data: ret,
        labels: pdfData.pdfHeader ? pdfData.pdfHeader : pdfData.header,
        //anchorPointType: pdfData.anchorPointType,
      };
    },
    async getData() {
      const _this = this;
      const params = {
        // uuid: this.$route.params.uuid,
        reportId: this.$route.params.reportId,
      };
      const res = await getRiskDetailList(this.$route.params.reportId);
      let list = res.data;
      this.parts = res.data.parts;
      //
      list.forEach((i) => {
        i.children.forEach((item) => {
          item.children.forEach((v) => {
            if (v.pdfData) {
              v.pdfDatas = JSON.parse(v.pdfData);
              v.table = this.generalTableData(JSON.parse(v.pdfData));
            }
          });
        });
      });
      this.detailsList = list;
    },
  },
};
</script>
<style lang="stylus" scoped>

.single{
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 1px 10px 2px rgba(0,0,0,0.1);
    border-radius: 16px;
    margin-bottom 12px
}
.shuoming{
    width:80px;
    height:32px;
    background: #A7C1FC;
    border-radius: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 32px;
    text-align: center;
    font-size 14px;
    margin-bottom:20px;
    margin-top: 20px;

}
.fangan{
    width:80px;
    height:32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    background: #26609A;
    border-radius: 16px;
    color: #FFFFFF;
    line-height: 32px;
    text-align: center;
    font-size 14px;
    margin-top: 20px;
    margin-bottom:20px;
}
.green{
  background: #6FC1AF;
  margin-top: 20px;
  margin-bottom:20px;
}
.fontStyle{
color: #4E5969
font-size: 14px;
letter-spacing: 2px;
line-height: 21px;
}
@media screen and (min-width:1300px) and (max-width: 2000px){
  .whiteStyle{
    height 40px;
   }
  .h5-wrapper {
    width: 375px;
    margin:0px auto;
    height:800px;
    overflow: auto;
    padding-bottom 0px
    box-shadow: 3px 2px 11px 2px #dedede;
  }
}

@media screen and (min-width:600px) and (max-width: 1300px){
  .whiteStyle{
    height 10px;
  }
  .h5-wrapper {
  margin:0px auto;
  width: 375px;
    height:600px;
    overflow: auto;
    padding-bottom 0px
    box-shadow: 3px 2px 11px 2px #dedede;
}
}
.parts{
  box-sizing: border-box
  margin-top: 0.2rem;
  background: #fff
  padding:0.3rem;
    .parts-content{
      .parts-header{
        height: 0.88rem;
        background: #2663F6;
        border-radius: 16px 16px 0px 0px;
        color: #fff;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding-left: 0.3rem;
        font-size: 0.32rem;
        font-weight: bold;
        margin-top: 0.3rem;
      }
      .parts-header:first-child(){
        margin-top: 0rem;
      }
      .parts-header-line{
        height: 0.04rem;
        background: #2663F6;
      }
      .parts-header-two{
        display: flex;
        margin-top: 0.3rem
        .left-line{
          width: 0.08rem;
          height: 0.3rem;
          background: #2663F6;
          border-radius: 4px 4px 4px 4px;
        }
        .title{
          font-size: 0.28rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: bold;
          color: #1D2129;
          margin-left: 0.2rem
        }
      }
      .onAnalysis{
        border-radius: 0.2rem;
        margin-top: 0.3rem;
        padding:0.2rem;
        box-sizing: border-box
        display: flex;
        align-items: center;
        .onAnalysis-icon{
          width: 1.2rem;
          height: 1.2rem;
          border-radius:50%
          display: flex;
          align-items: center;
          justify-content: center
          span{
            width: 1rem;
            height: 1rem;
            display: flex;
            align-items: center;
            font-size: 0.3rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: bold;
            color: #FFFFFF;
            border-radius:50%
            justify-content: center
          }
        }
        .onAnalysis-body{
          display: flex;
          flex-direction: column;
          width: 5.10rem;
          margin-left: 0.2rem
          .title{
            font-size: 0.28rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: bold;
          }
          .onAnalysis-content{
            font-size: 0.24rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            line-height: 0.36rem;
            margin-top: 0.1rem;
          }

        }
        img{
          width:1.2rem;
          height:1.2rem
          position: absolute;
          height: 1.2rem;
          right: 0.3rem;
        }

      }
      .verificationIdeas{
        background: #F4F6F8;
        border-radius: 0.2rem;
        padding:0.2rem;
        margin-top:0.3rem;
        .title{
          font-size: 0.28rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: bold;
          color: #1D2129;
        }
        .verificationIdeas-content{
          font-size: 0.24rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #1D2129;
          line-height: 0.36rem;
          margin-top:0.1rem;
        }
      }

    }
}
.redOne{
  background: #FFF0F0;
}
.redTwo{
  background: rgba(245,63,63,0.15);
}
.redThree{
  background: #F54242;
}
.redText{
  color: #F53F3F;
}

.yellowOne{
  background: #FFF7E5;
}
.yellowTwo{
  background: rgba(253,179,32,0.15);
}
.yellowThree{
  background: #FDB320;
}
.yellowText{
  color: #FDB320;
}

.blueOne{
  background: #EBF7FD;
}
.blueTwo{
  background: rgba(0,150,230,0.15);
}
.blueThree{
  background: #0096E6;
}
.blueText{
  color: #0096E6;
}

.greenThree{
  background: #EEF9F2;
}
.greenText{
  color: #2EB060;
}
</style>
