<template>
  <div class="chart-wrapper">
    <div class="unit">单位：{{ unit }}</div>
    <div ref="theChart" class="echarts1"></div>
  </div>
</template>
<script>
// import echarts from "echarts";
import * as echarts from "echarts";

export default {
  name: "ColumnChart",
  data() {
    return {
      chartColumnM: null,
      chartData: [],
    };
  },
  props: {
    data: Object,
    width: String,
    height: String,
  },
  watch: {},
  computed: {
    unit() {
      return this.data.unit || "";
    },
    headers() {
      return this.data.series.map((value) => value.name);
    },
    series() {
      const ret = [];

      const colors = [
        {
          type: "bar",
          color: "#73A0FA",
          barWidth: "21",
          label: {
            show: true,
            position: "top",
            textStyle: {
              fontSize: 10,
              color: "#1D2129",
            },
          },
        },
      ];

      for (let i = 0; i < this.headers.length; i++) {
        const element = this.headers.length[i];
        const color = colors[colors.length - i - 1];
        ret.push(color);
      }

      return ret;
    },
    source() {
      const ret = [];

      this.data.xLabels.forEach((productName, productIndex) => {
        const item = {};
        item.product = productName;
        this.data.series.forEach((aSeries) => {
          item[aSeries.name] = aSeries.data[productIndex];
        });
        ret.push(item);
      });

      // grp.data.forEach((v,index) => {
      //   item[this.data.xLabels[index]] = parseFloat(v);
      // });

      return ret;
    },
  },
  methods: {
    async init() {
      if (this.width) {
        this.$refs.chartColumn.style.width = this.width;
      }

      if (this.height) {
        this.$refs.chartColumn.style.height = this.height;
      }
      const myChart = echarts.init(this.$refs.theChart, null, {
        devicePixelRatio: 4,
        renderer: "svg",
      });
      const options = {
        legend: {
          x: "center",
          bottom: "5%",
          show: false,
        },
        tooltip: {
          formatter: (params) => {
            // const text = s.unSmooth(params.value[params.seriesName]);
            console.log(params);
            const text = params.name;
            return text + Object.values(params.data)[1] + this.unit;
          },
        },
        dataset: {
          dimensions: ["product", ...this.headers],
          source: this.source,
        },

        xAxis: {
          type: "category",
          splitLine: {
            show: false,
            lineStyle: {
              color: "#EEEEEE",
              width: 1,
              type: "solid",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#666",
            },
          },
          axisLabel: {
            // rotate: 35,
            interval: 0,
            textStyle: {
              fontSize: 10,
              fontFamily: "PingFangSC-Regular,PingFang SC",
              color: "#7585A2",
            },
          },
        },
        grid: {
          left: "0%",
          right: "0%",
          top: "10%",
          bottom: "0%",
          containLabel: true,
        },
        yAxis: {
          type: "value",
          minInterval: 1,
          min: 0, //取0为最小刻度
          axisLabel: {
            formatter: `{value} `,
            interval: 0,
            textStyle: {
              fontSize: 10,
              fontFamily: "PingFangSC-Regular,PingFang SC",
              color: "#7585A2",
            },
          },
          axisTick: {
            //y轴刻度线
            show: false,
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#666",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#E5E6EB",
              width: 1,
              type: "solid",
            },
          },
        },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: this.series,
      };
      myChart.setOption(options);
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="stylus" scoped>
.chart-wrapper {
  position relative

  .unit {
    text-align right
    font-size: 12px;
    font-weight: 400;
    color: #4E5969;
    line-height: 18px;
  }
}

.echarts1 {
  width: 89vw;
  height: 45vw;

  >>> div:first-child {
    width: 100% !important;
  }
}
@media screen and (min-width:500px) and (max-width: 2000px){
  .echarts1 {
    width: 320px;
    height: 300px;
    margin-bottom: 40px
    >>> div:first-child {
      width: 100% !important;
    }
  }
}
</style>
