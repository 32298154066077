<template>
  <div>
    <van-tabs v-model="activeIndex" type="card" class="btn-tabs">
      <van-tab :title="year" :key="year" v-for="(yearChart, year) in data.yearChart">
        <div class="tab-content">
          <BossTable :loaded="!!yearChart.table" :data="yearChart.table" class="table"></BossTable>
          <ColumnChart
            :v-if="yearChart.invoiceImageMap"
            :data="yearChart.invoiceImageMap.ruleOfInvoiceImageList[0]"
          ></ColumnChart>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import ColumnChart from "../../component/ColumnChart.vue";
import RingBar from "../../component/RingBar.vue";
import BossTable from "../../component/BossTable.vue";

export default {
  name: "HistogramTabs",
  components: { BossTable, RingBar, ColumnChart },
  props: {
    data: Object,
  },
  watch: {
    data: {
      handler() {
        //设置为最近一年(倒数第一)
        this.activeIndex = Object.entries(this.data.yearChart).length - 1;
        Object.entries(this.data.yearChart).forEach(([year, value], index) => {
          const body = value.invoiceImageMap.body.map((row) => {
            return [
              {
                content: row["资产负债率"],
              },
              {
                content: row["流动比率"],
              },
              {
                content: row["速动比率"],
              },
            ];
          });
          const table = {
            header: [
              [
                {
                  content: "资产负债率",
                },
                {
                  content: "流动比率",
                },
                {
                  content: "速动比率",
                },
              ],
            ],
            body: body,
          };
          this.data.yearChart[year].table = table;
        });
      },
      immediate: true,
    },
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  computed: {
    chartData() {
      const ret = [];
      for (const i in this.data.xLabels) {
        const label = this.data.xLabels[i];
        const value = this.data.series[0].data[i];

        ret.push({ name: label, value });
      }
      return ret;
    },
  },
};
</script>

<style lang="stylus" scoped>
.table{
  margin 16px 0 20px
}
.btn-tabs {

  border-bottom 1px dashed #7585A2;
  padding-bottom 20px
}

.tab-content {
  padding-bottom 30px
}
>>> .btn-tabs.van-tabs--card .van-tabs__nav--card .van-tab{
  font-weight: bold
  font-size: 16px;
}

@media screen and (min-width:500px) and (max-width: 2000px){
  >>> .btn-tabs.van-tabs--card .van-tabs__nav--card .van-tab{
    flex-basis: 5vw;
  }
}
</style>
