<template>
    <div>
        
    </div>
</template>
<script>
import { Dialog } from 'vant';
import 'vant/lib/button/style';
export default {
  data() {
    return{}
  },
  methods:{
    open(title,message,buttonSpan,buttonColor){
        Dialog.alert({
            title:title,
            message: message,
            confirmButtonText:buttonSpan,
            confirmButtonColor:buttonColor
            }).then(() => {
            // on close
            });
    },
  },
}
</script>