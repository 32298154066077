(function () {
    function a() {
        var b = document.documentElement.clientWidth;
        // console.log(b);
        b = b > 750 ? 750 : b;
        var c = (b / 750) * 100;
        // console.log(c);
        document.getElementsByTagName("html")[0].style.fontSize = c + "px";
    }
    a();
    window.onresize = a;
})();