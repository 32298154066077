<template>
  <div class="backPic">
    <div class="img" style="height: calc(100vh - 2rem)">
      <div class="posterContainer">
        <!-- <div class="posterName">这里是海报名称这里是海报</div>
        <div class="posterItem">
          <div>产品名称：老板看报告</div>
          <div>报告单价：0 元/次</div>
          <div>检测次数：2 次</div>
        </div>
        <div class="posterQR">
          <img src="@/assets/banner.png" alt="" />
        </div>
        <div class="QRTip">微信扫一扫 立即体验</div>
        <div class="posterTel">
          <div>联系人：李晓峰</div>
          <div>联系方式：15983720371</div>
        </div> -->
        <div>
          <div class="posterName">这里是海报名称这里是海报</div>
          <div class="posterItem">
            <div>产品名称：老板看报告</div>
            <div>产品单价：0 元</div>
            <div>项目数量：1 个</div>
            <div>单项目ocr次数：200 次</div>
            <div>报告单价：0 元/次</div>
            <div>账号数量：10 个</div>
            <div>系统网址：http://lvat.ai-tax.cn/</div>
            <div>使用时效：365 天</div>
          </div>
        </div>
        <div>
          <div class="posterQR">
            <img src="@/assets/banner.png" alt="" />
          </div>
          <div class="QRTip">微信扫一扫 立即体验</div>
          <div class="posterTel">
            <div>联系人：李晓峰</div>
            <div>联系方式：15983720371</div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn">
      <div class="btnList" v-if="btnStatus == true && !this.$route.query.btnStatus">
        <div class="order" @click="goEdit">返回修改</div>
        <div class="cost" @click="generatePoster">生成海报</div>
      </div>
      <div class="btnList" v-else>
        <div class="order" @click="goBack">返回我的</div>
        <div class="cost" @click="savePicture">保存到相册</div>
      </div>
    </div>

    <!-- <van-dialog
      v-model="show"
      show-cancel-button
      confirmButtonText="立即查看"
      confirmButtonColor="#3F74F6"
      cancelButtonText="海报制作记录"
      messageAlign="center"
      :confirm="confirmClick"
      :cancel="cancelClick"
    >
      <slot name="title">
        <i class="el-icon-success"></i>
        <div class="dialogTitle">专属海报制作成功</div></slot
      >
    </van-dialog> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      btnStatus: true,
    };
  },
  mounted() {},
  methods: {
    goBack() {
      this.$router.back();
    },
    generatePoster() {
      this.show = true;
      this.$dialog
        .alert({
          message: "专属海报制作成功",
          showCancelButton: true,
          confirmButtonColor: "#3F74F6",
          cancelButtonColor: "#0E1429",
          className: "vantDialog",
          confirmButtonText: "立即查看",
          cancelButtonText: "海报制作记录",
        })
        .then(() => {
          this.btnStatus = false;
        })
        .catch(() => {});
    },
    goEdit() {
      this.$router.push({
        path: "/posterInformation",
      });
    },
    goBack() {
      this.$router.push({
        path: "/my",
      });
    },
    savePicture() {},
    // confirmClick() {},
    // cancelClick() {},
  },
};
</script>
<style lang="stylus" scoped>
.dialogTitle{
  font-size: 0.34rem;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #0E1429;
  line-height: 0.34rem;
}
.el-icon-success{
  font-size: 1.28rem;
  color: #18CC87;
  opacity: 1;
  margin:0.64rem auto;
}
.backPic{
  height:100vh;

.img{
    background: url("../assets/img_bg_03.png") no-repeat;
    margin:0px;
    background-size:100% 87%;
    background-attachment:fixed;
    .posterContainer{
      padding: 0.6rem 0.5rem 0.5rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height:100%;
    .posterName{
      font-size: 0.44rem;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      margin-bottom: 0.4rem;
    }
    .posterItem{
        font-size: 0.3rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      div{
        line-height: 0.45rem;
        margin-top: 0.2rem;
      }
    }
    .posterQR{
      width: 2.4rem;
      height: 2.4rem;
      opacity: 1;
      border: 1px solid #FFFFFF;
      padding:0.18rem;
      box-sizing: border-box
      margin:0 auto;
      //margin-top: 3rem;
      img{
        width:100%;
        height:100%;
      }

    }
    .QRTip{
      font-size: 0.28rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      margin:0 auto;
      margin-top: 0.24rem;
      width: 2.7rem;
    }
    .posterTel{
      height: 1.38rem;
      background: rgba(255,255,255,0.1);
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      padding:0.24rem 0.3rem;
      box-sizing: border-box;
      margin-top:0.7rem;
      div{
        font-size: 0.26rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 0.47rem;
      }
    }
 }
}
.btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    //position: fixed;
    //bottom: 0;
    background: #fff;
    width: 100vw;
    height: 2rem;

    .btnList{
      display: flex;
      padding: 0.2rem 0.3rem;
      .order, .cost {
        width: 3.3rem;
        height: 0.88rem;
        line-height: 0.88rem;
        background: rgba(63, 116, 246, 0.2);
        border-radius: 0.1rem;
        color: #3F74F6;
        font-size: 0.32rem;
        text-align: center;
      }

      .cost {
        margin-left: 0.3rem;
        background: #3F74F6;
        opacity: 1;
        color: #fff;
      }
    }

  }
}
</style>
