<template>
  <div>
    <div class="whiteStyle"></div>
    <div class="h5-wrapper" id="h5-wrapper">
      <div class="top-bg" v-if="isShow == true">
        <div class="company-name">
          <span>{{ riskInfo.companyName }}</span>
        </div>
        <div class="date">{{ riskInfo.startTime }} - {{ riskInfo.endTime }}</div>
      </div>

      <div class="top-bg" v-else>
        <div class="company-name" v-if="this.$route.query.desensitization == 0">
          <span>{{ riskInfo.companyName }}</span>
        </div>
        <div class="company-name" v-else>
          {{ basicInfo.desensitizationCompanyName }}
        </div>

        <div class="date">{{ riskInfo.startTime }} - {{ riskInfo.endTime }}</div>
      </div>
      <div class="main-section">
        <div class="share" v-if="isShow" style="display: none">
          <div class="wrapper">
            <p @click="dialogVisible = true">分享</p>

            <van-popup v-model="dialogVisible" closeable :style="{ width: '360px' }">
              <van-form ref="form" validate-first class="content">
                <div class="title">安全提示</div>
                <div class="text">
                  检测报告涉及企业隐私数据，请谨慎分享，分享后对方查看报告需要输入校验码。
                </div>
                <div class="form-control">
                  <van-field
                    name="验证码有效期"
                    label="验证码有效期"
                    :rules="[{ validator: rules.expireHours, message: '请输入1-48的整数' }]"
                    v-model.number="form.expireHours"
                    :border="true"
                  >
                  </van-field>
                  <div class="unit">小时</div>
                </div>

                <div class="tuomin">
                  <van-checkbox
                    v-model="form.desensitization"
                    shape="square"
                    v-if="desensitizationAvailable"
                    >对报告中关键的公司名称、税号、地址进行脱敏
                  </van-checkbox>
                </div>
                <div class="dialog-footer">
                  <textarea v-model="shareText" id="input" class="textarea" />
                  <button class="btn btn-ok" @click="handleConfirmClick">复制链接及验证码</button>
                </div>
              </van-form>
            </van-popup>
          </div>
        </div>
        <div class="top-scores">
          <div class="top-score">
            <div class="score dark_grey">{{ riskInfo.total }}</div>
            <div class="score-name">检测总项</div>
          </div>
          <div class="top-score">
            <div class="score dark_grey">{{ riskInfo.active }}</div>
            <div class="score-name">风险项</div>
          </div>
          <div class="top-score">
            <div class="score">
              {{ getRiskLevelLabel(riskInfo.riskLevel) }}
            </div>
            <div class="score-name">风险等级</div>
          </div>
        </div>
        <div class="statistic-section">
          <div class="date-unit-wrapper">
            <div class="date">{{ businessInfo.startTime }} - {{ businessInfo.endTime }}</div>
            <div class="unit">单位：万元</div>
          </div>
          <div class="statistic-items">
            <div class="statistic-item">
              <div class="statistic-item-value">{{ businessInfo.yysr }}</div>
              <div class="statistic-item-name">营业收入</div>
            </div>
            <div class="statistic-item">
              <div class="statistic-item-value">{{ businessInfo.yycb }}</div>
              <div class="statistic-item-name">营业成本</div>
            </div>
            <div class="statistic-item">
              <div class="statistic-item-value">{{ businessInfo.mlr }}</div>
              <div class="statistic-item-name">毛利额</div>
            </div>
            <div class="statistic-item">
              <div class="statistic-item-value">{{ businessInfo.jlr }}</div>
              <div class="statistic-item-name">净利润</div>
            </div>
          </div>
        </div>
        <div class="entries-section">
          <router-link :to="{ name: 'IndustryComparison' }" class="entry-box">
            <div class="entry-name">
              <img src="../../../assets/icon@2x.png" alt="" />
              <span> 行业对比</span>
            </div>
            <div class="entry-text">和同行比，和过往比</div>
          </router-link>
          <router-link :to="{ name: 'financeAnalysis' }" class="entry-box">
            <div class="entry-name">
              <img src="../../../assets/icon@2x (1).png" alt="" />
              <span> 财务分析</span>
            </div>
            <div class="entry-text">3年账务，一目了然</div>
          </router-link>
          <router-link
            :to="{ name: 'OneKeyWatchInvoice',params:{desensitizationStatus:$route.params.desensitizationStatus} }"
            class="entry-box"
            style="margin-bottom: 0"
          >
            <div class="entry-name">
              <img src="../../../assets/icon@2x (2).png" alt="" />
              <span> 一键看票</span>
            </div>
            <div class="entry-text">进/销项发票，问题发票</div>
          </router-link>
          <router-link :to="{ name: 'TaxAnalysis' }" class="entry-box" style="margin-bottom: 0">
            <div class="entry-name">
              <img src="../../../assets/icon@2x (3).png" alt="" />
              <span> 纳税分析</span>
            </div>
            <div class="entry-text">税额、税目</div>
          </router-link>
        </div>
        <div class="risk-project-section">
          <div class="tool-bar">
            <div class="title">风险项目</div>
            <div class="btns">
              <div class="btn" @click="riskFilterShow">
                {{
                  riskFilterValue == null
                    ? "全部"
                    : riskFilterValue == -1
                    ? "未触警"
                    : riskFilterValue == 0
                    ? "无风险"
                    : riskFilterValue == 1
                    ? "低风险"
                    : riskFilterValue == 2
                    ? "中风险"
                    : "高风险"
                }}
                <i class="iconfont icon-icon_arrow_down"></i>
              </div>
              <van-action-sheet
                v-model="riskFilter"
                :actions="riskOptions"
                cancel-text="取消"
                close-on-click-action
                @cancel="onCancel"
                @select="handleRiskFilterChange"
              />
            </div>
          </div>
          <BossTable :loaded="loaded" :data="riskList" class="risk-project-table"></BossTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BossTable from "./component/BossTable.vue";
import "./bossWatchReport.styl";
import { Dialog, Button, Toast } from "vant";
import {
  getBusinessInfo,
  getRiskInfo,
  getRiskList,
  getReportUrl,
} from "../../../api/bossWatchReport";
import Copy from "copy-to-clipboard";
import { createShareCode, getDesensitizationStatus, h5GetResultBasicInfo } from "../../../api/api";

export default {
  name: "BossWatchReport",
  components: { BossTable },
  props: {
    uuid: String,
  },
  data() {
    return {
      loaded: false,
      businessInfo: {},
      riskInfo: {},
      riskList: {},
      riskFilter: false,
      riskFilterValue: 0,
      riskOptions: [
        { name: "全部", value: null },
        { name: "高风险", value: 3 },
        { name: "中风险", value: 2 },
        { name: "低风险", value: 1 },
        { name: "无风险", value: 0 },
        { name: "未触警", value: -1 },
      ],

      stepper: 1,
      dialogVisible: false,
      rules: {
        expireHours(value) {
          return Number.isInteger(value) && value >= 1 && value <= 48;
        },
      },
      form: {
        desensitization: false,
        shareCode: "",
        expireHours: 1,
      },
      desensitizationAvailable: false,
      shareText: "点击下方按钮获取分享链接",
      basicInfo: {},
      isShow: true,
      companyNameTwo: "",
      token: localStorage.getItem("token"),
    };
  },

  methods: {
    onCancel() {
      Toast("取消");
    },
    riskFilterShow() {
      this.riskFilter = true;
    },
    handleRiskFilterChange(v) {
      this.loadRiskList(v.value);
    },
    async loadRiskList(v) {
      let reportId = this.$route.params.reportId;
      let riskLevel = v;
      if (riskLevel == 0) {
        riskLevel = "0";
      }
      const desensitizationStatus=this.$route.params.desensitizationStatus?this.$route.params.desensitizationStatus:'0'

      const token = localStorage.getItem("token");
      this.riskFilterValue = v;
      this.loaded = false;
      this.riskList.body = [];
      const params = {
        uuid: this.uuid,
        riskLevel: riskLevel ? riskLevel : null,
      };
      let _this = this;
      const resRiskList = await getRiskList(params);
      if (resRiskList.data) {
        this.riskList.body = resRiskList.data.items.map((row) => {
          const ret = [];
          // if (row.riskLevel) {
          ret.push({ content: row.order });
          if (row.riskLevel != -1) {
            //未触警
            ret.push({
              content: row.name,
              click(e) {
                //去金税安的报告
                let url = getReportUrl({
                  reportId,
                  token,
                  desensitizationStatus
                });
                location.href = url;
              },
            });
          } else {
            //触警
            ret.push({
              content: row.name,
              riskLevel: row.riskLevel,
            });
          }

          if (row.riskLevel == -1) {
            ret.push({ content: "未触警" });
          } else if (row.riskLevel == 0) {
            ret.push({ content: "无" });
          } else if (row.riskLevel == 1) {
            ret.push({ content: "低" });
          } else if (row.riskLevel == 2) {
            ret.push({ content: "中" });
          } else if (row.riskLevel == 3) {
            ret.push({ content: "高" });
          }

          // } else {
          // ret.push({ content: row.order });
          // ret.push({ content: row.name });
          // ret.push({ content: "无" });
          // }

          return ret;
        });
        this.loaded = true;
      } else {
        this.riskList.body = [];
        this.loaded = true;
      }
    },
    async loadData() {
      const resBusinessInfo = await getBusinessInfo(this.uuid);
      this.businessInfo = resBusinessInfo.data;
      const desensitization=this.$route.params.desensitizationStatus?this.$route.params.desensitizationStatus:'0'
      const resRiskInfo = await getRiskInfo(this.uuid,desensitization);
      this.riskInfo = resRiskInfo.data;
    },
    getRiskLevelLabel(index) {
      const riskLevel = ["低", "中低", "中", "中高", "高"];
      return riskLevel[index - 1];
    },

    async handleConfirmClick() {
      try {
        await this.$refs.form.validate();
      } catch (e) {
        return;
      }

      const token = localStorage.getItem("token");
      const res = await createShareCode(this.uuid, this.nsrsbh, this.form.expireHours);

      let props = this.$router.resolve({
        name: "result-head-share2",
        params: {
          uuid: this.$route.params.uuid,
          shareCode: "null",
          checkId: res.data.urlCheck,
        },
        query: {
          ...this.$route.query,
          desensitization: this.form.desensitization ? 1 : 0,
        },
      });

      const sharedUrl = new URL(props.href, window.location.href).href;
      const companyName = this.form.desensitization
        ? this.basicInfo.desensitizationCompanyName
        : this.basicInfo.companyName;

      if (res.msgCode == 1) {
        this.shareText = `报告名称：${this.riskInfo.companyName}-老板看报告
        报告地址：${sharedUrl}
        校验码：${res.data.checkCode}
        有效期截止：${res.data.effectiveDate}`;
        setTimeout(() => {
          let copyText = document.querySelector("#input");
          copyText.select();
          document.execCommand("copy");
          this.$toast.success("复制成功");
        });
        this.dialogVisible = false;
      } else {
        this.error = true;
      }
    },
  },
  computed: {},
  watch: {
    form: {
      handler() {
        this.shareText = "点击下方按钮获取分享链接";
      },
      deep: true,
    },
  },
  async mounted() {
    if (this.$route.query.token) {
      localStorage.setItem("token", this.$route.query.token);
    }
    if (this.$route.query.desensitization) {
      this.isShow = false;
    }
    const resBasicInfo = await h5GetResultBasicInfo(this.$route.params.uuid);
    this.basicInfo = resBasicInfo.data;

    this.loadData();
    this.loadRiskList();
    this.riskList = {
      header: [
        [
          {
            content: "序号",
          },
          {
            content: "本企业",
          },
          {
            content: "风险等级",
          },
        ],
      ],
      body: [
        //[
        // {
        //   "colspan": 1,
        //   "rowspan": 1,
        //   "align": "center",
        //   "content": "增值税税负率",
        //   click(e) {
        //     console.log(e)
        //   }
        // }
        //    ]
      ],
    };

    //脱敏报告是否可用
    const desensitizationRes = await getDesensitizationStatus({ uuid: this.uuid });
    if (desensitizationRes.data) {
      this.desensitizationAvailable = true;
      this.form.desensitization = true;
    } else {
      this.form.desensitization = false;
      this.desensitizationAvailable = false;
    }
  },
};
</script>

<style lang="stylus" scoped>

.h5-wrapper {
  width: 100%;
  padding-bottom 0px
  .share{
    z-index: 999;
    position: absolute;
    top: -16px;
    background: #fff;
    right: 23px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin: 0 auto;
    border: 1px solid #c32d2d;
    p{
      line-height: 40px;
      font-weight: 600;
      font-size: 12px;
      color: #4ec0cd;
      margin: 0px 4px;
      >>>.van-button--normal{
        padding 0;
      }
    }
  }
  .top-bg {
    padding 0 0.3rem;
    box-sizing border-box
    width: 100%
    height: 1.5rem;
    display flex
    align-items center
    justify-content space-between
    background: #165DFF;

    .company-name {
      font-weight: 600;
      color: #FFFFFF;
      height: 48px;
      font-size: 16px;
      line-height 1.5;
      position relative
      top -5px
      text-align left
      display flex
      align-items center
      width: 4rem;
      span{
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap
      }
          }

    .date {
      font-size: 0.28rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 42px;
      position relative
      top -5px
    }
  }

  .main-section {
    width: 100%;
    background: #fff;
    border-radius 25px
    position relative;
    top -5vw;
    box-sizing border-box
    padding 0.3rem;

    .top-scores {
      display flex
      flex-direction row
      justify-content space-between

      border-bottom: 1px dashed #A4A6A9;
      padding-bottom: 0.4rem;

      .top-score {
        display flex
        align-items center
        justify-content space-between
        width: 85px;
        flex-direction column
        margin-top: 0.2rem;

        .score {
          font-size: 0.4rem;

          font-weight: bold;
          color: #F54242
          line-height: 36px;
          text-align center
        }
        .dark_grey{
          color: #1D2129

        }
        .score-name {
          margin-top 4px
          font-size: 14px;
          font-weight: 400;
          color: #606368;
          line-height: 21px;
          text-align center
        }
      }

    }

    .statistic-section {

      .date-unit-wrapper {
        margin-top: 0.4rem;
        font-size: 14px;
        font-weight: 400;
        color: #7585A2;
        line-height: 21px;
        display flex
        align-items center
        justify-content space-between
        .date{
          font-size: 0.28rem;
          font-weight: bold;
          color: #1D2129;
        }
        .unit{
          font-size: 0.28rem;
          font-weight: normal;
          color: #606368;
        }
      }

      .statistic-items {
        display flex
        flex-direction row
        justify-content space-between
        margin-top 16px
        background: #F1F5FF;
        border-radius: 4px;
        padding: 0.24rem
        box-sizing border-box
        flex-wrap wrap
        >div:first-child{
          margin-bottom 0.4rem

        }
        .statistic-item {
          //margin-bottom 20px
          width: 50%;
          height:50%;
          display flex
          justify-content space-between
          flex-direction column
          flex-shrink 0
          text-align left

          .statistic-item-value {
            font-size: 0.42rem;
            font-weight: bold;
            color: #1D2129;
            line-height: 28px;
          }

          .statistic-item-name {
            margin-top 4px
            font-size: 0.28rem;
            font-weight: 400;

            color: #606368;
          }

        }
      }

    }

    .entries-section {
      border-bottom: 1px dashed #A4A6A9;
      padding-bottom 20px
      display flex
      flex-direction row
      justify-content space-between
      margin-top 20px
      flex-wrap wrap

      .entry-box {
        background: #F1F5FF;
        border-radius: 0.12rem;
        margin-bottom 20px
        width: 49%;
        display flex
        justify-content flex-start
        flex-direction column
        flex-shrink 0
        text-align left
        padding 0.25rem
        box-sizing border-box

        .entry-name {
          font-size: 0.36rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #165DFF;
          display: flex;
          align-items: center;


          .entry-icon {
            width: 25px;
            height: 25px;
            vertical-align: middle;
            margin-right: 4px;
          }
          img{
            width: 0.4rem;
            height:0.4rem
            margin-right:5px
          }
        }

        .entry-text {
          font-size: 0.24rem;
          font-weight: 400;
          color: #1D2129;
          line-height: 18px;
          margin-top 8px

        }
      }
    }

    .risk-project-section {
      //border-bottom: 1px dashed #7585A2;
      padding-bottom 20px
      display flex
      flex-direction row
      justify-content space-between
      margin-top 20px
      flex-wrap wrap

      .tool-bar {
        display flex
        width 100%
        justify-content space-between
        align-items: center

        .title {
          font-size: 0.32rem;
          font-weight: 400;
          color: #1D2129;
          font-weight: bold;
          line-height: 24px;
        }
        .btns{
          width: 2rem;
          height: 0.64rem;
          background: #FFFFFF;
          border-radius: 0.08rem;
          opacity: 1;
          border: 0.02rem solid #DFDFE0;
          font-size: 0.28rem;
          align-items: center;
          padding: 0.12rem 0.2rem;
          box-sizing: border-box;
          .btn{
            display: flex;
            justify-content: space-between
            align-items: center;
            .icon-icon_arrow_down{
              color:#606368
              font-size: 0.2rem;

            }
          }
        }


      }

      .risk-project-table {
        margin-top 20px

      }

    }
  }
}

>>>.van-dialog__content{
padding 5px 15px;
margin 0 auto;
}
>>>.van-dialog__header{
  color:black;
}
.tooTip{
  color: gray
  font-size: 13px;
  line-height: 17px;
}
>>>.van-cell{
  padding: 10px 0px;
}
.checked{
  margin-left: 10px;
  color: #2774ff;
  font-weight: bold;
}
>>>.van-checkbox__icon--checked .van-icon{
  background-color: #2774ff;
}
.copyBtn{
  background: linear-gradient(88deg, #99b8f0 2%, #0349c8 100%);
  height: 0.4rem;
  border-radius: 8px;
  text-align: center;
  margin: 0 auto;
  line-height: 0.4rem;
  color: #fff;
}
.textarea{
    width: 100%;
    //height: 70px;
    height: 170px;

    margin: 5px 0 24px;
    border-radius: 5px;
    border: 1px solid #dedede;
    //overflow hidden
    //text-overflow: ellipsis:
    h5{
      margin 5px;
    }
}
>>>.van-dialog__confirm, .van-dialog__confirm:active {
  background: #2774ff;
}
//
.wrapper {
  display: flex;
  width 100%

  .icon {
    color: rgb(250, 173, 20);
    font-size: 32px;
  }

  .content {
    //font-size 0.28rem
    padding 39px
    padding-top 17px
    padding-bottom 25px

    .title {
      height: 24px;
      font-size: 16px;
      text-align center
      font-weight: 600;
      color: #000000;
      line-height: 24px;
    }

    .text {
      margin-top: 8px;
      font-size: 14px;
      color: #666;
      line-height: 1.5;
      margin-bottom 24px
    }
    .form-control{
      position relative;
      display flex;
      >>>.van-field__control{
        border 1px solid #ccc
        padding 0 5px
        margin-right 5px
      }

      .unit{
        width 100px
        line-height 24px
        font-size 14px
        color #646566
      }
    }

    .tuomin {
      margin-top 17px
      margin-bottom 10px
    }

    .error {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #C30505;
      line-height: 20px;
      margin-top 16px
    }
  }
}


>>> .el-dialog__header {
  padding: 0;
}

>>> .el-dialog__body {
  margin-bottom 0;
  padding-bottom 10px;
}

>>> .el-dialog__footer {
  padding 0
}

.mobileShareCodeBtnWrapper {
  position fixed;
  right 8px;
  top 60px;
  cursor pointer;
  display flex;
  justify-content center;
  align-items center;
  flex-direction column
  color #2F54EB
  font-weight bold;

  .shareCodeBtn {
    width 54px
  }
}


.shareCodeBtnWrapper {
  position fixed;
  right 1%;
  top 50%;
  transform translateY(-50%)
  width 160px
  cursor pointer;
  display flex;
  justify-content center;
  align-items center;
  flex-direction column
  color #2F54EB
  font-weight bold;

  .shareCodeBtn {
    width 100%;
    margin-top 58px
    transition all 0.5s ease-in-out
  }

  .shareCodeBtn:first-child {
    margin-top 0
  }

  .shareCodeBtn:hover {
    //transform: rotate(360deg);
    width 100%;
  }
  font-size 16px
}

>>> .van-cell
  padding 0

>>> .van-checkbox
  .van-checkbox__icon--checked .van-icon
    background rgba(47, 84, 235, 1);

  .van-checkbox__label
    color: rgba(47, 84, 235, 1);
  align-items flex-start

>>> .van-cell::after
  border-bottom 0 none;

.dialog-footer
  justify-content center
  width 100%
  display flex
  flex-direction column
  justify-content center;
  align-items center
  margin-top 17px

  .textarea
    font-weight lighter !important
    margin-bottom 30px;
    border 1px solid #eee
    width 100%
    height 50px

  .btn-ok
    width: 182px;
    height: 40px;
    background: linear-gradient(90deg, #5C8DF7 0%, #2F54EB 100%);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.13);
    border-radius: 7px;
    color #fff;
>>>.van-popup--center{
  top:36%;
}
</style>
