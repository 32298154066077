import { render, staticRenderFns } from "./BigPowerTable.vue?vue&type=template&id=ed58639a&scoped=true&"
import script from "./BigPowerTable.vue?vue&type=script&lang=js&"
export * from "./BigPowerTable.vue?vue&type=script&lang=js&"
import style0 from "./BigPowerTable.vue?vue&type=style&index=0&id=ed58639a&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed58639a",
  null
  
)

export default component.exports