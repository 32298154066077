<template>
    <div>
        <van-popup
      v-model="vanPopupShareShow"
      closeable
      round
      position="bottom"
      :style="{ height: '7.5rem'}"
    >
    <div class="shareDiv">
        <div class="shareTitle">邀请他人填写</div>
        <div class="codeboX">
            <span> <span style="margin-right: 0.05rem;">{{ phoneNumber }}</span> 邀请您授权数据，请扫码完成操作</span>
                <img :src="imageUrl?imageUrl:''" alt="">
        </div>
        <div class="copyBox">
            <span>您可复制链接或截图发送给被邀请者</span>
            <button class="copy" @click="copyLink">复制</button>
        </div>
        <div>
            退出当前页后，可在【检测记录】中找到本次检测，并查看到授权状态
        </div>
    </div>
        
    </van-popup>
    </div>
</template>
<script>
import {authAESEncrypt,bThirdFetchInfo,getSharedQrCode} from "@/api/api"
const baseEnv = process.env.VUE_APP_PDF_ROOT_FETCH;

export default {
    computed:{
        phoneNumber(){
            return localStorage.getItem('phoneNumber')
        },
    },
    
       
    data(){
        return{
            vanPopupShareShow:false,
            imageUrl:'',
            shareUrl:''
        }
    },
    methods:{
        async copyLink(){
        //   const data = JSON.parse(this.$route.query.info);
        // //   加密
        //   let params = {
        //     companyName: data.companyName,
        //     programId: this.$route.params.uuid,
        //     taxNo: data.taxNo,
        //     zq: data.zq.toString(),
        //     channelCode: "shiwusuo",
        //     companyNo: data.taxNo,
        //   };
        //   let res = await authAESEncrypt(params);
        //   const url=baseEnv+
        //   "oemV3/auth" +
        //   "?key=" +
        //   encodeURIComponent(res.data)+'&from=jinshuian'+'&token='+localStorage.getItem('token')

          var inputTest = document.createElement("input");
          inputTest.value =`${this.phoneNumber} 邀请您授权数据，请打开链接完成操作；${this.shareUrl}` ;
          document.body.appendChild(inputTest);
          inputTest.select();
          document.execCommand("Copy");
          inputTest.className = "oInput";
          inputTest.style.display = "none";
          this.$message.success(
            "复制成功！",
            );


    },
      async  openVanPopupShareShow(){

          const data = JSON.parse(this.$route.query.info);
          // 生成页面存储
          let params = {
            companyName: data.companyName,
            programId: this.$route.params.uuid,
            taxNo: data.taxNo,
            zqId: data.zq.toString()+'004',
            channelCode: "shiwusuo",
            companyNo: data.taxNo,
            id:null
          };
          const res=await bThirdFetchInfo(params)
          if(res.code==200){
            const url=baseEnv+
              "oemV3/auth" +
              "&uuid=" +this.$route.params.uuid+
             '&from=jinshuian'+'&token='+localStorage.getItem('token')
             const shareCode=baseEnv+
              "oemV3/auth" +
              "?uuid=" +this.$route.params.uuid+
             '&from=jinshuian'+'&token='+localStorage.getItem('token')
             this.shareUrl=shareCode
              const res2 = await getSharedQrCode(url)
              if(res2.code==200){
                this.imageUrl=res2.data
              }
          }


            this.vanPopupShareShow=true
        }
    }
}
</script>

<style lang="stylus" scoped>
>>>.van-popup__close-icon{
  color: #606368;
}
.shareDiv{
    padding:0.28rem 0.28rem 0.4rem 0.28rem;

  .shareTitle{
    box-sizing:border-box;
    padding:14px;
    padding-left:0px;
    padding-top:0px;
    //height: 54px;
    font-size: 17px;
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    color: #1D2129;
  }
  .copyBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom :0.4rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #1D2129;
    >button{
        width: 2rem;
        height: 0.8rem;
        color: #fff;
        background: #165DFF;
        color: #fff;
        font-size: 16px;

        border: 0;
        border-radius: 4px;
    }
  }
  .copyBox+div{
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #606368;

  }
  .codeboX{
    background: #F1F5FF;
    border-radius: 4px;
    padding: 0.3rem 0.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom :0.4rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #1D2129;
    >span{
        margin-bottom :0.3rem;
    }
    img{
     width:2.4rem;   
     height:2.4rem;   
    }
  }
}
</style>