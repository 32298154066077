/**
 * 得到th的样式
 * @param cell
 */
export function getThStyle(cell) {
    const ret = {}
    if (cell.align) {
        ret.textAlign = cell.align
    }

    if (cell.color) {
        ret.color = cell.color
    }

    if (cell.width) {
        ret.width = cell.width
    }
    return ret;
}

/**
 * 得到td的样式
 * @param table
 * @param cell
 * @param colIndex
 */
export function getTdStyle(table,cell, colIndex) {
    const ret = {}

    const headerCol = table.header[0][colIndex];
    if (headerCol?.width) {
        ret.width = headerCol.width
    }

    if (headerCol?.align) {
        ret.textAlign = headerCol.align
    }
    if (cell.align) {
        ret.textAlign = cell.align
    }

    if (cell.color) {
        ret.color = cell.color
    }

    if (cell.width) {
        ret.width = cell.width
    }
    return ret;
}



/**
 * 画出移除的rect
 * @param target
 * @param row1
 * @param col1
 * @param row2
 * @param col2
 */
export function drawRemovedRect(target, row1, col1, row2, col2) {
    if (row1 > row2) {
        const tmp = row1;
        row1 = row2
        row2 = tmp
    }

    if (col1 > col2) {
        const tmp = col1;
        col1 = col2
        col2 = tmp
    }

    for (let i = row1; i <= row2; i++) {
        for (let j = col1; j <= col2; j++) {
            if (i < target.length) {
                if (j < target[i].length) {
                    if (i == row1 && j == col1) {
                    } else {
                        target[i][j]._removed = true;
                    }
                }
            }

        }
    }
}

/**
 * 内容是否溢出表格
 * @param table
 */
export function getIsOverflowed(table){
    let isOverFlowed = false

    const parentNode = table.parentNode;
    if (parentNode.offsetHeight < parentNode.scrollHeight ||parentNode.offsetWidth < parentNode.scrollWidth) {
        isOverFlowed = true
    }
    return isOverFlowed

}
