<template>
  <div>
    <div class="selectButton">
      <div>
        <span :class="statType == 'quantity' ? 'selected' : ''" @click="statTypechange('quantity')"
          >发票数量</span
        >
        <span :class="statType == 'amount' ? 'selected2' : ''" @click="statTypechange('amount')"
          >含税金额</span
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      statType: "quantity",
    };
  },
  methods: {
    statTypechange(e) {
      this.statType = e;
      this.$emit("statTypechange", e);
    },
  },
};
</script>
<style lang="stylus" scoped>
.selectButton{
  >div{
    font-size 12px
    text-align: right;
    font-family: PingFangSC-Regular, PingFang SC;
    >span{
      text-align: center
      display: inline-block;
      width: 1.28rem;
      color: #1D2129;
      height: 0.56rem;
      line-height:0.56rem
      background: #FFFFFF;

      border: 1px solid #DFDFE0;
    }
    >span.selected{
      background: #FFFFFF;
      border: 1px solid #1677FF;
      color: #165DFF;
    }
    >span.selected2{
      background: #FFFFFF;
      border: 1px solid #1677FF;
      color: #1677FF;
    }
  }
}
</style>
