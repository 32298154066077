<template>
  <div class="success">
    <img src="@/assets/h5_success.png" alt="" />
    <div class="t1">提交成功！</div>
    <div class="t2">稍后会有专属客服联系您</div>
    <div class="t3" @click="toIndex">返回首页</div>
  </div>
</template>

<script>
export default {
  methods: {
    toIndex() {
      let channelUniqueId = localStorage.getItem("channelUniqueId");
      this.$router.push({
        path: "/",
        query: {
          channelUniqueId: channelUniqueId,
        },
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.success {
  text-align: center;
  background: #fff;
  height: 100vh;
  color: #0E1429;

  img {
    width: 4rem;
    height: 4rem;
    margin-top: 1.4rem;
    margin-bottom: 0.1rem;
  }

  .t1 {
    line-height: 0.5rem;
    font-size: 0.36rem;
    font-weight: 600;
    margin-bottom: 0.3rem;
  }

  .t2 {
    line-height: 0.42rem;
    font-size: 0.3rem;
  }

  .t3 {
    width: 4rem;
    height: 0.88rem;
    line-height: 0.88rem;
    background: #3F74F6;
    border-radius: 0.1rem;
    opacity: 1;
    color: #fff;
    text-align: center;
    margin: 0 auto;
    margin-top: 1.2rem;
  }
}
</style>
