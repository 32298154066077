import { fetchCompanyInfo, getCompanyId, messageList } from "@/api/api";


function initialState() {
  return {
    //企业信息
    companyInfo: {},
    //企业认证信息
    companyrenzheng: {
      auditStatus: -1
    },
    //用户信息
    userInfo: {},
    //消息中心
    messageNumber: 0,
    // 风险库信息
    goods: {
      id: 191,
      name: "2019通用规则测评",
      bankId: "2223f9d4515844e589597070d8847631"
    },
    customization: '', // 是否是自定义版本 如果不是就保持为''  lc:大连  xr:萧瑞
    contactUs:''
  };
}

export const state = {
  // 存储token
  authorization: localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "",
  ...initialState()
};

export const mutations = {
  setMessageNumber(state, number) {
    state.messageNumber = number;
  },

  setCustomization: (state, { customization }) => {
    state.customization = customization
  },
  setContactUs: (state, { data }) => {
    state.contactUs = data
  },
  // 修改token，并将token存入localStorage
  changeLogin(state, user) {
    state.authorization = user.authorization;
    localStorage.setItem("authorization", user.authorization);
  },
  clearToken(state) {
    (state.authorization = ""), localStorage.removeItem("authorization");
  },
  // 修改企业信息
  changeCompanyInfo(state, companyInfo) {
    state.companyInfo = companyInfo;
  },
  //修改企业认证状态
  changeCompanyRenzheng(state, payload) {
    state.companyrenzheng = payload.companyrenzheng;
  },
  //修改用户信息
  setUserInfo(state, payload) {
    state.userInfo = payload.userInfo;
  },
  //重置这个store初始状态
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  // 自动匹配风险库
  setGoods(state, { id, name, bankId }) {
    state.goods = { id, name, bankId };
  }
};

export const actions = {
  //载入公司信息
  async loadCompanyInfo({ commit }) {
    const userInfo = JSON.parse(localStorage.getItem("user"));

    if (userInfo.companyId) {
      let companyInfo = await fetchCompanyInfo({
        companyID: userInfo.companyId
      });


      if (companyInfo.msgCode != -1) {
        commit("changeCompanyInfo", companyInfo.data || {});
      }
    }
  },
  //消息中心
  async loadMessageNumber({ commit }) {
    let numberData = await messageList({
      type: '2'
    })
    let noread = 0;
    var message1 = numberData.data.content;
    for (var i in message1) {
      if (message1[i].childList) {
        for (var j in message1[i].childList) {
          if (message1[i].childList[j].readStatus == 1) {
            noread += 1;
          }
        }
      }
    }
    commit("setMessageNumber", noread);
  },

  /**
   * 载入公司认证信息
   * 调用方法:
   * 多次调用只调用一次:await this.$store.cache.dispatch("loadCompanyRenzheng");
   * 删除调用的缓存,使上面的方法可以再次调用 this.$store.cache.delete('loadCompanyRenzheng');
   * 普通调用(多次调用调用多次): await this.$store.dispatch("loadCompanyRenzheng");
   */

  async loadCompanyRenzheng({ commit, state }) {
    const token = state.authorization;

    const data = await getCompanyId(token);

    if (data.data) {
      localStorage.setItem("hasPwd", data.data.hasPwd);
      commit("changeCompanyRenzheng", { companyrenzheng: data.data });
    } else {
      localStorage.setItem("hasPwd", false);
    }
  },
  async loadUserInfo({ commit, state }) {
    const token = state.authorization;

    const data = await getCompanyId(token);

    if (data.data) {
      commit("changeCompanyRenzheng", { companyrenzheng: data.data });
    }
  }
};
