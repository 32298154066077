<template>
  <div>
    <van-tabs v-model="activeIndex" type="card" class="btn-tabs">
      <van-tab :title="year + '年'" :key="year" v-for="(yearChart, year) in data.yearChart">
        <div class="tab-content">
          <ColumnChart
            v-if="yearChart.invoiceImageMap"
            :data="yearChart.invoiceImageMap.ruleOfInvoiceImageList[0]"
          >
          </ColumnChart>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import ColumnChart from "./ColumnChart.vue";
import RingBar from "../../component/RingBar.vue";
export default {
  name: "HistogramTabs",
  components: { RingBar, ColumnChart },
  props: {
    data: Object,
  },
  watch: {
    data: {
      handler(news) {
        console.log(news);
      },
      deep: true,
    },
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  watch: {
    data: {
      handler() {
        //设置为最近一年(倒数第一)
        this.activeIndex = Object.entries(this.data.yearChart).length - 1;
      },
      immediate: true,
    },
  },

  computed: {
    chartData() {
      const ret = [];
      for (const i in this.data.xLabels) {
        const label = this.data.xLabels[i];
        const value = this.data.series[0].data[i];

        ret.push({ name: label, value });
      }
      return ret;
    },
  },
};
</script>

<style lang="stylus" scoped>
>>>.btn-tabs.van-tabs--card .van-tabs__nav--card .van-tab--active .van-tab__text--ellipsis{
  font-weight: 700;
  font-size: 14px;
  color: #1677ff;
}
>>>.btn-tabs.van-tabs--card .van-tabs__nav--card .van-tab{
  color:#1D2129;

}
@media screen and (min-width:500px) and (max-width: 2000px){
  >>> .btn-tabs.van-tabs--card .van-tabs__nav--card .van-tab{
    flex-basis: 5vw;
  }
}
.btn-tabs{

  //border-bottom 1px dashed #7585A2;
  padding-bottom 20px
}
.tab-content{
  //padding-bottom 30px
}
</style>
