<template>
  <div>
    <div class="whiteStyle"></div>

    <div>
      <div class="h5-wrapper h5">
        <div class="card-wrapper">
          <div class="info-card">
            <div class="text0">{{ keyFinancialData.industry }}</div>
            <div class="text1">所属行业</div>
          </div>
          <div class="info-card">
            <div class="text0">{{ keyFinancialData.area }}</div>
            <div class="text1">所属地区</div>
          </div>
        </div>
        <div class="content-section" v-if="keyFinancialData">
          <template v-for="(chart, index) in keyFinancialData.data">
            <bossTaxBearingRate
              :key="index"
              :data="chart"
              class="chart"
              height="300px"
              width="300px"
            ></bossTaxBearingRate>
            <div class="line"></div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "../bossWatchReport.styl";
import bossTaxBearingRate from "../component/bossTaxBearingRate.vue";
import { getIndustryCompareAPI } from "../../../../api/bossWatchReport";

export default {
  metaInfo: {
    title: "行业对比",
  },
  name: "IndustryComparison",
  components: { bossTaxBearingRate },
  data() {
    return {
      keyFinancialData: {},
    };
  },
  methods: {
    async load() {
      const params = {
        uuid: this.$route.params.uuid,
      };
      const res = await getIndustryCompareAPI(params);
      this.keyFinancialData = res.data;
    },
  },
  async mounted() {
    this.load();
  },
};
</script>

<style lang="stylus" scoped>
.h5-wrapper {
  //padding 20px 20px 35px;
  //position: relative
  background: #fff;
  .card-wrapper{
    display flex
    justify-content space-between
    padding: 0.3rem 0.3rem 0;
    .info-card{
      width: 3.3rem;
      height: 1.39rem;
      border-radius: 8px;
      padding: 0.24rem;
      box-sizing border-box
      //box-shadow: 0px 1px 10px 2px rgba(0,0,0,0.1);
      background: #F4F6F8;
      .text0{
        margin-bottom 8px
        font-size: 0.28rem;
        font-weight: 400;
        color: #1D2129;
        line-height: 20px;
        font-weight: bold;

      }
      .text1{
        font-size: 0.24rem;
        font-weight: 400;
        color: #606368;
        line-height: 18px;
        font-weight: bold;
      }
    }
  }
}

.content-section {
  padding: 0 0.3rem 0.08rem;
  background: #FFFFFF;

  .title {
    display flex
    align-items center
    font-size: 16px;
    font-weight: 400;
    color: #1D2129;
    line-height: 24px;
  }

  .title::before {
    content: '';
    display: inline-block;
    width: 0.08rem;
    height: 0.32rem;
    background: #2663F6;
    border-radius: 4px;
    margin-right: 0.2rem;
  }
}
.line{
    //width: 89.3vw;
    border-bottom: 2px dashed #7585a247;
    margin 20px auto;
}
</style>
