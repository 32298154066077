<template>
  <div>
    <van-tabs v-model="activeIndex" type="card" class="btn-tabs">
      <van-tab :title="year" :key="year" v-for="(yearChart, year) in data.yearChart">
        <div class="tab-content">
          <RingBar
            v-if="yearChart.invoiceImageMap"
            :data="yearChart.invoiceImageMap.ruleOfInvoiceImageList[0]"
            :total="yearChart.total * 1"
          ></RingBar>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import RingBar from "../../component/RingBar.vue";

export default {
  name: "RingBarTabs",
  components: { RingBar },
  props: {
    data: Object,
  },
  data() {
    return {
      activeIndex: 0,
      //data:{"imageSort":0,"imageType":"annulus","imageUnit":"%","series":[{"data":["0.3561","0.2048","0.0206","0.0203","0.0187","0.0136","0.0121","0.0119","0.0102","0.0102","0.3215"],"name":"比例"}],"xLabels":["*鉴证咨询服务*咨询服务","*一般税务咨询*顾问费","*鉴证咨询服务*咨询费","*涉税鉴证*审计费","*涉税鉴证*所得税汇算清缴262","*涉税鉴证*土增292","*经纪代理服务*代理费","*涉税鉴证*所得税汇算清缴129","*涉税鉴证*土增228","*鉴证咨询服务*其他鉴证服务224","其他"]}
    };
  },
  watch: {
    data: {
      handler() {
        //设置为最近一年(倒数第一)
        this.activeIndex = Object.entries(this.data.yearChart).length - 1;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="stylus" scoped>

.btn-tabs{

  //border-bottom 1px dashed #7585A2;
  padding-bottom 20px
}

.tab-content {
  padding-bottom 20px
}
.btn-tabs >>>.van-tab__text{
  font-weight: 600;
  font-size: 14px;
}
@media screen and (min-width:500px) and (max-width: 2000px){
  >>> .btn-tabs.van-tabs--card .van-tabs__nav--card .van-tab{
    flex-basis: 5vw;
  }
}
</style>
