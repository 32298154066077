<template>
  <div class="try">
    <div class="item" :class="{ itemType2: type == 2 }">
      <div class="link">联系人</div>
      <div class="item-input">
        <el-input v-model.trim="name" placeholder="请输入真实姓名"></el-input>
      </div>
    </div>
    <div class="item" :class="{ itemType2: type == 2 }">
      <div class="link">联系方式</div>
      <div class="item-input">
        <el-input v-model.trim="phone" placeholder="请输入手机号码"></el-input>
      </div>
    </div>
    <div class="item" :class="{ itemType2: type == 2 }">
      <div class="links">需求描述</div>
      <div class="item-inputs">
        <el-input
          type="textarea"
          v-model.trim="detail"
          maxlength="15"
          show-word-limit
          rows="3"
          placeholder="请简要描述您的需求"
        ></el-input>
      </div>
    </div>
    <div class="btn-item" :class="{ btnItem2: type != 2 }" @click="submit">提交</div>
  </div>
</template>

<script>
import { userMessage } from "@/api/fri";

export default {
  props: {
    type: [Number, String],
  },
  data() {
    return {
      name: "",
      phone: "",
      detail: "",
    };
  },
  methods: {
    submit() {
      if (!this.name || !this.phone) {
        this.$message({
          message: "请输入联系人联系方式！",
          type: "warning",
        });
        return;
      }
      let params = {
        name: this.name,
        phone: this.phone,
        detail: this.detail,
      };
      userMessage(params).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.$router.push({
            path: "/success",
          });
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.try {
  width: 7.1rem;
  background: #fff;
  font-size: 0.3rem;
  color: #181C36;
  padding-top: 0.4rem;
  border-radius: 0.2rem;

  .item {
    margin-bottom: 0.5rem;
    margin-left: 0.3rem;

    .link:before {
      content: "*";
      color: rgba(250, 79, 79, 1);
      margin-right: 3px;
    }
    .item-input {
      width: 6.5rem;
      height: 0.88rem;
      line-height: 0.88rem;
      background: #F8F9FB;
      border-radius: 0.1rem;
      opacity: 1;
      margin-top: 0.2rem;

      >>> .el-input__inner {
        border: none;
        background: #F8F9FB;
        height: 0.88rem;
        line-height: 0.88rem;
        padding: 0 0.24rem;
      }

      >>> .el-input__inner::placeholder {
        font-size: 0.3rem;
      }
    }
    .item-inputs {
      border-radius: 0.1rem;
      width: 6.5rem;
      background: #F8F9FB;
      border-radius: 0.1rem;
      opacity: 1;
      margin-top: 0.2rem;

      >>> .el-textarea__inner {
        border: none;
        background: #F8F9FB;
      }

      >>> .el-textarea .el-input__count {
        background: #F8F9FB;
        color: rgba(185, 186, 194, 1);
      }
    }
  }

  .btn-item {
    width: 6.9rem;
    height: 0.88rem;
    line-height: 0.88rem;
    background: #3F74F6;
    border-radius: 0.1rem;
    opacity: 1;
    color: #fff;
    text-align: center;
    margin-left: 0.3rem;
  }

  .itemType2 {
    .item-input {
      width: 6.9rem;
    }

    .item-inputs {
      width: 6.9rem;
    }
  }

  .btnItem2 {
    width: 6.5rem;
  }
}
</style>
