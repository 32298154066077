<template>
  <div class="info">
    <div class="title">产品详细信息</div>
    <div class="item" v-for="(item, index) in goodInfo">
      <div>{{ item.name }}</div>
      <div>
        <span class="addFam" :id="'access' + index">{{ item.value }}</span
        >{{ item.unit }}
        <span
          class="systemUrl"
          v-if="item.label == 'systemUrl'"
          @click="copyCode('access' + index)"
          >复制</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    goodInfo: [Array, Object],
  },
  methods: {
    copyCode(val) {
      var copyTest = document.getElementById(val).innerText;
      var inputTest = document.createElement("input");
      inputTest.value = copyTest;
      document.body.appendChild(inputTest);
      inputTest.select();
      document.execCommand("Copy");
      inputTest.className = "oInput";
      inputTest.style.display = "none";
      this.$message({
        message: "复制成功！",
        type: "success",
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.info {
  width: 7.1rem;
//   height: 3.98rem;
  background: #FFFFFF;
  border-radius: 0.2rem;
  opacity: 1;
  box-sizing: border-box;
  font-size: 0.28rem;

  .title {
    font-size: 0.32rem;
    line-height: 0.46rem;
    padding: 0.3rem 0 0.4rem 0.3rem;
    font-weight: 600;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.3rem;
    padding-bottom: 0.3rem;
    line-height: 0.4rem;

    .item-url {
      display: flex;
      align-items: center;

      .url {
        width: 0.76rem;
        height: 0.42rem;
        line-height: 0.42rem;
        background: #E5F3FE;
        border-radius: 0.08rem;
        opacity: 1;
        margin-left: 0.2rem;
        text-align: center;
        color: rgba(0, 140, 244, 1);
        font-size: 0.24rem;
      }

      .access {
        font-family: PingFangSC-Regular-, PingFangSC-Regular;
      }
    }

    .addFam {
      font-family: DIN Alternate-Bold, DIN Alternate;
    }
  }
}

.systemUrl {
  display: inline-block;
  width: 0.76rem;
  height: 0.42rem;
  line-height: 0.42rem;
  background: #E5F3FE;
  border-radius: 0.08rem;
  opacity: 1;
  margin-left: 0.1rem;
  text-align: center;
  color: rgba(0, 140, 244, 1);
  font-size: 0.24rem;
}
</style>
